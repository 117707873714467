import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../BDO/addbdo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../BDO/dob.css";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { APIURL } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";

function AddWards(props) {
  const [visitorDatas, setVisitorDatas] = useState({
    roomname: "",
    roomnumber: "",
    roomtype: { value: '', label: '' },
    roomprice:"",
    building:"",
    floor:""

  
   
  })

  const [error, setError] = useState({
   
  });

  const roomtype = [{ value:'delux', label:'Delux' },                       
  { value:'semi-delux', label:'Semi-Delux' },
  { value:'executive', label:'Executive' },
  { value:'vip', label:'VIP' },
  { value:'vvip', label:'VVIP' },
  { value:'standard', label:'Standard' },

 
  ]

  //const [dobValue, setDobValue] = useState();
  const [dobValue, setDobValue] = useState();

  const [errorMsg, setErrorMsg] = useState("");

  const [, setFormattedDob] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  //const history = useHistory();


  const RoomType = (data) => {
    

    setVisitorDatas(current => ({
      ...current, roomtype: data
    }))
  }


  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps">{submitMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
         
           
          
        </Modal.Footer>
      </Modal>
    );
  }

  function ErrorPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps" style={{ color: "red" }}>
            {errorMsg}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleDOB = (date) => {
    
    setDobValue(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      //let datefinal = moment(DateExtracted).format('YYYY-MM-DD')  
      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);

      setVisitorDatas((currentstate) => ({
        ...currentstate,
        dob: DateExtracted,
      }));
    }
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
  };

  const handleSubmit = () => {
    
    
    // if (rawPhone !== null && rawPhone !== "" && rawPhone !== " ") {
    //   setValidateRawPhoneMsg("success");
    //   const tokenStr = sessionStorage.getItem("usertoken");

    //   if (tokenStr) {
    //     const v = tokenStr;
    //     let v2 = v.replace(/["]+/g, "");

    //     const dataToSend = {
    //         first_name: visitorDatas.firstname ,
    //         last_name:visitorDatas.lastname,
    //         username: visitorDatas.username,
    //         password: visitorDatas.password,
    //         phone: visitorDatas.phone,
    //         related_patient:props.patient,
    //         relation:visitorDatas.familyrelation.value,
    //         code: visitorDatas.code,

    //     }
    //     if (visitorDatas.state) {
    //         dataToSend.state = visitorDatas.state;
    //       }

    //       if (visitorDatas.country) {
    //         dataToSend.country = visitorDatas.country;
    //       }

    //       if (visitorDatas.email) {
    //         dataToSend.email = visitorDatas.email;
    //       }

    //       if (visitorDatas.dob) {
    //         dataToSend.dob = visitorDatas.dob;
    //       }
    //       if (visitorDatas.gender) {
    //         dataToSend.gender = visitorDatas.gender;
    //       }
         
        
 
         
        
          
      

    //     axios
    //       .post(`${APIURL}/api/v1/service-provider/visitor/`, dataToSend, {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Token " + v2,
    //         },
    //       })
    //       .then((res) => {
    //         if (res.data.status === "success") {
    //           setSubmitMsg(
    //             res.data.message ? res.data.message : "Successfully added"
    //           );
              
    //           setModalShow(true);
    //           props.handle();
    //         } else {
    //           setErrorMsg(
    //             res.data.message ? res.data.message : "Error in submission"
    //           );

    //           setErrorShow(true);
    //         }
    //       })
    //       .catch((err) => {
    //         setErrorMsg("Error in submission");
    //       });
    //   }
    // } else {
    //   setValidateRawPhoneMsg("error");
    // }
  };

  const handleCheckEmail = (e) => {
    
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: dialCode,
        mobile_number: rawPhone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "This phone number is already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleContinue = async (e) => {
    
    
    e.preventDefault();

    if (validate() &&  isValidPhone || isValidEmail) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setVisitorDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };

  const handleQualification = (e) => {
    e.preventDefault();
    const val = e.target.value;

    const key = e.target.name;
    let arr = [];
    if (val && val.includes(",")) {
      arr = val.split(",");
    } else {
      arr.push(val);
    }
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      [key]: arr,
    }));
  };

  const handleCountry = (val) => {
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      country: val,
    }));
  };

  const handleRegion = (val) => {
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length));
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      phone: value.slice(data.dialCode.length),
      code: value.slice(0, data.dialCode.length),
    
    }));
  };

  const validate = () => {
    let input = visitorDatas;
    let focussed = false;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["firstname"] || input["firstname"] === "") {
      isValid = false;
      errors["firstname"] = "Enter first name";
    }

    if (!input["lastname"] || input["lastname"] === "") {
      isValid = false;
      errors["lastname"] = "Enter last name";
    }



  

 

    if (!dialCode || dialCode === "" || !rawPhone || rawPhone === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }




    if (
        typeof visitorDatas.familyrelation == "undefined" ||
        visitorDatas.familyrelation === "" ||
        visitorDatas.familyrelation === null
      ) {
        isValid = false;
        errors["familyrelation"] = "Select relationship";
      }

      if (typeof input["email"] !== "undefined" && input["email"] !== "") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Enter a valid email address.";
        }
      }
      
    // if (typeof input["department"] !== "undefined") {
    //   var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if(pattern2.test(input["department"])) {
    //     isValid = false;
    //     errors["specialization"] = "Special Characters are not allowed";
    //   }
    // }

    if (typeof input["username"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/?~]/);
      if (pattern2.test(input["username"])) {
        isValid = false;
        errors["username"] = "Check the characters used";
      }
    }

    if (typeof input["username"] !== "undefined") {
        var pattern1 = new RegExp(
          /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){0,18}[a-zA-Z0-9]$/
        );
        if (!pattern1.test(input["username"]) || (input["username"]).length < 6) {
          isValid = false;
          errors["username"]=
            'Special Characters and space are not allowed in username and Characters limit is 4-20';
          
        }
      }

    if (
        input["password"].trim() === null ||
        typeof input["password"] === "undefined" ||
        input["password"].trim() === "" ||
        input["password"].trim() === null
      ) {
        isValid = false;
        errors["password"] = "Enter your password";
  
      }
 

    if (typeof input["password"] !== "undefined") {
        let pattern = new RegExp(
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!#^%*?&])([a-zA-Z0-9@$#^!%*?&]{8,})$/
        );
        if (!pattern.test(input["password"])) {
          isValid = false;
          errors["password"]=
            'Password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character(@$.!#^%*?&_) and must not include spaces';
          
        }
      }
 
    if (typeof input["firstname"] !== "undefined") {
      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern1.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }

    if (input["firstname"] && typeof input["firstname"] !== "undefined") {
      if (input["firstname"].length > 0 && input["firstname"].length < 3) {
        isValid = false;
        errors["firstname"] = "Must contain atleast 3 characters";
      }
    }

    if (typeof input["lastname"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern2.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }

    if (rawPhone.length > 0 && rawPhone.length < 10) {
      isValid = false;
      errors["phone"] = "Please check numbers ";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };


  return (
    <div className="add-dr-page">
 

      <div className="add-dr-form-holder">

     
        <form className="add-dr-form" onSubmit={handleContinue} style={{width:"80%",backgroundColor:"rgb(217 214 189)"}}>
          <h4 className="title-of-page"> Add New Ward</h4>
          <div className="add-dr-div">
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Ward Name: </label>
              <input
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="buildname"
                onChange={handleChange}
              />
              {error.buildname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.buildname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Ward Number: </label>

              <input
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="buildnumber"
                onChange={handleChange}
              />
              {error.buildnumber ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.buildnumber}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> *Ward Type: </label>

              <Select
                           value={visitorDatas.roomtype}
                            onChange={(data,e)=>{RoomType(data,e)
                            }}
                            options={roomtype}
                            name='roomtype'
                            className="select-currency select-flex"
                        /> 
              {error.roomtype ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.roomtype}
                </div>
              ) : null}
            </div>
      
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Ward Price: </label>

              <input
                className="form-control"
                type="number"
                maxLength="50"
                placeholder=""
                name="roomprice"
                onChange={handleChange}
              />
              {error.roomprice ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.roomprice}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Building: </label>

              <input
                className="form-control"
                type="number"
                maxLength="50"
                placeholder=""
                name="roomprice"
                onChange={handleChange}
              />
           
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Floor: </label>

              <input
                className="form-control"
                type="number"
                maxLength="50"
                placeholder=""
                name="floor"
                onChange={handleChange}
              />
            
            </div>



        

            

          

        

            

          
           
          </div>
          <div className="form-button">
          <button type="submit" className="btn btn-primary btn-col">
            {" "}
            Add
          </button>{" "}
          </div>
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
        </form>

        {submitMsg !== "" ? (
          <SubmitPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setSubmitMsg("");
            }}
          />
        ) : null}

        {errorMsg !== "" ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
              setErrorMsg("");
            }}
          />
        ) : null}

        {/************************Unused***************
    {(() => {
      switch(submitMsg){
      case "success":
      return   (
          <div>
            <h5 style={{ color: "green" }}> Data submitted sucessfully !!</h5>{" "}

            


            <br />
            <br />
           
          
            
          </div>
        );

        case "error": 
        return (
          <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        );
        default: 
        return "";
           }
      })()}

    *************************/}

        {validateRawPhoneMsg === "error" ? (
          <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddWards;
