
import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css'; 
import "./doctorDashboard.css";
import request from "../../assets/images/icons/request1.png";  
import calendar from "../../assets/images/icons/calendar3.png";  
import patient from "../../assets/images/icons/patient.png";  
import wound from "../../assets/images/wound.png"

function DoctorDashboard() {
  const [loginDatas] = useContext(LoginContext);

  const [features,setFeatures]=useState(JSON.parse(sessionStorage.getItem("features")))
  
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <>
    <div>
  
    </div>
    <div >

      <div className="home">
      
        <h2 className="title-of-page"> {login_datas.name} </h2>
       
        <div className="home-container">
        
          <div className="home-tiles" 
          onClick={() => navigate("/patientlist")}
           >
             <img src={patient} className="home-icon" width="65px" />
            <h5 className="each-title"> Patient Records
            </h5>

          </div>

          {features.includes("schedule")&&<div className="home-tiles" 
           onClick={() => navigate("/doctor_schedule")}
          >
              <img  src={calendar} className="home-icon" width="65px" />
            <h5 className="each-title">Schedule</h5> 

          </div> }
          {features.includes("wound_management")&& <div className="home-tiles" 
          onClick={() => navigate("/wound_chart")}
           >
             <img src={wound} className="home-icon" width="65px" />
            <h5 className="each-title"> Wound Documentation
            </h5>

          </div>}
          {/* <div className="home-tiles" 
           onClick={() => navigate("/service-request")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Service Fulfillment</h5> 

          </div>  */}
                   
        </div>

      </div>

    </div>

    </>


  );
}

export default DoctorDashboard;