import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import DatePicker from 'react-date-picker';
import "./wound.css"
import PatientSearch from '../../common/PatientSearch';
function Actions(props) {
    const alert = useAlert()
  const [actionInfo,setActionInfo]=useState("")
  const [search, setSearch] = useState("");
  const [doctor, setDoctor] = useState("");
  const [options, setOptions] = useState([]);
  const [display, setDisplay] = useState(false);
  const [other, setOther] = useState(false);

  const handleAction= (e) => {
    const key = e.target.name;
   
    setActionInfo((currentstate) => ({
      ...currentstate,
      action_planned: key,
    })
    );

  };
  const trimspace=(val)=>{
    
    return  val.startsWith(' ')
    ? val.trimLeft()
    : val;
  }
  const handleTitle = (e) => {
    e.preventDefault();
    const val = trimspace(e.target.value);
    const key = e.target.name;
    setActionInfo(currentstate => ({
        ...currentstate,
       [key]: val
    }));

}

useEffect(() => {
    if(search!==""){
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/procedure-assignee/?q=${search}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        const assignee_List = [];
        if (res.data.status === "success") {
          const assigneeList = res.data.details;
          setOptions(assigneeList);
         setDisplay(true)
        }
      })
      .catch((err) => {});
    }
  }, [search]);

const submit = (e) => {
    e.preventDefault()
    const tokenString = sessionStorage.getItem('usertoken');
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let str = tokenString.replace(/["]+/g, '')
    let data={...props.general,...actionInfo,start_date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss")}
   
        axios.post(`${APIURL}/api/v1/service-provider/wound-info/?timeZone=${currentTimezone} `, data, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {


                if (res.data.status === "success") {

                    alert.success("successfully Updated")
                    props.onSuccess(res.data.wound_id)

                } else {


                }

            })
       
    }

const handleSearchChange = (e) => {
    setSearch(e.target.value)
    setDoctor(e.target.value)
    if (e.target.value === "") {
        setDisplay(false)
    }
}
return(
    <div>
         <div className='head-div'>
                <h6 style={{ padding: "1%" }}>Action to be taken</h6>
            </div>
            <div>
            <span className="text-style-here">Treatment Reference</span>
                        <input className='enquiryinput'
                       
                          type="text"
                          name="treatment_by" 
                          value={doctor} 
                          onChange={handleSearchChange}
                        
                          />
                           <div className='' >
                {
                    display ?

                        <div className='suggest-display ' style={{ width: "34%",backgroundColor:"white" }}>
                            <button style={{ backgroundColor: "#F8F8F8", marginLeft: "92%" }} variant="secondary" onClick={() => { setDisplay(false) }}>
                                <i class="fa fa-times-circle " style={{ color: "#0d6efd" }} aria-hidden="true"></i>
                            </button>
                            {
                               options.length > 0 ?options.map((item) => {
                                    return (
                                        <div style={{ fontWeight: "700" }} onClick={() => {
                                            setSearch("");setDoctor(item.full_name);setDisplay(false);
                                            setActionInfo(currentstate => ({
                                                ...currentstate,
                                                treatment_by:item.id
                                            }));
                                        }} className='suggest-item text-secondary'>
                                            <div className=''>{item.full_name}</div>
                                            <small className=''>ID:{item.id}</small>
                                         
                                        </div>
                                    )
                                }) : <h6 className='text-muted'>No Doctors found !!</h6>
                            }
                        </div>

                        : null
                }</div>
                          
            </div>
            <div className='row'>
                <div className='col-6'>
                <span className="text-style-here">Wound Cleansing</span>
                        <textarea className='enquiryinputtext'
                       maxLength="255"
                        type="text"
                         name="cleansing" 
                         value={actionInfo.cleansing} 
                           onChange={handleTitle}  
                          />
                </div>
                <div className='col-6'>
                <span className="text-style-here">Wound Dressing</span>
                        <textarea className='enquiryinputtext'
                       maxLength="255"
                          type="text"
                          name="dressing" 
                          value={actionInfo.dressing} 
                          onChange={handleTitle}  
                          />
                </div>

            </div>
            <div className='row'>
                <div className='col-6'>
                <span className="text-style-here">Other</span>
                        <textarea className='enquiryinputtext'
                       maxLength="255"
                        type="text"
                         name="other" 
                        value={actionInfo.other} 
                        onChange={handleTitle}  
                          />
                
                </div>

                <div className='col-6'>
                <div className='head-div' style={{marginTop:"1%"}}>
                <h6 style={{ padding: "1%" }}>Reason for action</h6>
            </div>
            <div style={{ padding: "1%" }}>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="wound_cleansing"
                                    name="wound_cleansing"
                                checked={actionInfo.action_planned==="wound_cleansing"}
                                onChange={(e) => { setOther(false); handleAction(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>To aid wound cleansing</label></>
                            </div>

                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="protect_granulation"
                                    name="protect_granulation"
                                    checked={actionInfo.action_planned==="protect_granulation"}
                                    onChange={(e) => { setOther(false); handleAction(e)  }}
                                   
                                />{" "}<label className="" style={{ marginTop: "2%" }}>To promote and protect granulation</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="stimulate_epithelization"
                                    name="stimulate_epithelization"
                                    checked={actionInfo.action_planned==="stimulate_epithelization"}
                                    onChange={(e) => { setOther(false); handleAction(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>To Stimulate epithelization</label></>
                            </div>
                            <div>
                            {!other?<><input
                                    className=""
                                    type="radio"
                                    id="stimulate_epithelization"
                                    name="stimulate_epithelization"
                                    checked={actionInfo.action_planned===""}
                                    onChange={(e) => { setActionInfo(currentstate => ({
                                        ...currentstate,
                                        action_planned: ""
                                    })); setOther(true)}}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Other</label></>:
                           <> 
                        <input className='enquiryinput'
                        maxLength="200"
                        type="text"
                         name="action_planned" 
                         value={actionInfo.action_planned} 
                           onChange={handleTitle}  
                          /></>}
                            </div>

                        </div>
                </div>

            </div>
           {!actionInfo.id&& <div className='text-center'>
                    <button onClick={submit} className='cons-button'>Submit</button>

                </div>}
    </div>
)
}
export default Actions