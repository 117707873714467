import React ,{useState, useContext} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';
import {ProfileContext} from '../contexts/ProfileContext'
import { useAlert } from 'react-alert';

function YearUpdate(props) {

    const[year ,setyear] =useState(props.year !== '' ? props.year :'')
    const alert = useAlert()
    const [error,setError]=useState({
        year:"",
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='year'){
            setyear(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
          var current_year = new Date().getFullYear();
          if (year==="" || year===null || year.length>4 ||year.length<4) {
            isValid = false;
            errors["year"] = "Enter or check the value";
          }
          if(year<1600 || year > current_year ){
            isValid = false;
            errors["year"] = "Year should be in range 1600 to current year";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitYear()
        }
      }

        const submitYear = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
                established_year : year
             }
          
             axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
               alert.error("Updated successfully")
               props.handle()        
            
              }else{
                alert.error("Error occured. Try again!")
              }
            
            })
            .catch(err=>{
              alert.error("Error occured. Try again!")
            })
          
          }

    return (
        <div>
            <div className="item"><input  type="text" className="form-control"   name="year" value={year} onChange={handleChange}/>

                {error.year ? <div className="error-validation-msg"> {error.year}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Established Year</button>
            </div>           
        </div>
    )
}

export default YearUpdate
