import React,{useState,useEffect} from "react";
import {Link, useNavigate,useParams} from "react-router-dom";
// import "./EditDoctor.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import axios from 'axios';


import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import BackButton from "../common/BackButton";


function EditDoctor(props){
   const [modalShow, setModalShow] = useState(false);
  const [doctorDatas,setDoctorDatas] = useState({
     name: "",
    
     phone: "",
     email:"",
     
     specializations: "",
      qualification : [],
     department: "",
     id: ''
    
    
 })
  
 const [error,setError]=useState({
  email: '',
  phone:"",
 });

  
const [submitMsg, setSubmitMsg]= useState("");
const [submitSuccessMsg, setSubmitSuccessMsg]= useState(""); 
const alert = useAlert();
const [rawPhone, setRawPhone] =useState("");
const [dialCode ,setDialCode] =useState('');
const [isValidEmail,setIsValidEmail]=useState(true);
const [isValidPhone,setIsValidPhone]=useState(true);
const [errorMsg, setErrorMsg] = useState("");
const [errorSubmit, setErrorSubmit] = useState(false);

// const id = props.match.params.id;
const {id} =useParams();

  
const navigate = useNavigate();


const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});

useEffect(()=> {
  window.scrollTo(0,0)
},[])


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['d']){  
   navigate('/doctorslist')
 }
 if(e.altKey && keys['a']){
   navigate('/hospital/add')
 }
 if(e.altKey && keys['b']){
   navigate('/bdo-list')
 }
 if(e.altKey && keys['o']){
   navigate('/hospital/add-bdo')
 }
 if(e.altKey && keys['n']){
   navigate('/emergency-number')
 }
 if(e.altKey && keys['s']){
   navigate('/service-request-list')
 }
 if(e.altKey && keys['l']){
   navigate('/license-details')
 }
 if(e.altKey && keys['p']){
   navigate('/license-payment')
 }
 if(e.altKey && keys['c']){
   navigate('/hosp-calllog')
 }
 if(e.altKey && keys['h']){
   navigate('/payment-history')
 }

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

useEffect(()=>{
  const tokenStr= sessionStorage.getItem('usertoken');
        if(tokenStr){
                 
                 const v = tokenStr;
                let v2= v.replace(/["]+/g, '') ;
       axios.get(`${APIURL}/api/v1/service-provider/doctors/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Token " + v2
        },
      })
    .then(res =>{

            if (res.data.status === "success") {
       
          if (res.data.doctor && res.data.doctor.phone && !res.data.doctor.phone.startsWith('91')) {
            res.data.doctor.phone = '91' + res.data.doctor.phone;
          }
           setDoctorDatas(res.data.doctor);
           window.original_value = res.data.doctor;

           
          } else if (res.data.status === "error") {
            
            setSubmitMsg(res.data.message)
             
          }else {
            
            setSubmitMsg("Error in fetching data!")
             
          }
  
            })
     .catch(err =>{
     setSubmitMsg("Error in fetching data!")
  
  })

   
 
    }
   
},[id])

const getChangedData = (doctorDatas) => {
  let objectByKeys = {};
  if (window.original_value) {
    let sda_changed_keys = Object.keys(doctorDatas).filter(key => doctorDatas[key] !== window.original_value[key]);
    sda_changed_keys.forEach(key => {
      objectByKeys[key] = doctorDatas[key]
    });
    delete window.original_value;
  }
  return objectByKeys;
}

	const handleSubmit=()=>{

   
        const tokenStr= sessionStorage.getItem('usertoken');
     
        if(tokenStr){
                 
                 const v = tokenStr;
                let v2= v.replace(/["]+/g, '') ;
                 
                
                 if(doctorDatas.name.startsWith("Dr.")){ 
                
                let dataToSend = getChangedData(doctorDatas);

     
 
       axios.put(`${APIURL}/api/v1/service-provider/doctors/${id}/`, dataToSend, { headers: {
     'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization : 'Token '+v2}})
    .then(res =>{
            

            if (res.data.status === "success") {
            alert.success('Updates successfully')
            navigate('/doctorslist')

          } else if (res.data.status === "error") {
         
              alert.error(res.data.message);
          }
          else {
         
              setSubmitMsg("Error in data update!");
          }
            
                
            })
     .catch(err =>{
   setSubmitMsg("Error in data update!");
   
  })  }
   
   else{
    alert.error("Please add Dr. as prefix of your name");
  }
}  
	}

  const handleCheckEmail = (e)=> {
    setIsValidEmail(true);
    if(e.target.value!== ""){
           setError(currentstate=>({
                  ...currentstate,
                email:  ''}));
      e.preventDefault();
      let key=e.target.name;
    
    
        const data = {
                [e.target.name]: e.target.value
            }
         
              axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
         'Content-Type': 'application/json'}})
        .then(res =>{
              
                if(res.data.status === "success"){
                  if(res.data.is_existing_user === true){
                 
                        setError(currentstate=>({
                  ...currentstate,
                [key]: `This ${key} is already registered!`}));
                      setIsValidEmail(false);
                     }
                       else{
            
                  setIsValidEmail(true);
                      
                }
                      
                        
                    }
                else{
            
                  setIsValidEmail(true);
                      
                }
                    
                })
        .catch(err =>{
      
        
      })
    }
  }


  const handleCheckExistingPhone= (e)=>{
    setIsValidPhone(true);
if(e.target.value !==""){
 
  setError(currentstate=>({
             ...currentstate,
           phone: ''}));

 

   const data = {
           code: dialCode,
           mobile_number: rawPhone
       }
      
         axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
    'Content-Type': 'application/json'}})
   .then(res =>{
        
           if(res.data.status === "success"){
             if(res.data.is_existing_user === true){
              
                 setError(currentstate=>({
             ...currentstate,
           phone: "This phone number is already registered!"}));
                  setIsValidPhone(false);
                }
                 else{

             setIsValidPhone(true);
                 
           }
                
                  
                   
               }
           else{

             setIsValidPhone(true);
                 
           }
               
           })
   .catch(err =>{

   
 })

}
 

}

  const handleContinue= async (e)=>{

    e.preventDefault();
   
    if(validate() && isValidEmail && isValidPhone){
      handleSubmit();
    }

}

const handleChange= e=>{
      e.preventDefault();
      const val=e.target.value;
      const key=e.target.name;


    
         setDoctorDatas(currentstate=>({
            ...currentstate,
            [key]: val
          }));
      
}

const handleQualification=e=>{
  e.preventDefault();
  const val=e.target.value;
      const key=e.target.name;
      let arr=[];
      if(val && val.includes(',')){
        arr = val.split(',');
      }
      else{
      arr.push(val);
      }
      setDoctorDatas(currentstate=>({
        ...currentstate,
        [key]: arr
      }));
   
}
const handlePhoneInput= (value, data, event, formattedValue)=>{
  
 
      setRawPhone(value.slice(data.dialCode.length));
      setDialCode(value.slice(0,data.dialCode.length))
     setDoctorDatas(currentstate=>({
        ...currentstate,
           phone: value

                       }));
  
  

 }

 const validate = ()=> {

  let input = doctorDatas
  let errors = {};
  let isValid = true;
  setError({});

  if (!input["name"] || input["name"]==="" ) {
    isValid = false;
    errors["name"] = "Enter first name";
  }

  if (!input["specializations"] || input["specializations"]==="") {
    isValid = false;
    errors["specializations"] = "Enter specializations";
  }

  if (!input['qualification'] || input['qualification'].length===0 || input['qualification'][0]==="") {
    isValid = false;
    errors["qualification"] = "Enter qualification";
  }

  if (!input['phone'] || input['phone']==="") {
    isValid = false;
    errors["phone"] = "Enter phone number";
  }

  if (rawPhone.length > 0 && rawPhone.length < 10) {
    isValid = false;
    errors["phone"] = "Please Enter valid phone number ";
  }
  if (typeof input["email"] !== "undefined") {
      
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Enter a valid email address.";
    }
  }

  if (typeof input["name"] !== "undefined") {
    var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
    if (pattern1.test(input["name"])) {
      isValid = false;
      errors["name"] = "Special Characters are not allowed";
    }
  }

  if (!input["email"]) {
    isValid = false;

    errors["email"] = "Enter email Address.";
  }
  
  if(isValidPhone===false){
    isValid = false;
    errors["phone"] = "Phone number already registered!";
  }
  if(isValidEmail===false){
    isValid = false;
    errors["email"] = "Email already registered!";
  }

  setError(currentstate=>({
    ...currentstate,
    ...errors
  }));


return isValid;

 }
  

	return(<div className="add-dr-page" >



    <div className="add-dr-form-holder">
     
    
     <div className="quick-links">
      <Link to="/doctorslist"><button type="button" className="btn btn-primary btn-col dr-btn btn-custom"> Goto Doctors List </button></Link>

       </div>
		<form className="add-dr-form" onSubmit={handleContinue}>
		<h4 className="title-of-page"> Update Doctor details</h4>
    <div className="add-dr-div">
		   <div className="form-group col-lg-6">
           <label > *Full Name: </label>
          <input className="form-control" type="text" maxLength='30' name="name"  value={doctorDatas.name} onChange={handleChange} />
          {error.name ? <div style={{zIndex:'auto'}} className="error-validation-msg">{error.name}</div> : null}
        </div>
       
        <div className="form-group col-lg-6">
         <label> *Email: </label>
          <input className="form-control " type="email" maxLength='50' value={doctorDatas.email} name="email" onChange={handleChange} onBlur={handleCheckEmail} />
          {error.email ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.email}</div>: null}
        </div>
        <div className="form-group col-lg-6">
            <label> *Phone Num: </label>
           
   <PhoneInput
                inputProps={{
                                                  
                                                  
                      }}
                country={"in"}
                onlyCountries={['in']}
                  countryCodeEditable={false}
                  value = {doctorDatas.phone}
                  onChange={handlePhoneInput}
                  onBlur={handleCheckExistingPhone}
                                          />
          {error.phone ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.phone}</div> : null}                      

        </div>


        
        <div className="form-group col-lg-6">


          <label> *Qualification: </label>
          <input className="form-control" type="text" maxLength='150' value={doctorDatas.qualification} name="qualification"  onChange={handleQualification} />
          {error.qualification ? <div style={{zIndex:'auto'}} className="error-validation-msg">{error.qualification}</div> : null}
        </div>
        <div className="form-group col-lg-6">

          <label> *Specializations: </label>
          <input className="form-control" type="text" maxLength='150' value={doctorDatas.specializations} name="specializations"  onChange={handleChange} />
          {error.specializations ? <div style={{zIndex:'auto'}} className="error-validation-msg">{error.specializations}</div> : null}
        </div>
        
       <div className="form-group col-lg-6">
          <label> Department: </label>
          <input className="form-control" type="text" maxLength='150' value={doctorDatas.department} name="department"  onChange={handleChange}/>
        </div>

        
       



        
      </div>
        <button type="submit" className="btn btn-primary btn-col"> Submit Data</button> 
		</form>





    {submitMsg!=="" && submitMsg!==null?
     <h5 style={{ color: "red" }}>
            
            {submitMsg}
          </h5> : 
          null
   }   
    </div>
    </div>
		)
}

export default EditDoctor;
