import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  Navigate,
  NavLink,
} from "react-router-dom";
import admission from "../../assets/images/icons/admission.png";
import { LoginContext } from "../contexts/LoginContext";
import "font-awesome/css/font-awesome.min.css";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import "./hospital.css";
//import Navbar from "../common/Navbar"
//mport Footer from "../common/Footer";
import prof from "../../assets/images/icons/doctor1.png";
import request from "../../assets/images/icons/request1.png";
import patient from "../../assets/images/icons/patient.png";
import bdo from "../../assets/images/icons/bdo.png";
import carer from "../../assets/images/icons/carer.webp";
import sda from "../../assets/images/icons/sda1.png";
import pharmacy from "../../assets/images/icons/pharmacy.png";
import lab from "../../assets/images/icons/lab.png";
import nurse from "../../assets/images/icons/nurse1.png";
import allied from "../../assets/images/icons/allied.png";
import fac from "../../assets/images/icons/facility.png";
import manager from "../../assets/images/icons/manager.png";
import visitor from "../../assets/images/icons/visitor.png";
function HomePage() {
  const [features, setFeatures] = useState(JSON.parse(sessionStorage.getItem("features")))
  const [web_users, setweb_users] = useState(JSON.parse(sessionStorage.getItem("web_users")))
  const [app_users, setapp_users] = useState(JSON.parse(sessionStorage.getItem("app_users")))
  const [loginDatas] = useContext(LoginContext);
  const projType = sessionStorage.getItem("project_type")
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div>
      {/* <Navbar /> */}
      <div className="home">
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h5 className="subtitle-of-page" style={{ marginBottom: "0%" }}>{login_datas.location}</h5>

        <div className="home-container" style={{ marginTop: "1%" }}>


          {features.includes("ip_form_admission") && <div className="home-tiles"
            onClick={() => navigate("/admissionchairman")}
          >
            <img src={admission} className="home-icon" width="65px" />
            <h5 className="each-title">Admissions</h5>

          </div>}
          <div className="home-tiles"
            onClick={() => navigate("/patientlist")}
          >
            <img src={patient} className="home-icon" width="65px" />
            <h5 className="each-title"> Patient Records
            </h5>

          </div>


          {(web_users["hospital_manager"] === "True" || app_users["hospital_manager"] === "True") && <> <div className="home-tiles" onClick={() => navigate("/add-manager")}>
            <img src={manager} className="home-icon" width="60px" />
            <h5 className="each-title"> Add Manager</h5>
          </div>

            <div className="home-tiles" onClick={() => navigate("/manager-list")}>
              <img src={request} className="home-icon" width="65px" />
              <h5 className="each-title">Manager List</h5>
            </div></>}
            {features.includes("visitor_registration") &&<div className="home-tiles"
            onClick={() => navigate("/visitor_requests")}
          >
            <img src={visitor} className="home-icon" width="65px" />
            <h5 className="each-title"> Visitors
            </h5>

          </div>}


        </div>
      </div>

    </div>
  );
}

export default HomePage;
