import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import "./schedule.css";

import { useAlert } from 'react-alert'
function AddPayment(props) {
    const alert = useAlert()
    const [isChange, setIsChange] = useState(false)
    const [isChange1, setIsChange1] = useState(false)
    const [fee, setFee] = useState('')
    const [feeOnline, setFeeOnline] = useState('')
    const [mode, setMode] = useState("")
    const [activate, setActivate] = useState(false)
    const [id, setId] = useState(0)
    const [idOnline, setIdOnline] = useState(0)
    const [update, setUpdate] = useState(false)
    const [update1, setUpdate1] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [isActive1, setIsActive1] = useState(false)
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {

        if (!isActive && !isActive1) {
            props.onSuccess("0")
        }
        else if (isActive && !isActive1)
            props.onSuccess("1")
        else if (!isActive && isActive1) {
            props.onSuccess("2")
        } else if (isActive && isActive1) {
            props.onSuccess("3")
        }
    }, [isActive, isActive1])
    useEffect(() => {
        if (mode !== "")
            callActivate()
    }, [activate])
    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/doctor/consultation-fees/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data[0].consult_mode === 'offline') {
                        if (res.data[0].fees !== "") {
                            setUpdate(true)
                        }
                        setId(res.data[0].id)
                        setIsChange(true)
                        setFee(res.data[0].fees)
                        setIsActive(res.data[0].is_active)

                    } else {

                        if (res.data[0].fees !== "") {
                            setUpdate1(true)
                        }
                        setIdOnline(res.data[0].id)
                        setIsChange1(true)
                        setFeeOnline(res.data[0].fees)
                        setIsActive1(res.data[0].is_active)
                    }
                    if (res.data[1] !== undefined) {
                        if (res.data[1].consult_mode === 'offline') {
                            if (res.data[1].fees !== "") {
                                setUpdate(true)
                            }
                            setId(res.data[1].id)
                            setIsChange(true)
                            setFee(res.data[1].fees)
                            setIsActive(res.data[1].is_active)
                        } else {
                            if (res.data[1].fees !== "") {
                                setUpdate1(true)
                            }
                            setIdOnline(res.data[1].id)
                            setFeeOnline(res.data[1].fees)
                            setIsChange1(true)
                            setIsActive1(res.data[1].is_active)
                        }
                    }
                }
            })

    }, [refresh]);
    const callSave = () => {

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        let data = {}

        data = {
            fees: mode === "offline" ? fee : feeOnline,
            consult_mode: mode,
        }

        if (fee < 1) {
            alert.error("Please Enter valid number")
        }
        else if (feeOnline < 1) {
            alert.error("Please Enter valid number")
        }
        else {

            axios.post(`${APIURL}/api/v1/doctor/consultation-fees/`, data, {
                headers: {

                    "Authorization": 'Token ' + str
                }
            })
                .then(res => {



                    if (res.data.status === "success") {
                        //setToggle(false)

                        alert.success("Successfully added")
                        setMode("")
                        setRefresh(!refresh)



                    }
                })
                .catch(err => {


                })
        }

    }
    const callUpdate = () => {

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        let data = {}
        if (fee < 1) {
            alert.error("Please Enter valid Amount")
        }
        else if (feeOnline < 1) {
            alert.error("Please Enter valid amount")
        }
        else {
            if (mode === "offline") {
                data = {
                    fees: fee,

                }
            } else {
                data = {
                    fees: feeOnline,

                }
            }


            axios.put(`${APIURL}/api/v1/doctor/consultation-fees-detail/${mode === "offline" ? id : idOnline}/`, data, {
                headers: {

                    "Authorization": 'Token ' + str
                }
            })
                .then(res => {



                    if (res.data.status === "success") {
                        //setToggle(false)

                        alert.success("Successfully Updated")
                        setMode("")
                        setRefresh(!refresh)
                    }
                    else {
                        alert.error("Error while Updating")
                    }
                })
                .catch(err => {
                    alert.error("Error while Updating")
                    setRefresh(!refresh)
                })

        }
    }
    const callActivate = () => {

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        let data = {}

        if (mode === "offline") {
            data = {
                is_active: !isActive,

            }
        } else {
            data = {
                is_active: !isActive1,

            }
        }


        axios.put(`${APIURL}/api/v1/doctor/consultation-fees-detail/${mode === "offline" ? id : idOnline}/`, data, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {



                if (res.data.status === "success") {
                    //setToggle(false)

                    alert.success("Successfully Updated")
                    setMode("")
                    setRefresh(!refresh)
                }
                else {
                    alert.error("Error while Updating")
                }
            })
            .catch(err => {
                alert.error("Error while Updating")
                setRefresh(!refresh)
            })


    }

    const handleChange = (e) => {
        const val = e.target.value;
        const key = e.target.name;
        setMode(key)
        if (key === "offline") {
            setFee(val);

        }
        else if (key === "online") {
            setFeeOnline(val);

        }


    }

    return (
        <div className="consultation">
            <h4 className="title-of-consultation">Consultation Fee</h4>
            <div className="conslt-div">
                {isChange ? <>
                    <i className={isActive ? "fa fa-toggle-on " : "fa fa-toggle-on fa-rotate-180 "}
                        style={{ color: isActive ? "green" : "red", cursor: "pointer", marginTop: "1%", marginLeft: '3%' }}
                        onClick={() => { setMode("offline"); setActivate(!activate) }}
                    ></i></> : ""}
                <label className="col-4 label-consult" style={{ paddingTop: "1%", }}>In-person:</label>
                <input
                    disabled={isChange}
                    style={{ textAlign: "center", width: "87px", color: isActive ? "black" : "silver" }}
                    className='confinput-consult'
                    type="number"
                    min={0}
                    name="offline"
                    placeholder="In RS/-"
                    value={fee}
                    onChange={handleChange}


                ></input>
                {isChange
                    ? <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span><button style={{ backgroundColor: "#ffffff" }} disabled={!isActive1}><i disabled={!isActive} className="fas fa-pencil-alt"
                        style={{ color: isActive ? "#007bff" : "silver", cursor: "pointer", marginTop: "1%" }}
                        onClick={() => { setIsChange(false) }}
                    ></i></button></> : <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <i className="fa fa-floppy-o"
                            style={{ marginTop: "1%", color: "#5a9569", cursor: "pointer" }}
                            onClick={update ? callUpdate : callSave}
                        ></i></>}
            </div>
            <div className="conslt-div">
                {isChange1 ? <> <i className={isActive1 ? "fa fa-toggle-on " : "fa fa-toggle-on fa-rotate-180 "}
                    style={{ color: isActive1 ? "green" : "red", cursor: "pointer", marginTop: "1%", marginLeft: '3%' }}
                    onClick={() => { setMode("online"); setActivate(!activate) }}
                ></i></> : ""}
                <label className='col-4 label-consult' style={{ paddingTop: "1%" }}>Online:</label>
                <input
                    disabled={isChange1}
                    style={{ textAlign: "center", width: "87px", color: isActive1 ? "black" : "silver" }}
                    className='confinput-consult'
                    type="number"
                    min={0}
                    name="online"
                    placeholder="In RS/-"
                    value={feeOnline}
                    onChange={handleChange}

                ></input>
                {isChange1
                    ? <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span><button style={{ backgroundColor: "#ffffff" }} disabled={!isActive1}><i className="fas fa-pencil-alt "
                        style={{ color: isActive1 ? "#007bff" : "silver", cursor: "pointer", marginTop: "1%" }}
                        onClick={() => { setIsChange1(false) }}
                    ></i></button></> : <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <i className="fa fa-floppy-o "
                            style={{ color: "#5a9569", cursor: "pointer", marginTop: "1%" }}
                            onClick={update1 ? callUpdate : callSave}
                        ></i></>}
            </div>
        </div>
    )
}
export default AddPayment;