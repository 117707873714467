import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from "../../../Global";
import { useNavigate } from "react-router-dom";
import { useAlert  } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment, { invalid } from "moment";
import "./deathreport.css";
import PatientSearch from '../../common/PatientSearch';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DatePicker as DatePicker1 } from 'rsuite';
import DatePicker from 'react-date-picker';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const { allowedRange } = DateRangePicker;
function DeathReportForm(props) {
    const [patient, setPatient] = useState({
        full_name: props.patient.id && props.patient.patient_name,
        id: props.patient.id && props.patient.patient,
        gender: props.patient.id && props.patient.gender,
        location: props.patient.id && props.patient.location
    })
    const now =new Date()
    const navigate = useNavigate();
    const [deathInfo, setDeathInfo] = useState(props.patient)
    const [phone, setPhone] = useState(props.patient.id && props.patient.country_code + props.patient.retriever_contact)
    const [fileName, setFileName] = useState(props.patient.id && props.patient.consent !== null ? "Consent Uploaded" : "Upload Consent");
    const [admissionDate, setAdmissionDate] = useState(null)
    const [deId, setdeId] = useState(props.patient.id && props.patient.id)
    const [search, setSearch] = useState("");
    const [doctor, setDoctor] = useState(props.patient.id ? props.patient.doctor_confirmed : "");
    const [options, setOptions] = useState([]);
    const [display, setDisplay] = useState(false);
    const [date, setDate] = useState(props.patient.id ? new Date(props.patient.date_of_death):null)
    const [time, setTime] = useState(props.patient.id && new Date(props.patient.date_of_death + " " + props.patient.time_of_death))
    const [hr, setHr] = useState()
    const [mn, setMN] = useState()
    const inputRefs = useRef([])
  const submitButtonRef = useRef (null)
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {  
      e.preventDefault()

      if(index === 12 ) {
        submitButtonRef.current.click();
      }else{
      e.preventDefault();    
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        // move focus to the next input if it exists
        inputRefs.current[nextIndex].focus();
      }
    }
    }
  };
  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()

      const activeElement = document.activeElement;
      if (
        !inputRefs.current.includes(activeElement) &&
        activeElement !== submitButtonRef.current
      ) {

        submitButtonRef.current.click();
      }
    }else if (e.altKey && e.key === 's') {

      submitButtonRef.current.click(); 
    }else if (e.altKey && e.key === 'c') {
        navigate("/hospital_bdo");
      }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
  
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);


useEffect(() => {
if (deathInfo.date_of_death === moment(new Date()).format("yyyy-MM-DD")) {
setHr(new Date().getHours())
          setMN(new Date().getMinutes())
        }
        else {
            setHr(24)
            setMN(60)
        }
    }, [deathInfo.date_of_death])

    const alert = useAlert()

    useEffect(() => {
        if (props.patient.id) {
            
            setAdmissionDate((props.patient.admitted_date).slice(0,10))
        }

        else if (patient.id) {
            setAdmissionDate((patient.admission_date).slice(0,10))
        }


    }, [props.patient.id, patient.id]);
    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }

    const submit = () => {
        const [hours, minutes, seconds] =deathInfo.time_of_death? deathInfo.time_of_death.split(':'):[];
        const dynamicTime = new Date();
        dynamicTime.setHours(parseInt(hours), parseInt(minutes), parseInt(seconds), 0);
        
        if (!patient.id) {
            alert.error("Select the patient")
        }

        else if (!deathInfo.date_of_death) {
            alert.error("Select date of demise")
        }
        else if (!deathInfo.time_of_death) {
            alert.error("Select Time of demise")
        }
       
        else if(deathInfo.date_of_death===moment(new Date()).format('YYYY-MM-DD')&&dynamicTime > new Date()){
          
            alert.error("You opted for a future timeframe.please select a valid time of demise")
            
        }
        else if (!deathInfo.death_confirmed_by) {
            alert.error("Enter The doctor who confirmed the death")
        }
        else if (!deathInfo.place_of_demise) {
            alert.error("Enter Place of Demise")
        }
        else if (!deathInfo.retriever_name) {
            alert.error("Enter retriever's name")
        }
       
        else if (!deathInfo.retriever_contact) {
            alert.error("Enter retriever's contact number")
        }
        else if (!deathInfo.retriever_address) {
            alert.error("Enter retriever's address")
        }
       
        else if (!deathInfo.relationship) {
            alert.error("Enter Relationship with the deceased")
        } 
        
        else {
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const tokenString = sessionStorage.getItem('usertoken');

            let str = tokenString.replace(/["]+/g, '')
            const formData = new FormData();
            formData.append('patient', patient.id);
            Object.keys(deathInfo).forEach(key => {
                formData.append(key, deathInfo[key]);
            });
            axios.post(`${APIURL}/api/v1/service-provider/patient-death/?timeZone=${currentTimezone} `, formData, {
                headers: {

                    "Authorization": 'Token ' + str
                }
            })
                .then(res => {


                    if (res.data.status === "success") {
                        if (res.data.message === "Successfully Created") {
                            alert.success(res.data.message)
                            props.onSuccess()

                        }
                        else {
                            alert.error(res.data.message)
                        }


                    } else {


                    }

                })
        }
    }
    const handleTitle = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;
        setDeathInfo(currentstate => ({
            ...currentstate,
            [key]: val
        }));

    }

    const handleDate = (date) => {

        setDate(date)

        const a = date !== null ? moment(date).format('YYYY-MM-DD') : null;
        setTime(null)
        setDeathInfo(currentstate => ({
            ...currentstate,
            date_of_death: a !== null ? a : "",
            time_of_death: null
        }));

    }
    const handleTime = (time) => {
        setTime(time)
        const a = moment(time).format('HH:mm:ss');

        setDeathInfo(currentstate => ({
            ...currentstate,
            time_of_death: a
        }));

    }
    const handlePhoneInput = (value, data, event, formattedValue) => {
        const rawPhone = value.slice(data.dialCode.length);
        const dialcode = value.slice(0, data.dialCode.length);
        if (value.length === 12) {
            setPhone(value)
            setDeathInfo(currentstate => ({
                ...currentstate,
                retriever_contact: rawPhone,
                country_code: dialcode

            }));

        }
        else {
            setPhone("")
            setDeathInfo(currentstate => ({
                ...currentstate,
                retriever_contact: "",
                country_code: "91"

            }));

        }

    }
    const validatePhoneInput = (value,) => {
        if (value.length !== 2) {
            if (value.length !== 12) {
                return "Invalid phone number";

            }
            else {
                return true
            }
        }

    }
    const reader = new FileReader();


    const handleFile = async (e) => {
        
        const key = e.target.name;
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file);
      
        if (!file) {
            setDeathInfo(currentstate => ({ ...currentstate, consent: null }))
          alert.error("Please Select a File");
          setFileName("Upload File");
          return;
        }
      
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            setDeathInfo(currentstate => ({ ...currentstate, consent: null }))
          alert.error("Select a valid image or PDF file");
          setFileName("Upload File");
          return;
        }
      
        if (file.size > 10e6) {
            setDeathInfo(currentstate => ({ ...currentstate, consent: null }))
          alert.error("Select a file smaller than 10MB");
          setFileName("Upload File");
          return;
        }
       
          const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;
      
          if (file.type.startsWith('image/')) {
            setFileName(shortFileName);
            setDeathInfo(currentstate => ({ ...currentstate, consent: file }))
          }  if (file.type === 'application/pdf') {
          // Handle PDF file
          setFileName(file.name);
          setDeathInfo(currentstate => ({ ...currentstate, consent: file }))
        
      }
      };
    // const handleFile = e => {

    //     const file = e.target.files[0];

    //     if (!file) {
    //         setDeathInfo(currentstate => ({ ...currentstate, consent: null }))
    //         alert.error("Not a file")
    //         setFileName('Upload Consent');
    //         return false
    //     }
    //     if (!file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
    //         setDeathInfo(currentstate => ({ ...currentstate, consent: null }))
    //         alert.error("Not a valid image")
    //         setFileName('Upload Consent');
    //         return false;
    //     }
    //     if (file.size > 2e6) {
    //         setDeathInfo(currentstate => ({ ...currentstate, consent: null }))
    //         // setSizeShow(!sizeShow)
    //         setFileName('Primary Form');
    //         return false
    //     }
    //     reader.onload = (evt) => {
    //         const img = new Image();
    //         img.onload = () => {
    //             setDeathInfo(currentstate => ({ ...currentstate, consent: file }))
    //             setFileName("Consent Uploaded");
    //         };

    //         img.onerror = () => {
    //             setDeathInfo(currentstate => ({ ...currentstate, consent: null }))
    //             // setContentShow(!contentShow)
    //             setFileName('Upload Consent');

    //             return false;
    //         };
    //         img.src = evt.target.result;
    //     };
    //     reader.readAsDataURL(file);


    // };

    const getChangedData = (bdoDatas) => {
        let objectByKeys = {};
        if (props.patient) {
            let sda_changed_keys = Object.keys(bdoDatas).filter(key => bdoDatas[key] !== props.patient[key]);
            sda_changed_keys.forEach(key => {
                objectByKeys[key] = bdoDatas[key]
            });

        }
        return objectByKeys;
    }

    const update = () => {
        const [hours, minutes, seconds] =deathInfo.time_of_death? deathInfo.time_of_death.split(':'):[];
        const dynamicTime = new Date();
        dynamicTime.setHours(parseInt(hours), parseInt(minutes), parseInt(seconds), 0);
        if (!deathInfo.date_of_death) {
            alert.error("Select date of demise")
        }
        else if (!deathInfo.time_of_death) {
            alert.error("Select Time of demise")
        }
        else if(deathInfo.date_of_death===moment(new Date()).format('YYYY-MM-DD')&&dynamicTime > new Date()){
          
            alert.error("You opted for a future timeframe.please select a valid time of demise")
            
        }
        else if (!deathInfo.death_confirmed_by||deathInfo.death_confirmed_by==="") {
            alert.error("Enter The doctor who confirmed the death")
        }
        else if (!deathInfo.place_of_demise||deathInfo.place_of_demise==="") {
            alert.error("Enter Place of Demise")
        }
        else if (!deathInfo.retriever_name || deathInfo.retriever_name === "") {
            alert.error("Enter retriever's name")
        }
       
        else if (!deathInfo.retriever_contact || deathInfo.retriever_contact === "") {
            alert.error("Enter retriever's contact number")
        }
        else if (!deathInfo.retriever_address || deathInfo.retriever_address === "") {
            alert.error("Enter retriever's address")
        }
       
        else if (!deathInfo.relationship||deathInfo.relationship==="") {
            alert.error("Enter Relationship with the deceased")
        } 
      
        else {
            const tokenString = sessionStorage.getItem('usertoken');

            let str = tokenString.replace(/["]+/g, '')

            let dataToSend = getChangedData(deathInfo);
            const formData = new FormData();

            Object.keys(dataToSend).forEach(key => {
                formData.append(key, dataToSend[key]);
            });
            formData.append("status", "pending");

            axios.put(`${APIURL}/api/v1/service-provider/death-status/?de_id=${deId}`, formData, {
                headers: {

                    "Authorization": 'Token ' + str
                }
            })
                .then(res => {


                    if (res.data.status === "success") {
                        Object.keys(deathInfo).forEach(key => delete deathInfo[key]);
                        Object.keys(props.patient).forEach(key => delete props.patient[key]);
                        alert.success("successfully Updated")
                        props.onSuccess()
                    } else {


                    }

                })
        }
    }
    useEffect(() => {
        if (search !== "") {
            const tokenString = sessionStorage.getItem("usertoken");

            let str = tokenString.replace(/["]+/g, "");

            axios
                .get(`${APIURL}/api/v1/service-provider/procedure-assignee/?q=${search}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {

                    if (res.data.status === "success") {
                        const assigneeList = res.data.details;
                        setOptions(assigneeList);
                        setDisplay(true)
                    }
                })
                .catch((err) => { });
        }
    }, [search]);
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
        setDoctor(e.target.value)
        if (e.target.value === "") {
            setDisplay(false)
            setDeathInfo(currentstate => ({
                ...currentstate,
                death_confirmed_by: ""
            }));
        }
       
    }
    return (
        <div className='' style={{ paddingTop: "2%", paddingBottom: "2%", marginTop: "2%" }}>
            <div className='enquirycontainer' style={{ paddingTop: "1%", paddingLeft: "2%", paddingRight: "2%" }}>
                <h5 className="enquiryheader">Form for transfer of the deceased</h5>
                {!props.patient.patient && <PatientSearch onSuccess={(data) => {
                    setPatient(data); setDate(""); setDeathInfo(currentstate => ({
                        ...currentstate,
                        date_of_death:  "",
                        time_of_death: null
                    }));
                   
                }} />}

                <div className='row' style={{ marginTop: "2%" }}>

                    <div className='col-5'><label className='text-style-here ' >*Patient Name</label><br />
                        <input className='enquiryinput' disabled value={patient.full_name} style={{ cursor: "not-allowed" }} /></div>

                    <div className='col-3'><label className='text-style-here ' >ID</label><br />
                        <input disabled value={patient.id} className='enquiryinput' style={{ cursor: "not-allowed" }} /></div>

                    <div className='col-4'><label className='text-style-here '>Gender</label><br />
                        <div style={{ paddingTop: "3%", cursor: "not-allowed" }} className='enquiryinput' >&nbsp; &nbsp; <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={patient.gender === "male"}
                        />

                            <label htmlFor="male">Male</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="female"
                                checked={patient.gender === "female"}
                            />

                            <label htmlFor="female">Female</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="others"
                                name="gender"
                                value="others"
                                checked={patient.gender === "others"}

                            />
                            <label htmlFor="others">Others</label>
                        </div>
                    </div>

                </div><br/>
                <div className='row'>
                <div className='row' style={{justifyContent:'flex-end', alignItems:"flex-end"}}>

                    
                        <div className='col-2 '>
                            <label className='text-style-here ' >*Date of demise</label><br />

                            <DatePicker
                                disabled={!patient.id}
                                style={{ border: "none" }}
                                className="enquiryinput deathtime"
                                appearance="default"
                                format="yyyy-MM-dd"
                                minDate={new Date(admissionDate)}
                                maxDate={new Date()}
                                onChange={date => { handleDate(date) }}
                                // disabledDate={allowedRange(admissionDate, new Date())}
                                onClear={() => { }}
                                value={(props.patient.id||patient.id)&&date}

                               
    
                            />
                        </div>
                        <div className='col-2 '>
                            <label className='text-style-here ' >*Time of demise</label><br />

                            <DatePicker1
                                disabled={date===""}
                                style={{ border: "none" }}
                                className="enquiryinput deathtime"
                                appearance="default"
                                format="HH:mm:ss"
                                onChange={time => { handleTime(time) }}
                                disabledHours={hour => hour > hr}
                                // disabledMinutes={minute => minute > mn}

                                onClear={() => { }}
                                value={time}

                            />
                        </div>
                    <div className='col-4' >
                        <label className='text-style-here ' >Period of stay at the peace village</label><br />
                        <input disabled style={{ cursor: "not-allowed" }} value={deathInfo.date_of_death && deathInfo.date_of_death !== "" && admissionDate !== null && admissionDate + " to " + deathInfo.date_of_death} onChange={handleTitle} className='enquiryinput' />



                    </div>
                    <div className='col-4'>
                        <label className="text-style-here">* Death confirmed by</label>
                        <input className='enquiryinput'
                            ref={el => inputRefs.current[0] = el} 
                            onKeyDown={(e) => handleKeyDown(e,0)}
                            type="text"
                            name="treatment_by"
                            value={doctor}
                            onChange={handleSearchChange}

                        /></div>
                        <div className='col-4' style={{textAlign:"right"}}>
                            {
                                display ?

                                    <div className='suggest-display ' style={{ width: "100%", backgroundColor: "white" }}>
                                        <button style={{ backgroundColor: "#F8F8F8", marginLeft: "92%" }} variant="secondary" onClick={() => { setDisplay(false) }}>
                                            <i class="fa fa-times-circle " style={{ color: "#0d6efd" }} aria-hidden="true"></i>
                                        </button>
                                        {
                                            options.length > 0 ? options.map((item) => {
                                                return (
                                                    <div style={{ fontWeight: "700" }}
                                                    onClick={() => {
                                                        setSearch(""); setDoctor(item.full_name); setDisplay(false);
                                                        setDeathInfo(currentstate => ({
                                                            ...currentstate,
                                                            death_confirmed_by: item.id
                                                        }));
                                                    }} className='suggest-item text-secondary'>
                                                        <div className=''>{item.full_name}</div>
                                                        <small className=''>ID:{item.id}</small>

                                                    </div>
                                                )
                                            }) : <h6 className='text-muted'>No Doctors found !!</h6>
                                        }
                                    </div>

                                    : null
                            }</div>
                    </div>
                </div><br/>
                <div className='row'>

                    <div className='col-6'>
                        <label className="text-style-here">Reason of death</label>
                        <textarea name="reason_of_death" maxLength="300"
                            ref={el => inputRefs.current[1] = el} 
                            onKeyDown={(e) => handleKeyDown(e,1)}
                            value={deathInfo.reason_of_death}
                            onChange={handleTitle} className='enquiryinputtext' />
                    </div>
                    <div className='col-6'>
                        <label className="text-style-here">Cremation details</label>
                        <textarea name="cremation_detail" maxLength="300"
                            ref={el => inputRefs.current[2] = el} 
                            onKeyDown={(e) => handleKeyDown(e,2)}
                            value={deathInfo.cremation_detail}
                            onChange={handleTitle} className='enquiryinputtext' />
                    </div>
                </div><br/>
                <div className='row'>
                    <div className='col-6'><label className='text-style-here ' >*Place of demise</label><br />
                        <input ref={el => inputRefs.current[3] = el} onKeyDown={(e) => handleKeyDown(e,3)} name="place_of_demise" value={deathInfo.place_of_demise} onChange={handleTitle} className='enquiryinput' /></div>
                    <div className='col-6'><label className='text-style-here ' >The location to which the deceased will be transported </label><br />
                        <input ref={el => inputRefs.current[4] = el} 
                            onKeyDown={(e) => handleKeyDown(e,4)}
                            name="destination" value={deathInfo.destination}
                            onChange={handleTitle} className='enquiryinput' /></div>

                </div>
                <div><br />
                    <h6 className='enquiryheader' > Details about the individual who retrieves the deceased.</h6>
                </div>
                <div className='row'>
                    <div className='col-6 row'>
                        <div className='col-6'><label className='text-style-here ' >*Name</label><br />
                            <input name="retriever_name"
                                ref={el => inputRefs.current[5] = el} 
                                onKeyDown={(e) => handleKeyDown(e,5)}
                                value={deathInfo.retriever_name}
                                onChange={handleTitle} className='enquiryinput' /></div>
                        <div className='col-6'><label className='text-style-here ' >*Contact number</label><br />
                            <PhoneInput
                                className='enquiryinput'
                                style={{ width: "100%", border: "none" }}
                                country={'in'}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                value={phone !== "" ? phone : "91"}
                                isValid={validatePhoneInput}
                                onChange={handlePhoneInput}
                            />

                        </div>
                        <div className='col-12'><label className='text-style-here ' >*Relationship with the deceased</label><br />
                            <select

                                style={{ backgroundColor: "white", paddingLeft: "1%" }}
                                className="enquiryinput"
                                value={deathInfo.relationship}
                                name="relationship"
                                onChange={handleTitle}
                                placeholder='Select'
                            >
                                <option hidden value="" ></option>
                                <option value="father" >Father</option>
                                <option value="mother" >Mother</option>
                                <option value="husband" >Husband</option>
                                <option value="wife" >Wife</option>
                                <option value="son" >Son</option>
                                <option value="daughter" >Daughter</option>
                                <option value="brother" >Brother</option>
                                <option value="sister" >Sister</option>
                                <option value="friend" >Friend</option>
                                <option value="cousin" >Cousin</option>
                                <option value="nephew" >Nephew</option>
                                <option value="uncle" >Uncle</option>
                                <option value="aunt" >Aunt</option>
                                <option value="other" >Other</option>
                            </select>
                        </div>
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='col-6'><label className='text-style-here ' >*Address</label><br />
                        <textarea ref={el => inputRefs.current[6] = el} onKeyDown={(e) => handleKeyDown(e,6)} name="retriever_address" value={deathInfo.retriever_address} maxLength="300"
                            onChange={handleTitle} style={{ height: "117px" }} className='enquiryinputtext' /></div>
                </div>
                <div className='row'>


                </div>
                <br />
                <div className='row'>
                    <div className='col-6'>
                        <h6 className='enquiryheader ' >Witness 1</h6>
                        <div className='row'>
                            <div className='col-6'><label className='text-style-here ' >Name</label><br />
                                <input ref={el => inputRefs.current[7] = el} onKeyDown={(e) => handleKeyDown(e,7)} name="witness1_name" value={deathInfo.witness1_name}
                                    onChange={handleTitle} className='enquiryinput' /></div>
                            <div className='col-6'><label className='text-style-here ' >Contact number</label><br />
                                <PhoneInput
                                    inputProps={{
                                        ref: el => inputRefs.current[8] = el, // Assuming inputRefs is a useRef initialized as useRef([])
                                        tabIndex: 0,
                                        onKeyDown: e => handleKeyDown(e, 8),
                                      }}
                                    disabled={!deathInfo.witness1_name}
                                    className='enquiryinput'
                                    style={{ width: "100%", border: "none" }}
                                    country={'in'}
                                    onlyCountries={['in']}
                                    countryCodeEditable={false}
                                    value={deathInfo.witness1_number!== "" ?deathInfo.witness1_number:"91"}
                                    isValid={validatePhoneInput}
                                    onChange={(value) => {
                                        value.length === 12 ? setDeathInfo(currentstate => ({
                                            ...currentstate,
                                            witness1_number: value
                                        })):
                                        setDeathInfo(currentstate => ({
                                            ...currentstate,
                                            witness1_number: ""
                                        }))
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <h6 className='enquiryheader ' >Witness 2</h6>
                        <div className='row'>
                            <div className='col-6'><label className='text-style-here ' >Name</label><br />
                                <input ref={el => inputRefs.current[10] = el} onKeyDown={(e) => handleKeyDown(e,10)} name="witness2_name" value={deathInfo.witness2_name}
                                    onChange={handleTitle} className='enquiryinput' /></div>
                            <div className='col-6'><label className='text-style-here ' >Contact number</label><br />
                                <PhoneInput
                                    inputProps={{
                                        ref: el => inputRefs.current[11] = el, // Assuming inputRefs is a useRef initialized as useRef([])
                                        tabIndex: 0,
                                        onKeyDown: e => handleKeyDown(e, 11),
                                      }}
                                    disabled={!deathInfo.witness2_name}
                                    className='enquiryinput'
                                    style={{ width: "100%", border: "none" }}
                                    country={'in'}
                                    onlyCountries={['in']}
                                    countryCodeEditable={false}
                                    value={deathInfo.witness2_number!== "" ?deathInfo.witness2_number:"91"}
                                    isValid={validatePhoneInput}
                                    onChange={(value) => {
                                        value.length === 12 ?
                                            setDeathInfo(currentstate => ({
                                                ...currentstate,
                                                witness2_number: value
                                            })):
                                            setDeathInfo(currentstate => ({
                                                ...currentstate,
                                                witness2_number: ""
                                            }))
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'><label className='text-style-here ' >Address</label><br />
                        <textarea ref={el => inputRefs.current[9] = el} 
                            onKeyDown={(e) => handleKeyDown(e,9)}
                            maxLength="200" disabled={!deathInfo.witness1_name} name="witness1_address" value={deathInfo.witness1_address}
                            onChange={handleTitle} className='enquiryinputtext' type="text" /></div>
                    <div className='col-6'><label className='text-style-here ' >Address</label><br />
                        <textarea ref={el => inputRefs.current[12] = el} 
                            onKeyDown={(e) => handleKeyDown(e,12)} 
                            maxLength="200" disabled={!deathInfo.witness2_name} name="witness2_address" value={deathInfo.witness2_address}
                            onChange={handleTitle} className='enquiryinputtext' type="text" /></div>
                </div>
                <div className='row'>
                    <div className='col-6' style={{ padding: "2%" }}>
                        <label style={{ backgroundColor: "white", border: "1px dashed #104173", height: "40px", width: "98%", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center", gap: "8px", padding: "0 12px", cursor: "pointer" }}>
                            <input type="file" style={{ display: "none" }}
                                onChange={handleFile}
                            />
                            {!deathInfo.consent ? <i className="fa fa-cloud-upload" aria-hidden="true"></i> : <i style={{ color: "blue" }} className="fa fa-file-image-o" aria-hidden="true"></i>}
                            {fileName}
                        </label>

                    </div>

                    <div className='col-6' style={{ padding: "2%" }}>
                        <button ref={submitButtonRef} style={{ backgroundColor: "#9069A5", color: "white", height: "40px", border: "none", width: "98%", borderRadius: "10px" }} onClick={() => { props.patient.id ? update() : submit() }}>Submit</button>
                    </div>


                </div>
            </div>
        </div>
    )
}
export default DeathReportForm;