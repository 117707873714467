import React, { useState, useEffect, } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import "./pagination.css";
import './userlist.css'
import axios from "axios";
import { useAlert } from "react-alert";
import { APIURL } from '../../Global'
import { Modal } from 'react-bootstrap';
import AddDoc from './UserListAddDoc';
import ZoomableImage from '../BDO/Admission/ZoomableImage';
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

function UserListDr() {
  const alert = useAlert()
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10);
  const [hasNext, setHasNext] = useState(true)
  const [page, setPage] = useState(0)
  const [load, setLoad] = useState(false)
  const [display, setDisplay] = useState(false)
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [addShow, setAddShow] = useState(false);
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState("")
  const [reports, setReports] = useState([])
  const [perPage1] = useState(10);
  const [hasNext1, setHasNext1] = useState(true)
  const [page1, setPage1] = useState(0)
  const [load1, setLoad1] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [searching,setSearching]=useState(false)
  const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
  const handleLoad = async (page, per_page) => {
    console.log("handleload")
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
    axios.get(`${APIURL}/api/v1/doctor/patient-record-search/?per_page=${perPage + 1}&page=${page}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        /* this to be changed once API is fixed */

        if (res.data.status === "success") {
          let Data = []

          setHasNext(res.data.has_next)
          setPage(res.data.page)

          Data = res.data.patients
            .filter(function (o1) {

              return !patientList.some(function (o2) {
                return o1.id === o2.id;
              });
            })

          setPatientList([...patientList, ...Data]);

        } else {
          // setErrorMsg("error");
        }


      })
      .catch(err => {

        // setErrorMsg("error");
      })

  };
  useEffect(() => {
    if(searching){
      const tokenString = sessionStorage.getItem('usertoken');
      let str = tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
       let url = `${APIURL}/api/v1/doctor/patient-record-search/?q=${searchTerm}`
        axios
            .get(url, {
              headers: {

                "Authorization": 'Token ' + str
              }
            })
            .then((res) => {
                if (res.data.status === "success") {
                 
                  res.data.patients.length>0&&res.data.patients.map((item,index)=>{
                    setPatientList([{
                      id:item.id,
                      name:item.patient_name,
                                      
                    }])
                  })

                  setPatientList(res.data.patients)
                   
                }
                else {

                }
            }).catch((error) => {

            })
          }setSearching(false)
}, [searchTerm])


  const deleteItem = (id1) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    axios.put(`${APIURL}/api/v1/doctor/patient-documents-view/?id=${id1}&patient_id=${id} `, { is_deleted: true }, {
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {

          alert.success("Deleted successfully")
          reports.splice(0, reports.length + 1)
          setRefresh(!refresh)

        } else {


        }

      })
      .catch(err => {


      })

  }
 
  useEffect(() => {

    if (hasNext1) {
      GetReport(page1 + 1, perPage1)
    }

  }, [load1])
  useEffect(() => {
    GetReport(1, 10)

  }, [id, refresh])
  useEffect(() => {

    if (hasNext) {
      console.log(hasNext)
      handleLoad(page + 1, perPage)
    }

  }, [load])
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
          <ZoomableImage src={info} />
        </Modal.Body>
      </Modal>
    );
  };
  const GetReport = async (page, per_page) => {
if(id!==""){
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(
        `${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${id}&per_page=10&page=${page}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let Data = []

          setHasNext1(res.data.has_next)
          setPage1(res.data.page)

          Data = res.data.results
            .filter(function (o1) {

              return !reports.some(function (o2) {
                return o1.id === o2.id;
              });
            })

          setReports([...reports, ...Data]);
          setLoad1(!load1)
        } else {
          // setErrorMsg("error");
        }
      });
    }

  };

  const onSuccess = () => {

    setAddShow(false)
    GetReport(1, 10)

  };
  const AddPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >

        <Modal.Body style={{ width: "95%", minHeight: "450px", paddingRight: "5%" }}>
          <h6 style={{ marginLeft: "2%", textTransform: "capitalize" }}><b>{name}</b>
            <button style={{ backgroundColor: "white", float: "right" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times fa-lg" style={{ color: "black" }} aria-hidden="true"></i>
            </button></h6>
          <AddDoc id={id} onSuccess={onSuccess} onClose={props.onHide} />


        </Modal.Body>

      </Modal>
    )
  }


  const handleSearchChange = (e) => {
    if(e.target.value!==''){
    setSearching(true)
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
    }else{
    setSearching(false)
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
    handleLoad(1,10)
    }
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber > currentPage) {
      setLoad(!load)
    }

    setCurrentPage(pageNumber);
  };
  let data = JSON.parse(JSON.stringify(patientList))
   
  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current1 = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;




  return (<>



    <div className="search-patient-page directory-page container">

      <div className='row'>
        <div className="col-7" >
          <h4 className=" title-of-tasks"> Patient Records Directory </h4><br />
          <div className="search-section search-div">

            <form style={{ display: "flex" }}>

              <input
                className="form-control search-input"
                type="text"
                placeholder="Enter Name"
                value={searchTerm}
                maxLength="150"
                onChange={handleSearchChange}
              />

              <button className="btn btn-primary btn-col search-btn">

                <i className="fas fa-search"></i>
              </button>
            </form>     </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-7" >

          <div className="">
            {
              current1.length > 0 ?
                current1.map((value, index) => {
                  // 

                  return (

<div className='list-item-new' style={{ backgroundColor: display && id === value.id && "rgba(222, 214, 138, 1)" }}>
                      <div className='row'>
                        <div key={index} className=' col-4' style={{ padding: "1%" }}>
                          <h6 style={{ paddingLeft: "1.5%", fontSize:"medium"}} className="each-list-link1" >&nbsp;{(currentPage - 1) * 10 + index + 1}.{value.name}</h6>
                        </div>
                        <div className="col-2" style={{ padding: "1%" }}>
                          <p className='each-list-link2' style={{fontSize:"medium"}}><i class="fa fa-address-card fa-lg" aria-hidden="true"  style={{fontSize:"1rem"}}></i>&nbsp; {value.id} </p>
                        </div>
                        <div className="col-3" style={{display:"flex",alignItems: "center",alignItems: "center", padding: "1%" }}>
                          <p className='each-list-link2' style={{fontSize:"medium"}}> <i class="fa fa-phone fa-lg" aria-hidden="true"style={{fontSize:"1rem"}}></i>&nbsp; {value.mobile_number} </p>
                        </div>
                        <div className="col-3 row dir-button" >
                          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Profile</Tooltip>}>
                            <button onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                              state: { },
                            })} className="col-4 dir-button" style={{ backgroundColor: "rgba(114, 156, 207, 1)", color: "white", height: "100%" }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path fill="white" d="M13.5 0h-12C.675 0 0 .675 0 1.5v13c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-13c0-.825-.675-1.5-1.5-1.5zM13 14H2V2h11v12zM4 9h7v1H4zm0 2h7v1H4zm1-6.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 5 4.5zM7.5 6h-2C4.675 6 4 6.45 4 7v1h5V7c0-.55-.675-1-1.5-1z" /></svg></button></OverlayTrigger>
<span className='col-2'></span>
                          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Documents</Tooltip>}>
                            <button disabled={value.id === id} onClick={() => { setId(value.id); setName(value.name); reports.splice(0, reports.length + 1); setDisplay(true); }} className="col-4 " style={{ backgroundColor: "rgba(114, 156, 207, 1)", color: "white", height: "100%" }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M5 8c0-2.828 0-4.243.879-5.121C6.757 2 8.172 2 11 2h2c2.828 0 4.243 0 5.121.879C19 3.757 19 5.172 19 8v8c0 2.828 0 4.243-.879 5.121C17.243 22 15.828 22 13 22h-2c-2.828 0-4.243 0-5.121-.879C5 20.243 5 18.828 5 16V8Zm0-3.924c-.975.096-1.631.313-2.121.803C2 5.757 2 7.172 2 10v4c0 2.828 0 4.243.879 5.121c.49.49 1.146.707 2.121.803M19 4.076c.975.096 1.631.313 2.121.803C22 5.757 22 7.172 22 10v4c0 2.828 0 4.243-.879 5.121c-.49.49-1.146.707-2.121.803" /><path stroke-linecap="round" d="M9 13h6M9 9h6m-6 8h3" /></g></svg>
                            </button></OverlayTrigger>
                        </div>
                      </div>


                    </div>


                  )
                }) : <h6 style={{ color: "red" }}>No Patients Found</h6>
            }

          </div>


          <br />
          <br />
          {totalPages > 0 ?
            <div className="pagn" >

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={total}
                pageRangeDisplayed={totalPages}
                onChange={handlePageChange}
                disabledClass="disabled-class"
              />
            </div>
            : null}
        </div>
        <div className="col-5" style={{ padding: "1%" }}>
          <div className='doc-right'>
            <div style={{ minHeight: "400px", paddingTop: "1%" }}>
              <h4 className=" title-of-tasks">{name} </h4><br />
              {id === "" ? <div className='text-center'><svg style={{ marginTop: "120px" }} xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 24 24"><path fill="rgba(158, 158, 158, 1)" d="M21.26 21.21L3.79 3.74L2.38 5.15l2.74 2.74l-.12.12V19c0 1.1.9 2 2 2h10c.35 0 .68-.1.97-.26l1.88 1.88l1.41-1.41zM7 19V9.77L16.23 19H7zm3.84-14H17v9.11l2 2V5c0-1.1-.9-2-2-2h-6.99L7.95 5.06l1.41 1.41L10.84 5z" /></svg>

                <h4 style={{ color: "rgba(158, 158, 158, 1)", marginTop: "2%" }}>Select a profile</h4></div> :
                <SimpleBar style={{ maxHeight: "380px", paddingRight: "2%", paddingLeft: "2%" }}>
                  <div >  {reports.length > 0 ? reports.map((item, index) => {
                    return (
                      <div className='row' style={{ marginBottom: "2%", marginLeft: "5%" }}>
                        <div className='col-9 row listofrep ' style={{ padding: "1%" }}>
                          <div className='col-6 text-center' style={{ borderRight: "1.5px solid rgba(0, 0, 0, 1)" }}>
                            <h6 style={{ textTransform: "capitalize" }}><b>{item.doc_type.replace("_", " ")}</b></h6>
                            <span className="">{item.uploaded_at.slice(0, 10)}</span>
                          </div>
                          <div style={{ backgroundColor: "rgba(240, 240, 240, 1)" }} className='col-6'>
                            <h6 style={{ color: item.comment ? "black" : "red" }}>{item.comment ? item.comment : "No Remarks!!"}</h6>
                          </div>
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div className='col-2  ' style={{ padding: "1%", display: "flex", height: "45px" }}>
                          <button onClick={() => { setInfo(item.images); setShow(true) }} style={{ backgroundColor: "rgba(36, 86, 147, 1)", color: "white", width: "55px", borderRadius: "10px" }} className='dir-button '>View</button>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          <i onClick={() => {login_datas.user_type !== "hospital_nurse"&& deleteItem(item.id) }} style={{ color:login_datas.user_type !== "hospital_nurse"? "rgba(229, 89, 11, 1)":"gray",cursor:login_datas.user_type !== "hospital_nurse"?"pointer":"not-allowed" }} class="fa fa-trash fa-2x" aria-hidden="true"></i>

                        </div>



                      </div>

                    )
                  }) : <div className='title-of-tasks' style={{ color: "lightgrey", marginTop: "25%" }}><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="m9.5 5.5l-4 4m5-9h-8a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-10l-3-3Zm-3 10a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z" /></svg>
                    <br /><span>--No Data--</span>
                  </div>}
                  </div>
                </SimpleBar>}
            </div>
            {id !== "" && <div className='text-center'>
              <button disabled={login_datas.user_type === "hospital_nurse"} onClick={() => { setAddShow(true) }} style={{ width: "50%", backgroundColor: "rgba(9, 188, 255, 1)", height: "40px" }} className='cons-button'>
                <svg style={{ paddingBottom: "1%" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="white"><path fill-rule="evenodd" d="M12 15.75a.75.75 0 0 0 .75-.75V4.027l1.68 1.961a.75.75 0 1 0 1.14-.976l-3-3.5a.75.75 0 0 0-1.14 0l-3 3.5a.75.75 0 1 0 1.14.976l1.68-1.96V15c0 .414.336.75.75.75Z" clip-rule="evenodd" /><path d="M16 9c-.702 0-1.053 0-1.306.169a1 1 0 0 0-.275.275c-.169.253-.169.604-.169 1.306V15a2.25 2.25 0 1 1-4.5 0v-4.25c0-.702 0-1.053-.169-1.306a1 1 0 0 0-.275-.275C9.053 9 8.702 9 8 9c-2.828 0-4.243 0-5.121.879C2 10.757 2 12.17 2 14.999v1c0 2.83 0 4.243.879 5.122C3.757 22 5.172 22 8 22h8c2.828 0 4.243 0 5.121-.879C22 20.242 22 18.828 22 16v-1c0-2.829 0-4.243-.879-5.121C20.243 9 18.828 9 16 9Z" /></g></svg>&nbsp;&nbsp;<span style={{ paddingTop: "1%" }}>Upload Document</span></button>

            </div>}
          </div>
        </div>
      </div>

    </div >
    {
      addShow ?
        <AddPopUp
          show={addShow}
          onHide={() => setAddShow(false)}

        /> : null
    }
    {show ? (
      <InfoPopup
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />
    ) : (
      ""
    )}

  </>
  )
}


export default UserListDr;