import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Keyboard from 'react-simple-keyboard';
import { Button } from 'react-bootstrap';
import 'react-simple-keyboard/build/css/index.css';
import "./enquiry.css"
function AdmissionForm() {
    return(
        <div  style={{height:"650px", padding:"20%"}} className="text-warning">
      <h4><b> Page is under construction</b></h4>
</div>
    )
}
export default AdmissionForm
