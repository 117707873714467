import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./featurelist1.css";
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
// import Pagination from "react-js-pagination";
// import "./pagination.css";
import AddMedicine from "./Addmedicine";
import EditMedicine from "./EditMedicine";
import Pagination from "react-js-pagination";
import LoadingOverlay from "react-loading-overlay";

function Medicine() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  const [featureList1, setFeatureList1] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [medicine, setMedicine] = useState([]);
  const [activePage, setActivePage] = useState(page);
  const [perPage] = useState(10);
  const [editShow, setEditShow] = useState(false);
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(true);
  const [display2, setDisplay2] = useState(false);
  const [current, setCurrent] = useState();
  const [refresh, setRefresh] = useState(false);
  const [totalPages, setTotalPages] = useState(1); // Initialize to 1 as a default
  const [search, setSearch] = useState("name_suggest__completion");
  const [addRentalShow, setAddRentalShow] = useState(false);
  const [visiblePages, setVisiblePages] = useState([]);
  const [pagesToShow, setPagesToShow] = useState(4);
  const [category, setCategory] = useState(2);
  const [expand, setExpand] = useState();

  const [flag, setFlag] = useState(false);
  const [successShow, setSuccessShow] = useState(false);

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   console.log(featureList1); // This will log the updated featureList1 when it changes
  // }, [featureList1]);
  useEffect(() => {
    // Generate visible page numbers based on the current page and total pages
    let startPage = Math.max(1, page - Math.floor(pagesToShow / 2));
    let endPage = startPage + pagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const newVisiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    setVisiblePages(newVisiblePages);
  }, [page, totalPages, pagesToShow]);



  useEffect(() => {
    

     

      axios
        .get(
          `https://uniapi.cianlogic.com/api/v1/doctor/medicine/suggest/?${search}=${searchTerm}`,
          {
            headers: {  },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setMedicine(res.data[search][0].options);

           setDisplay(true);
         
          } else {
          }
        })
        .catch((error) => { });
    
  }, [searchTerm]);

  useEffect(() => {
     bindMedicine(page);
  }, [refresh]);

  const handleMedicineList =()=>{
    setDisplay1(true)
  }

  const bindMedicine = (page) => {
    
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/staff/master-medicines/?is_approved=True&page=${page}  `, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.data.status === "success") {
          setLoading(false);
          const datas = res.data.data;
          console.log(datas);
          setFeatureList(datas);
          setTotalPages(res.data.total_pages);
          //console.log(featureList)
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const rentalModalClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setFlag(true);
  };

  const notProceedClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setEditShow(false);
  };

  const modalClose = () => {
    setEditShow(false);
    setRefresh(!refresh);
  };

  const handleClose = () => {
    bindMedicine();
    setAddRentalShow(false);
    setEditShow(false);
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
          

          {/* <Button
            variant="danger"
            onClick={() => {
              handleDelete(deleteIndex);
            }}
          >
            Confirm
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopUp = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success">medicine Deleted Successfully!!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddRentalPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddMedicine
            modalClose={rentalModalClose}
            notProceedClose={notProceedClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditMedicine data={current} modalClose={modalClose} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleEdit = (item) => {
    // const list = featureList[index];
    setCurrent(item);
    setEditShow(true);
  };

  const handleDeletePopUp = (id) => {
    //  e.preventDefault();
    setDeleteIndex(id);
    setDeleteShow(true);
  };



  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    bindMedicine(pageNumber)
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setActivePage(page+1)
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setActivePage(page-1)
    }
  };
  
  const handleAddProcedure = (e,item) => {
    debugger
  
    setFeatureList1(item)
    console.log(featureList1)
    setSearchTerm("")
    setDisplay(false)
    setDisplay1(false)
    setDisplay2(true)
  }

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };
  const handleSearchChange = (e) => {
    
    e.preventDefault();
    setSearchTerm(e.target.value);

    if (e.target.value === '') {
      setDisplay(false);
    }
    // document.body.addEventListener("click", bodyClickHandler);
  };

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  

 
  // const totalPages = pageNumbers.length;
  let dataDisplay = [];

  dataDisplay =
    category === 2 ? (
      featureList.length > 0 ? (
        featureList.map((item, index) => {
          return (
            <div
              className="care-features"
              key={item.id}
              style={{ cursor: "pointer" }}
              onClick={() => handleDetail(index)}
            >
              <div className="feature-data">
                <h5 className="feature-title">
                  <b>
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDetail(index)}
                    >
                      {(page - 1) * perPage + index + 1}.{item.med_name}<span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span><span style={{fontSize:"20px", color: "green"}}>(Strength:{item.strength})</span>
                    </span>
                    <i
                      className={
                        expand === index
                          ? "fa fa-angle-up fa-fw"
                          : "fa fa-angle-down fa-fw"
                      }
                      style={{ color: "#6788bc " }}
                    ></i>
                  </b>

         
                </h5>
              </div>

              {expand === index ? (
                <>
                  <div className="seperate-content" />
                  <div className="row-of-features">
                    <div className="col-of-features">
                    { item.image ?  <img src={item.image} alt="image" width="100" height="100" />: null}
                       <h6>Created Name : {item.created_name}</h6> 
                      <h6> Unified Code: {item.med_1}</h6>
                      <h6>Composition: {item.composition}</h6>
                      <h6>Strength: {item.strength}</h6>
                      <h6>Strength Unit: {item.strength_unit}</h6>
                      <h6>Dosage Form: {item.dosage_form}</h6>
                      <h6>Route of Medicine : {item.route}</h6>
                     
                      <h6>Description : {item.description}</h6>
                      <h6>Drug Interactions : {item.med_interactions!=="null"&&item.med_interactions}</h6>
                      <h6 style={{textTransform:"none"}}>Drug Interactions URL :<a href={item.med_url}> {item.med_url!=="null"&&item.med_url}</a></h6>
                      <h6>Manufacturer Name : {item.manufacturer_name}</h6>
                      <h6>created at : {item.created_at}</h6>

                      <h6>Updated at : {item.modified_at}</h6> 
                    </div>
                  </div>
                  {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}
                </>
              ) : null}
            </div>
          );
        })
      ) :  (
        <div
          style={{
            color: "red",
            fontSize: "25px",
            height: "200px",
            padding: "40px",
          }}
        >
          {!loading ? <h4>No Medicine to show!!</h4> : ""}
        </div>
      )
    ) : null;

    let dataDisplay1 = null;

if (category === 2) {
  if (Object.keys(featureList1).length > 0) {
    dataDisplay1 = (
      <div className="care-features">
        <div className="feature-data"  onClick={() => handleDetail(0)}>
          <h5 className="feature-title" >
            <b>
              <span style={{ cursor: "pointer" }} onClick={() => handleDetail(0)}>
                {featureList1.med_name}{" "}
                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
                <span style={{ fontSize: "20px", color: "green" }}>
                  (Strength: {featureList1.strength})
                </span>
              </span>
              <i
                className="fa fa-angle-down fa-fw"
                style={{ color: "#6788bc" }}
              ></i>
            </b>
          </h5>
        </div>
        {expand === 0 ? (
          <>
            <div className="separate-content" />
            <div className="row-of-features">
              <div className="col-of-features">
                {featureList1.image ? (
                  <img src={featureList1.image} alt="image" width="100" height="100" />
                ) : null}
                
                <h6>Unified Code: {featureList1.med_1}</h6>
                <h6>Composition: {featureList1.composition}</h6>
                <h6>Strength: {featureList1.strength}</h6>
                <h6>Strength Unit: {featureList1.strength_unit}</h6>
                <h6>Dosage Form: {featureList1.dosage_form}</h6>
                <h6>Route of Medicine : {featureList1.route}</h6>
                <h6>Description : {featureList1.description}</h6>
                <h6>
                  Drug Interactions :{" "}
                  {featureList1.med_interactions !== "null" &&
                    featureList1.med_interactions}
                </h6>
                <h6 style={{ textTransform: "none" }}>
                  Drug Interactions URL :<a href={featureList1.med_url}> {featureList1.med_url !== "null" && featureList1.med_url}</a>
                </h6>
                <h6>Manufacturer Name : {featureList1.manufacturer_name}</h6>
            
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  } else {
    dataDisplay1 = (
      <div
        style={{
          color: "red",
          fontSize: "25px",
          height: "200px",
          padding: "40px",
        }}
      >
        {!loading ? <h4>No Medicine to show!!</h4> : ""}
      </div>
    );
  }
}

// Remember to replace category, featureList1, expand, and handleDetail with your actual variables and functions.

  const handleBack = (e) => {
    navigate(-1);
  };

  return (
    <>
      {/* <BackofficeNavBar/> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div className="container displaylist-container ">
          {/* <button style={{padding:"5%",marginTop:"5%"}}
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button> */}
          <div className="header-display">
            <h2 className="title-of-page"> Medicine Details</h2>

            <div className="search-section">
              <div className="search-div">
                <div style={{ display: "flex",marginLeft: "10%",width:"100%" }}>
                  <input
                    className="form-control search-input"
                    type="text"
                    placeholder="Search Here"
                    value={searchTerm}
                    maxLength="30"
                    onChange={handleSearchChange}
                  />
                  <button className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </button>
                  <div className="buttons-wrapper">
                
                      <button
                        style={{ marginLeft: "60%" }}
                        className="btn btn-primary btn-col"
                        onClick={() => handleMedicineList()}
                      >
                        Medicine List 
                      </button>
               
                  </div>
                </div>
              </div>
            </div>

            {display ? (
              <div className="suggest-display adjust-dimensions procedure1-auto1-suggest1" style={{marginLeft:"34%",marginTop:"35px",}}>
                {medicine.length > 0 ? (
                  medicine.map((item) => {
                    return (
                      <p
                        onClick={(e) => handleAddProcedure(e, item._source)}
                        className=" text-secondary"
                        style={{padding:"2%"}}
                      >

                        {search === "name_suggest__completion" ?
                          <> <div className="row"> <span className="col-6">{item._source.med_name}</span><span className="col-6">{item._source.strength}</span> </div>  </>:
                          // search === "generic_name" ?
                          // <div className="row"><span className="col-6">{item._source.med_name+","+item._source.strength}</span> <span className="col-5"> {item._source.generic_name}</span> <i style={{ float: "right" }} class={item._source.is_approved === false ? "fa fa-window-close" : "fa fa-check-square"} aria-hidden="true"></i></div> :
                            <div className="row"> <span className="col-4">{item._source.med_name}</span><span className="col-3">{item._source.strength}</span><span className="col-5">{item._source.composition}</span> </div>}
                      </p>
                    );
                  })
                ) : (
                  <h6 className="text-muted" style={{ padding: 10 }}>
                    No Medicines found !!
                  </h6>
                )}
              </div>
            ) : null}
          </div>

          <div className="test-reports-display-list">
            <div className="feature-container">{ display1 ? dataDisplay :display2 ? dataDisplay1 :dataDisplay}</div>
          </div>
         
        {display1 ?   <div className="pagn pagn-small" style={{textAlign:"center"}}>
              
              <button  onClick={handlePreviousPage} disabled={page === 1}>
              <i class="fa fa-angle-double-left"  aria-hidden="true"></i>
</button>
{visiblePages.map((pageNumber) => (
  <button
    key={pageNumber}
    onClick={() => {
      handlePageChange(pageNumber);
      setActivePage(pageNumber); // Update the active page
    }}
    className={pageNumber === activePage ? 'active-page' : ''}
  >
    {pageNumber}
  </button>
))}
<button onClick={handleNextPage} disabled={page === totalPages}>
<i class="fa fa-angle-double-right" aria-hidden="true"></i>
</button>
              {/* <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={total}
                pageRangeDisplayed={totalPages}
                onChange={handlePageChange}
                
              /> */}
            </div>
         :""}

          {editShow ? (
            <EditPopUp show={editShow} onHide={() => setEditShow(false)} />
          ) : null}

          {deleteShow ? (
            <DeleteConfirmPopup
              show={deleteShow}
              onHide={() => {
                setDeleteShow(false);
                setDeleteIndex("");
              }}
            />
          ) : (
            ""
          )}

          {successShow ? (
            <SubmitPopUp
              show={successShow}
              onHide={() => {
                setSuccessShow(false);
                setRefresh(!refresh);
              }}
            />
          ) : null}

          {addRentalShow ? (
            <AddRentalPopUp
              show={addRentalShow}
              onHide={() => setAddRentalShow(false)}
            />
          ) : null}
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Medicine;
