import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Doctor from "../doctor/Doctor";

import "./doctorslist.css";
import axios from 'axios';
import { APIURL } from "../../Global";
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import LoadingOverlay from 'react-loading-overlay';
import BackButton from '../common/BackButton';


function DoctorsList() {
  const [loading,setLoading]=useState(false);
  const [doctorsList, setDoctorsList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState()
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false)

  const navigate = useNavigate();

 

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


 

  
  useEffect(() => {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");


    axios
      .get(`${APIURL}/api/v1/service-provider/doctors/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Token " + v2
        },
      })
      .then((res) => {
   
        if (res.data.status === "success") {
          const datas = res.data.doctors;
          setLoading(false)
          setFreeCount(res.data.free_user_count)
          setActiveCount(res.data.active_free_users)
          setDoctorsList(datas);
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }, [refresh]);

  const refreshHandle = () => {
    setRefresh(!refresh)
  }

  return (<>
  

    <LoadingOverlay
    active={loading}
    spinner
    styles={{
      spinner: (base) => ({
        ...base,
        width: '50px',
        '& svg circle': {
          stroke: 'rgba(0, 0, 255, 0.5)'
          
        }
      }),
      overlay: {},
    }}
   
>
  <div className="drlist-page">

<br/>
 

    <h2 className="title-of-page"> Doctors List </h2>
    <div className="quick-links">
      <Link to="/adddoctor"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add Doctor to List </button></Link>
      {/* <Link to={{ pathname: '/license-details', flag: "dr-page" }}><button type="button" style={{ marginLeft: "10px" }} className="btn btn-info dr-btn"> View Licence Details</button></Link> */}

    </div>

    {doctorsList.length !== 0 ? (<div>
      <div className="drs-container">
        {doctorsList.map((data, index) => {
          return <Doctor key={index} data={data} freeCount={freeCount} activeCount={activeCount} handle={refreshHandle} />
        })}
      </div>

      <div className="quick-links">
        <Link to="/adddoctor"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add Doctor to List </button></Link>

      </div>

    </div>)
      :
      <h5 style={{ color: "red", margin: "20px" }}>Doctors List is empty</h5>
    }

    {errorMsg === "error" ?
      <h5 style={{ color: "red" }}>

        Error in data fetching.Please try again!
      </h5>
      :
      null}

  </div>
</LoadingOverlay></>
  );
}

export default DoctorsList;