import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function EditAller(props) {
  const alert = useAlert();
    const {data, id} =props
    const [obsData, setObsData]= useState(data.allergy)   
    const [allerduration, setAllerDuration] = useState(data.duration);
    const saveButtonRef = useRef(null)
    const inputRefs = useRef([])
    const handleKeyDown = (e, index) => {
      if (e.key === "Enter") {  
        e.preventDefault()
        if(index === 1 ) {
          saveButtonRef.current.click();
        }else if(index === 0){
          e.preventDefault();    
          const nextIndex = index + 1;
          inputRefs.current[nextIndex].focus();        
        }
      }
    };
    useEffect(() => {
      
      const handleKey = (e) => {
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click() 
        }     
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);

    
    useEffect(() => {
      inputRefs.current[0].focus();  
      inputRefs.current[0].selectionStart = inputRefs.current[0].selectionEnd = inputRefs.current[0].value.length;
  }, []);
    
    const handleSave = ()=> {

   
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "allergy": obsData,
              // "observation_id": data.id,
               "patient_id": id,
               "duration":allerduration
           }
     
     ;
       
         axios.put(`${APIURL}/api/v1/doctor/allergies-details/${data.id}/`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
               alert.success("Allergy updated  Successfully ")
               props.handleObs();
          
       }
       else{
       
          alert.error("already exist");
        
        
        
       }
     
         })
         .catch(err=>{
       
       alert.error("error in submission")
     })

    }


   
   

    return (
        <div className='flex-col'>        

                <textarea 
                ref={el => inputRefs.current[0] = el}
                onKeyDown={(e) => handleKeyDown(e,0)}
                type="text" 
                className="form-group text-area remark-add"
                value= {obsData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setObsData(e.target.value)
          } }
            autoComplete="off"
                />

<div>
        <span className="align-rental">Duration : </span>{" "}
        <input
          ref={el => inputRefs.current[1] = el}
          onKeyDown={(e) => handleKeyDown(e,1)}
          type="number"
          className="form-control title-flex"
          name="duration"
          value={allerduration}
          onChange={(e) => {
            e.preventDefault();
            setAllerDuration(e.target.value);
          }}
        ></input>
        <br />
        <br />{" "}
     
      </div>
            <button 
            ref={saveButtonRef}
            // disabled={obsData=== data.allergy} 
            onClick={handleSave} className='btn btn-primary btn-col'>Save</button>





           

        </div>



    )
}

export default EditAller
