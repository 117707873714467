import React, { useState, useEffect, useRef } from "react";



import "../BDO/dob.css";

import { useAlert } from "react-alert";
import { preventDefault } from "@fullcalendar/react";

function Review(props) {
  const alert = useAlert();
  
  const [doImmediatly, setDoImmediatly] = useState(false)
  const submitButtonRef = useRef(null)
  const handleChange = (e) => {
    setDoImmediatly(!doImmediatly)
  }

  useEffect(() => {
    let timeoutId;
  
    // Delay the attachment of the event listener
    timeoutId = setTimeout(() => {
      const handleKey = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          submitButtonRef.current.click();
        } else if (e.altKey && e.key === "c") {
          props.needUpdate();
        } else if (e.key === "Escape") {
          props.needUpdate();
        }
      };
  
      document.addEventListener("keydown", handleKey);
      return () => {
        document.removeEventListener("keydown", handleKey);
      };
    }, 1000);
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  
  return (
    <div className="add-dr-page">


      <div className="">


        {props.MedData.map((item) => {
          return (<>
            <div style={{ marginBottom: "1%" }} className="row">
              &nbsp; &nbsp; &nbsp;
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-5"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>{item.medicine_name}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{item.frequency}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>Start Today</div></div>

            </div>

          </>
          )
        })}
        <br />

        <div>
          <input type="checkbox" checked={doImmediatly} onChange={handleChange} />
          &nbsp;
          <span style={{ fontSize: "14px", marginTop: "1%" }} className="text-style-here">Apply immediately</span>
        </div><br />

        <div className="form-button">
          <button onClick={() => { props.needUpdate() }} className="review-edit">Edit</button>
          &nbsp;
          <button ref={submitButtonRef} onClick={() => { props.onSuccess(doImmediatly) }} className="review-button">Sign and Submit</button>

        </div>


      </div>
    </div>
  );
}

export default Review;
