import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Redirect } from "react-router";
//import "./pagination.css";
//import './userlist.css'
import "./admission.css";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../../Global";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Link as Scroll } from "react-scroll";
import AddComments from "../AddComments";
import ZoomableImage from "../../BDO/Admission/ZoomableImage";
//import HospDocNavbar from '../BackoffcieNav/BackofficeNavBar';

function AdmissionPatient() {
  const [admissionView, setAdmissionView] = useState(false);
  const [admissionList, setAdmissionList] = useState([])
  const [remarkData, setRemarkData]= useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rejectView, setRejectView] = useState(false);
  const [approveView, setApproveView] = useState(false);
  const [recommenderView, setRecommenderView] = useState(false);
  const [refererView1, setRefererView1] = useState(false);
  const [requestView, setRequestView] = useState(true);
  const [rejectList, setRejectList] = useState([]);
  const [approvedList, setapprovedList] = useState([]);
  const [qualityView, setQualityView] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [relativeView, setRelativeView] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [approveCommentShow, setApproveCommentShow] = useState(false);
  const [rejectaddComments, setRejectaddComments] = useState(false);
  const [enquiryRejectComment, setEnquiryRejectComment] = useState(false);
  const [enquiryApproveShow, setEnquiryApproveshow] = useState(false);
  const [enquiryApprovalComment, setEnquiryApprovalComment] = useState(false);
  const [addShow2, setAddShow2] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [rejectEnquiryShow, setRejectEnquiryShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [refresh1, setRefresh1] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [refresh3, setRefresh3] = useState(false);
  const [refresh4, setRefresh4] = useState(false);
  const [view, setView] = useState(false);
  const [view1, setView1] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);
  const [itemList, setItemList] = useState({});
  const [user, setUser] = useState("");
  const [user1, setUser1] = useState([]);
  const [itemId, setItemId] = useState("");
  const [status, setStatus] = useState("");
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [itemId1, setItemId1] = useState("");
  const [viewData1, setViewData1] = useState([]);
  const [weaknessView, setWeaknessView] = useState(false);
  const [consentView, setConsentView] = useState(false);
  const [detailsId, setDetailsId] = useState("");
 const [searchStarted,setSearchStarted]=useState(false)
const [viewType,setViewType]=useState("")

 
  const alert = useAlert();
  let navigate = useNavigate();


  const handleButtonClick=()=> {
    if(viewType==="primary1"){
      setView(true);
    }else{
      
      setView1(true);
    }
    setRelativeView(false);
    setQualityView(false);
    setWeaknessView(false);
    setRecommenderView(false);
    setRefererView1(false);
    setConsentView(false)
    setViewType("")
  }

  const handleButtonClick1=()=> {
    setView(true)
      setRefererView1(false);
      
     }

  const handleClickReject = (e) => {
    e.preventDefault();
  
    setRequestView(false);
    setRejectView(true);
    setApproveView(false);
    setAdmissionView(false)
    if(searchQuery===""){
    setRefresh2(!refresh2)
    }
  };
  const handleClick1 = (e) => {
    e.preventDefault();
  
    setRequestView(true);
    setApproveView(false);
    setRejectView(false);
    setAdmissionView(false)
    if(searchQuery===""){
    setRefresh1(!refresh1)
    }
  };
  const handleClickApproved = (e) => {
    e.preventDefault();
  
    setRequestView(false);
    setRejectView(false);
    setApproveView(true);
    setAdmissionView(false)
    if(searchQuery===""){
    setRefresh3(!refresh3)
    }
  };
  const handleClickAdmission = (e) => {
    e.preventDefault();
  
    setRequestView(false);
    setRejectView(false);
    setApproveView(false);
setAdmissionView(true)
if(searchQuery===""){
    setRefresh4(!refresh4)
}
  };
  const handleQualityView =(e)=>{
    e.preventDefault();
    setQualityView(true)
    setView1(false)
    
  }

  const handleWeaknessView =(e)=>{
    e.preventDefault();
    setWeaknessView(true)
   setView1(false)
    
  }
  const handleRelativeView =(e)=>{
    e.preventDefault();
    setRelativeView(true)
    setView1(false)
    
  }

 

const handleApproveClick = (x) => {

   

if(x.pe_status==="pending") {
setItemList(x)
setApproveShow(true);
setItemId(x.pv_enquiry_number)
 
}
else if(x.enquiry_status==="pending"){
  setItemList(x)
  setEnquiryApproveshow(true);
  setItemId1(x.pv_enquiry_number)
}

else if(x.pe_status==="approved"&&x.enquiry_status===null){
  alert.error("He/She is waiting for Enquiry Request submission")
  setItemId("")
  setItemId1("")
  setEnquiryStatus("")
  setStatus("")
}
//   else if(itemId===x.id  && status==="approved"&&enquiryStatus===""&&x.enquiry_status==="pending"){
//   alert.error("He/She is waiting for Enquiry Request approval")
//   setItemId("")
//   setItemId1("")
//   setEnquiryStatus("")
//   setStatus("")
// } 
else if(x.enquiry_status==="approved"){
  alert.error( "He/She is waiting for admission")
  setItemId("")
  setItemId1("")
  setEnquiryStatus("")
  setStatus("")
}
// else if(x.pe_status==="approved"&&x.enquiry_status==="pending"){
//   alert.error("He/She is waiting for Enquiry Request Approval")
//   setItemId("")
//   setItemId1("")
//   setEnquiryStatus("")
//   setStatus("")
// }
// else if(x.enquiry_status==="approved"){
//   alert.error("He/She is waiting for admission")
//   setItemId("")
//   setItemId1("")
//   setEnquiryStatus("")
//   setStatus("")
// }
// else if((itemId1==="" && itemId==="")){
//   alert.error("Select the request for Approval")
//   setItemId("")
//   setItemId1("")
//   setEnquiryStatus("")
//   setStatus("")
// }

  }


  const formatFirstName = (name) => {
    return name&&name!==""&&name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };
  
  const rejectCase = async (item) => {
    
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    let currentPage = 1;
    let totalPages = 1;
    let rejectList = [];
  
    while (currentPage <= totalPages) {
      try {
        const response = await axios.get(`${APIURL}/api/v1/service-provider/enquiry-status/?status=rejected&page=${currentPage}`, {
          headers: {
            Authorization: "Token " + str,
          },
        });
  
        if (response.status === 200) {
          const pageData = response.data.message.filter((item) => (
            item.pe_status === "rejected" || item.enquiry_status === "rejected" || item.chairman_status==="rejected"
          ));
  
          rejectList = rejectList.concat(pageData);
  
          // Check if there are more pages
          if (response.data.has_next) {
            currentPage++;
            totalPages++;
          } else {
            break; // Exit the loop if there are no more pages
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        break; // Exit the loop on error
      }
    }
  
    // Now, approvedList contains data from all pages
    setRejectList(rejectList);
  };
  const approveCase = async (item) => {
    
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    let currentPage = 1;
    let totalPages = 1;
    let AppData = [];
  
    while (currentPage <= totalPages) {
      try {
        const response = await axios.get(`${APIURL}/api/v1/service-provider/user-admission/?page=${currentPage}&per_page=10`, {
          headers: {
            Authorization: "Token " + str,
          },
        });
  
        if (response.data.status === "success") {
          const pageData = response.data.results.filter((item) => (
            item.user!== null &&item.user!==""&&item.admission_status==="approved"
          ));
  
          AppData = AppData.concat(pageData);
  
          // Check if there are more pages
          if (response.data.has_next) {
            currentPage++;
            totalPages++;
          } else {
            break; // Exit the loop if there are no more pages
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        break; // Exit the loop on error
      }
    }
  
    // Now, approvedList contains data from all pages
    console.log(AppData,"approved")
    setapprovedList(AppData);
  };
  useEffect(() => {


    admissionPending()
  }, [refresh4]);
  const admissionPending = async (item) => {
    
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    let currentPage = 1;
    let totalPages = 1;
    let AppData = [];

    while (currentPage <= totalPages) {
      try {
        const response = await axios.get(`${APIURL}/api/v1/service-provider/user-admission/?page=${currentPage}&per_page=10`, {
          headers: {
            Authorization: "Token " + str,
          },
        });

        if (response.data.status === "success") {
          const pageData = response.data.results.filter((item) => (
            item.admission_status === "pending"
          ));

          AppData = AppData.concat(pageData);

          // Check if there are more pages
          if (response.data.has_next) {
            currentPage++;
            totalPages++;
          } else {
            break; // Exit the loop if there are no more pages
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        break; // Exit the loop on error
      }
    }

    // Now, approvedList contains data from all pages
    console.log(AppData, "approved")
    setAdmissionList(AppData);
  };
  const handleContinue=(data)=>{


    const tokenStr = sessionStorage.getItem("usertoken");

    if (tokenStr) {
      const v = tokenStr;
      let v2 = v.replace(/["]+/g, "");

      let dataToSend = {}; // Define the object here

      if (itemId) {
        dataToSend = {
        pv_enquiry_number: itemId,
          pe_status: "approved",
          manager_recommend:data

        };
      } else if (itemId1) {
        dataToSend = {
          pv_enquiry_number: itemId1, // Corrected this from itemId to itemId1
          enquiry_status: "approved",
          mgr_recommend_enquiry:data
        };
      }
      axios
        .put(`${APIURL}/api/v1/service-provider/enquiry-status/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setItemId("")
            setItemId1("")
            setRefresh1(!refresh1);
            setApproveCommentShow(false)
            setEnquiryApprovalComment(false)
            setRemarkData('')
            alert.success(res.data.message);
          
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => {});
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(searchStarted){

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?search=${searchQuery}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          let data =res.data
          const filteredData = data.filter(x => {
            return x.pe_status === "rejected" || x.enquiry_status==="rejected" ||x.chairman_status==="rejected"
        })
        const filteredData1 = data.filter(x => {
          return x.pe_status === "pending" || (x.enquiry_status==="pending"&&x.pe_status !== "rejected") ||(x.chairman_status==="pending"&&x.enquiry_status!=="rejected"&&x.pe_status !== "rejected")||(x.chairman_status===null&&x.enquiry_status!=="rejected"&&x.pe_status !== "rejected")
      })
      const filteredData2 = data.filter(x => {
        return x.user!==null&&x.admission_status==="approved"
    })
    const filteredData3 = data.filter(x => {
      return x.user!==null&&x.admission_status==="pending"
  })
        setRejectList(filteredData)
        setPendingList(filteredData1)
        setapprovedList(filteredData2)
        setAdmissionList(filteredData3)
          
            if(pendingList.length>0){
              setRejectView(false)
            setRequestView(true)
            setApproveView(false)
            setAdmissionView(false)
           
            }
            else if(rejectList.length>0){
              setRejectView(true)
              setRequestView(false)
              setApproveView(false)
              setAdmissionView(false)
            }
            else if(admissionList.length>0){
              setRejectView(false)
              setRequestView(false)
              setApproveView(false)
              setAdmissionView(true)
            }
            else{
              setRejectView(false)
              setRequestView(false)
              setApproveView(true)
              setAdmissionView(false)
            }
         
          
          }
      });
    }
    else{
      setRefresh1(!refresh1);
      setRejectView(false)
      setRequestView(true)
      setApproveView(false)
      setAdmissionView(false)
    }

  },[searchQuery]
  )


  
 

  const ApprovePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{color:"green"}}>Do you want to approve primary enquiry form of {formatFirstName(itemList.first_name)}? </h4>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"  onClick={() => {setApproveShow(false);setItemId("");setItemId1("")}}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setApproveShow(false);
              setApproveCommentShow(true);
              //handleContinue();
              
            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const RecommenderPopUp = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            style={{ minHeight: "450px" }}
        >
  <Modal.Header closeButton >
  <h4 style={{marginLeft:"32%"}}>Recommender Details</h4>
    </Modal.Header>
    <Modal.Body style={{ backgroundColor: "#E4DD9F",textAlign:"center" }}>
    <div>
    {enquiryData[0].govt_member && enquiryData[0].govt_member!==null && enquiryData[0].govt_member !== "[]"&& enquiryData[0].govt_member!==undefined   ? (
  <div>
    {JSON.parse(enquiryData[0].govt_member).map((relative, index) => (
      <div key={index}>
         <strong>Recommender :</strong> <span style={{ textTransform: "uppercase" }}>{relative.referer}</span><br />
        <strong>Recommender Name:</strong> {relative.name && formatFirstName(relative.name)}<br />
      
        <strong>Recommender Phone:</strong> {relative.phone!=='91' ?relative.phone:"" }<br />
  
       
        **********************************************
      </div>
    ))}
  </div>
  ) : (
  <div>No recommender data available</div>
  )}
  
    </div>
  </Modal.Body>
  
  <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
  
        </Modal>
    )
  }
  
  const EnquiryApprovePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{color:"green"}}>Do you want to approve enquiry form of {formatFirstName(itemList.first_name)}? </h4>
          <h6 id="confirm-reject" style={{color:"green"}}>Approval will be moved to trust confirmation  </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {setEnquiryApproveshow(false);setItemId("");setItemId1("")}}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setEnquiryApproveshow(false);
              setEnquiryApprovalComment(true)
              //handleContinue();
              
            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const RejectPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{color:"red"}}>Do you want to reject the primary enquiry form of {formatFirstName(itemList.first_name)}?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setRejectShow(false);
              setRejectaddComments(true)
             // handleRejectContinue();
              
            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RejectEnquiryPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{color:"red"}}>Do you want to reject the enquiry form of {formatFirstName(itemList.first_name)}?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setRejectEnquiryShow(false);
              setEnquiryRejectComment(true);
             // handleRejectContinue();
              
            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
 
 

  const handleRejectClick =(x)=>{

    if(x.pe_status==="pending") {
      setItemList(x)
      setRejectShow(true);
      setItemId(x.pv_enquiry_number)
      
      }

      else if(x.enquiry_status==="pending"){
        setItemList(x)
        setRejectEnquiryShow(true);
        setItemId1(x.pv_enquiry_number)
      }
     
      else if( x.pe_status==="approved"&&x.enquiry_status===null){
        alert.error("He/She is waiting for Enquiry Request submission")
        setItemId("")
        setItemId1("")
        setEnquiryStatus("")
        setStatus("")
      }  
      else if(x.enquiry_status==="approved"){
        alert.error( "He/She is waiting for admission")
        setItemId("")
        setItemId1("")
        setEnquiryStatus("")
        setStatus("")
      }
      // else if(x.pe_status==="approved"&&x.enquiry_status==="pending"){
      //   alert.error("He/She is waiting for Enquiry Request Approval")
      //   setItemId("")
      //   setItemId1("")
      //   setEnquiryStatus("")
      //   setStatus("")
      // }
      // else if(x.enquiry_status==="approved"){
      //   alert.error("He/She is waiting for admission")
      //   setItemId("")
      //   setItemId1("")
      //   setEnquiryStatus("")
      //   setStatus("")
      // }
      // else if((itemId1==="" && itemId==="")){
      //   alert.error("Select the request for rejection")
      //   setItemId("")
      //   setItemId1("")
      //   setEnquiryStatus("")
      //   setStatus("")
      // }
    
   
  }
  const QualityPopUp = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            style={{ minHeight: "450px" }}
        >
  <Modal.Header >
    <h4 style={{marginLeft:'40%'}}>Qualities</h4>
    </Modal.Header>
    <Modal.Body style={{backgroundColor: "#E4DD9F"}} >
    {enquiryData[0].qualities    ? (
    <div >
    
      {enquiryData[0].qualities.split(',').map((detail, index) => (
        <div key={index}> {index+1}. {formatFirstName(detail.trim())}</div>
      ))}
   
    </div>):( <div>No qualities available</div>)}
  </Modal.Body>
  
  <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
        </Modal>
    )
  }
  const RelativePopUp = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            style={{ minHeight: "450px" }}
        >
  <Modal.Header  >
  <h4 style={{marginLeft:"32%"}}>Relatives Details</h4>
    </Modal.Header>
    <Modal.Body style={{ backgroundColor: "#E4DD9F",textAlign:"center" }}>
    <div>
    {enquiryData[0].relatives && enquiryData[0].relatives!==null && enquiryData[0].relatives !== "[]"&& enquiryData[0].relatives!==undefined   ? (
  <div>
    {JSON.parse(enquiryData[0].relatives).map((relative, index) => (
      <div key={index}>
        <strong>Name:</strong> {relative.name && formatFirstName(relative.name)}<br />
        <strong>Age:</strong> {relative.age}<br />
        <strong>Relation:</strong> {relative.relation && formatFirstName(relative.relation)}<br />
        <strong>Job:</strong> {relative.job && formatFirstName(relative.job)}<br />
        <strong>Phone:</strong> {relative.phone!=="91" ? relative.phone :""}<br />
        **********************************************
      </div>
    ))}
  </div>
) : (
  <div>No relatives data available</div>
)}

    </div>
  </Modal.Body>
  <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
  
  
        </Modal>
    )
  }


  const handleRejectContinue = (data) => {


    const tokenStr = sessionStorage.getItem("usertoken");

    if (tokenStr) {
      const v = tokenStr;
      let v2 = v.replace(/["]+/g, "");

      let dataToSend = {}; // Define the object here

      if (itemId) {
        dataToSend = {
          pv_enquiry_number: itemId,
          pe_status: "rejected",
          manager_recommend:data
        };
      } else if (itemId1) {
        dataToSend = {
          pv_enquiry_number: itemId1, // Corrected this from itemId to itemId1
          enquiry_status: "rejected",
          mgr_recommend_enquiry:data
        };
      }
      axios
        .put(`${APIURL}/api/v1/service-provider/enquiry-status/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setItemId("")
            setItemId1("")
            alert.success(res.data.message);
            setRejectaddComments(false);
            setEnquiryRejectComment(false);
            setRemarkData('')
            setRefresh1(!refresh1);
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  const handleEnvelopClick = (id,status) => {
   
    // setItemId(id);
    // setItemId1("");
    setStatus(status)
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setViewData(res.data);
          setView(true);
        } else {
          alert.error(res.data.message);
        }
      });


  };

  const handleEnquiryClick = (id,status) => {

   
    // setItemId1(id);
    // setItemId("");
    setEnquiryStatus(status)

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEnquiryData(res.data);
          setView1(true);
       
        } else {
          alert.error(res.data.message);
        }
      });

  };

  useEffect(() => {
    const fetchData = async () => {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");
      let currentPage = 1;
      let totalPages = 1;
      let pendingData = [];
     
  
      while (currentPage <= totalPages) {
        try {
          const response = await axios.get(`${APIURL}/api/v1/service-provider/enquiry-status/?page=${currentPage}`, {
            headers: {
              Authorization: "Token " + str,
            },
          });
  
          if (response.status === 200) {
            const pageData = response.data.message.filter((item) => (
              item.user === null &&
              item.enquiry_status !== "rejected" &&
              item.pe_status !== "rejected"
            ));
          
            pendingData = pendingData.concat(pageData);
            
  
            // Check if there are more pages
            if (response.data.has_next) {
              currentPage++;
              totalPages++;
            } else {
              break; // Exit the loop if there are no more pages
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          break; // Exit the loop on error
        }
      }
  
      setPendingList(pendingData);
      
    };
  
    fetchData();
  }, [refresh1]);

  useEffect(() => {
  
  
    rejectCase(page)
   }, [page,refresh2]);
  
   useEffect(() => {
  
  
    approveCase(page)
   }, [page,refresh3]);





  const onSuccess = (data) => {
    


   setRemarkData(data)
   setApproveCommentShow (false)
   setEnquiryApprovalComment(false)
   handleContinue(data)
  }

  const onReject = (data) => {

 
   setRemarkData(data)
   setRejectaddComments(false);
   setEnquiryRejectComment(false);
   handleRejectContinue(data)
  }

  const ApproveCommentPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
       
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">   
        <div>
        
          <AddComments value={remarkData}  onSuccess={onSuccess} />
</div>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button variant="primary" onClick={(e)=>handleContinue(e)} >Add</Button>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>


        </Modal.Footer> */}
      </Modal>
    );
  }

  const EnquiryApprovalPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
       
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">   
        <div>
        
          <AddComments value={remarkData} onSuccess={onSuccess} />
</div>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button variant="primary" onClick={(e)=>handleContinue(e)} >Add</Button>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>


        </Modal.Footer> */}
      </Modal>
    );
  }

  const WeaknessPopUp = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            style={{ minHeight: "450px" }}
        >
  <Modal.Header >
    <h4 style={{marginLeft:'40%'}}>Weakness</h4>
    </Modal.Header>
    <Modal.Body style={{backgroundColor: "#E4DD9F"}} >
    {enquiryData[0].weakness    ? (
    <div >
    
      {enquiryData[0].weakness.split(',').map((detail, index) => (
        <div key={index}> {index+1}. {formatFirstName(detail.trim())}</div>
      ))}
   
    </div>):( <div>No weakness available</div>)}
  </Modal.Body>
  
  <Modal.Footer>
            <Button variant="primary" onClick={() => handleButtonClick()}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }

  const RejectCommentPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
       
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">   
        <div>
        
          <AddComments value={remarkData} onSuccess={onReject} />
</div>
        </Modal.Body>
        <Modal.Footer>
        {/* <Button variant="primary" onClick={(e)=>handleRejectContinue(e)} >Add</Button>
          <Button variant="secondary" onClick={props.onHide}>Close</Button> */}


        </Modal.Footer>
      </Modal>
    );
  }

  const EnquiryRejectPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
       
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">   
        <div>
        
          <AddComments value={remarkData} onSuccess={onReject} />
</div>
        </Modal.Body>
        <Modal.Footer>
        {/* <Button variant="primary" onClick={(e)=>handleRejectContinue(e)} >Add</Button>
          <Button variant="secondary" onClick={props.onHide}>Close</Button> */}


        </Modal.Footer>
      </Modal>
    );
  }

  const handleRefererView1 =(e)=>{
    e.preventDefault();
    setRefererView1(true)
    //setView1(false)
    setView(false)
    
  }

  const handleRecommenderView =(e)=>{
    e.preventDefault();
    setRecommenderView(true)
    setView1(false)
    
  }


  const ViewPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "green", textAlign: "center" }}>
            <b>Primary Enquiry Details</b>
          </h4>
          <Card
            className="mb-2"
            bg={"light"}
            border="light"
            style={{  height: "auto", top: "-10%" }}
          >
            <hr />

            <Card.Body>
           
              <ListGroup
                style={{ textAlign:"left" }}
                className="list-group-flush"
              > <ListGroupItem>
              <b>Enquiry Number </b>
              <span style={{ float: "right" }}>
              {viewData[0].pv_enquiry_number} 
              </span>
            </ListGroupItem>
            <ListGroupItem>
                  <b>PE Date </b>
                  <span style={{ float: "right" }}>
                  {viewData[0].pe_date!==null&&(viewData[0].pe_date).slice(0,10)} 
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Name </b>
                  <span style={{ float: "right" }}>
                    {formatFirstName(viewData[0].first_name)} {viewData[0].last_name && formatFirstName(viewData[0].last_name)}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Gender </b>{" "}
                  <span style={{ float: "right" }}> {viewData[0].gender&&formatFirstName(viewData[0].gender)}</span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Date of Birth </b>
                  <span style={{ float: "right" }}> {viewData[0].dob}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Mobile Number </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].mobile_number}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Location</b>
                  <span style={{ float: "right" }}>{viewData[0].location && formatFirstName(viewData[0].location)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Province </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].province&& formatFirstName(viewData[0].province)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>City </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].city&&formatFirstName(viewData[0].city)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Region </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].state &&viewData[0].state !=="null"? formatFirstName(viewData[0].state):""}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Country </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].country}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Pincode </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].pincode}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Email </b>
                  <span style={{ float: "right" }}>{viewData[0].email}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].idcard_type}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card Number </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].idcard_no}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                <b>Manager Recommendation </b>
                <span style={{ float: "right" }}>
                  {viewData[0].manager_recommend && formatFirstName(viewData[0].manager_recommend)}
                </span>
              </ListGroupItem>
                <ListGroupItem>
                <b>Referrer </b>
                <span style={{ float: "right" }}>
                <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>handleRefererView1(e)}> View</button>
                                  </span>
              </ListGroupItem>
              {viewData[0].pe_report!==null&&<ListGroupItem>
  <b>Primary Form</b>
  <span style={{ float: "right" }}>
  <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>{handleConsentView(e);setViewType("primary1");setView(false)}}> View</button>
  </span>
</ListGroupItem>}
              </ListGroup>

              {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
            </Card.Body>
          </Card>
        </Modal.Body>
     
      </Modal>
    );
  };
  const RefererPopUp1 = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            style={{ minHeight: "450px" }}
        >
  <Modal.Header  >
  <h4 style={{marginLeft:"32%"}}>Referrer Details</h4>
    </Modal.Header>
    <Modal.Body style={{ backgroundColor: "#E4DD9F",textAlign:"center" }}>
    <div>
    {viewData[0].referer && viewData[0].referer!==null && viewData[0].referer!==undefined   ? (
  <div>
    {viewData[0].referer.map((relative, index) => (
        relative.name&&relative.name !== "null"&&
      <div key={index}>
        <strong>Referrer Name:</strong> {relative.name!=="null" ?   formatFirstName(relative.name) :""}<br />
      
        <strong>Referrer Phone:</strong> {relative.contact !=="91" ? relative.contact :"" }<br />
  
        <strong>Referrer Comment:</strong> {relative.comments && formatFirstName(relative.comments)}<br />
       
        **********************************************
      </div>
    ))}
  </div>
  ) : (
  <div>No referrer data available</div>
  
  )}
  <div>{viewData[0].referer &&
            viewData[0].referer !== null &&
            viewData[0].referer !== undefined &&((viewData[0].referer.length===1 &&!viewData[0].referer[0].name)||
            (viewData[0].referer.length===1 &&viewData[0].referer[0].name==="null")||(viewData[0].referer.length===2&&!viewData[0].referer[0].name&&!viewData[0].referer[1].name)||(viewData[0].referer.length===2&&viewData[0].referer[0].name==="null"&&viewData[0].referer[1].name==="null"))? <div>No referrer data available</div>:""}</div> 
            </div>
  </Modal.Body>
  
  <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick1()}>
            Ok
          </Button>
        </Modal.Footer>
  
        </Modal>
    )
  }
  const handleConsentView =()=>
  {
    setConsentView(true)
    setView1(false)
  }
  
  const ConsentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
       
      >
        <Modal.Header></Modal.Header>

        <Modal.Body
          style={{ padding: "7%" }}
        >
          <div style={{textAlign:"center"}}>
          <ZoomableImage src={viewType==="consent"?enquiryData[0].consent_form:viewType==="primary"?enquiryData[0].pe_report:viewType==="primary1"?viewData[0].pe_report:enquiryData[0].enquiry_report} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleZoomOut}>Zoom Out</Button>
        <Button variant="secondary" onClick={handleZoomIn}>Zoom In</Button> */}
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  
  

  const EnquiryFormPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "green", textAlign: "center" }}>
            <b> Enquiry Details</b>
          </h4>
          <Card
            className="mb-2"
            bg={"light"}
            border="light"
            style={{ height: "auto", top: "-10%" }}
          >
            <hr />

            <Card.Body>
              <ListGroup
                style={{ textAlign:"left" }}
                className="list-group-flush"
              >
                 <ListGroupItem>
                  <b>Enquiry Date </b>
                  <span style={{ float: "right" }}>
                  {enquiryData[0].enquiry_date!==null&&(enquiryData[0].enquiry_date).slice(0,10)} 
                  </span>
                </ListGroupItem>
                <ListGroupItem>
               
                  <b>Name </b>
                  <span style={{ float: "right" }}>
                    {formatFirstName(enquiryData[0].first_name)} {enquiryData[0].last_name && formatFirstName(enquiryData[0].last_name)}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Gender </b>{" "}
                  <span style={{ float: "right" }}> {formatFirstName(enquiryData[0].gender)}</span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Date of Birth </b>
                  <span style={{ float: "right" }}> {enquiryData[0].dob}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Mobile Number </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].mobile_number}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Location</b>
                  <span style={{ float: "right" }}>{enquiryData[0].location&&formatFirstName(enquiryData[0].location)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Province </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].province&&formatFirstName(enquiryData[0].province)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>City </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].city&&formatFirstName(enquiryData[0].city)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Region </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].state}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Country </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].country}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Pincode </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].pincode}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Email </b>
                  <span style={{ float: "right" }}>{enquiryData[0].email}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].idcard_type}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card Number </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].idcard_no}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Manager Recommendation </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].mgr_recommend_enquiry && formatFirstName(enquiryData[0].mgr_recommend_enquiry)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Trust Decision </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].chairman_comment}</span>
                </ListGroupItem>
              
                <ListGroupItem>
                  <b>Field Findings </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].field_report && formatFirstName(enquiryData[0].field_report)}
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
                  <b>Manager's Recommendation </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].manager_recommend}
                  </span>
                </ListGroupItem> */}

                <ListGroupItem>
                  <b>Primary Enquiry Status </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].pe_status&&formatFirstName(enquiryData[0].pe_status)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Parent Name </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].parent_name&&formatFirstName(enquiryData[0].parent_name)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Marital Status</b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].marital_status===true ? "Yes" :enquiryData[0].marital_status===false ? "No":""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Is Parent Alive </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].is_aliveparent===true ? "Yes" :enquiryData[0].is_aliveparent===false ? "No" :""}
                  </span>
                </ListGroupItem>
                
                <ListGroupItem>
                  <b>Post Care Details </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].post_care 
                    ? formatFirstName(enquiryData[0].post_care) 
                    : "No"}
                  </span>
                </ListGroupItem>
                
                <ListGroupItem>
                  <b>Own Home </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].is_home===true ? "Yes" : enquiryData[0].is_home===false ? "No":""}
                  </span>
                  </ListGroupItem>
                  <ListGroupItem>
                  <b>Own Property </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_property 
                    ? formatFirstName(enquiryData[0].own_property)
                    : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Land </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_land 
                    ? formatFirstName(enquiryData[0].own_land)
                    : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Ration Card </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_rationcard===true ? "Yes" : enquiryData[0].own_rationcard===false ? "No" :""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Income </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_income 
                    ? formatFirstName(enquiryData[0].own_income) 
                    : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Bystander Name </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].bystander_name&&formatFirstName(enquiryData[0].bystander_name)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Bystander Contact </b>
                  <span style={{ float: "right" }}>
                  {enquiryData[0].bystander_contact!=="undefined" ? enquiryData[0].bystander_contact :"" }
                  </span>
                </ListGroupItem>
                <ListGroupItem>
  <b>Qualities </b>
  <span style={{ float: "right" }}>
    {/* {enquiryData[0].qualities} */}
    <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>handleQualityView(e)}> View</button>
  </span>
</ListGroupItem>
<ListGroupItem>
  <b>Weakness </b>
  <span style={{ float: "right" }}>
    {/* {enquiryData[0].qualities} */}
    <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>handleWeaknessView(e)}> View</button>
  </span>
</ListGroupItem>
<ListGroupItem>
  <b>Relatives</b>
  <span style={{ float: "right" }}>
  <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>handleRelativeView(e)}> View</button>
  </span>
</ListGroupItem>
<ListGroupItem>
  <b>Recommendations</b>
  <span style={{ float: "right" }}>
  <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>handleRecommenderView(e)}> View</button>
  </span>
</ListGroupItem>

                <ListGroupItem>
                  <b>Physical Support </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].physical_support===true ? "Yes" : enquiryData[0].physical_support===false ? "No" :""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Police Case Any </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].police_case===true ? "Yes" :enquiryData[0].police_case===false ? "No":""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Undergoing Treatment </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].undergoing_treatment && formatFirstName(enquiryData[0].undergoing_treatment)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Treatment Place </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].treatment_place && formatFirstName(enquiryData[0].treatment_place)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Care Details </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].care_details && formatFirstName(enquiryData[0].care_details)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Auto Biography </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].autobiography && formatFirstName(enquiryData[0].autobiography)}
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
                  <b>Investigator </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].investigator}
                  </span>
                </ListGroupItem> */}
                <ListGroupItem>
  <b>Consent form</b>
  <span style={{ float: "right" }}>
  <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>{handleConsentView(e);setViewType("consent")}}> View</button>
  </span>
</ListGroupItem>

{enquiryData[0].enquiry_report!==null&&<ListGroupItem>
  <b>Enquiry Form</b>
  <span style={{ float: "right" }}>
  <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>{handleConsentView(e);setViewType("enquiry")}}> View</button>
  </span>
</ListGroupItem>}
              </ListGroup>

              {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
            </Card.Body>
          </Card>
        </Modal.Body>
        
      </Modal>
    );
  };

  return (
    <>
      <div className="facilityouter container" style={{marginTop:"1%", width:"100%"}}>
        <div className="row">
          <div className="col-lg-2 col-md-2">
            <h4
              style={{
                color: "#245693",
                margin: "1.5em",
                fontFamily: "inter",
                fontWeight: "700",
              }}
            >
              Admissions
            </h4>
            <div className="leftsidewindow" style={{maxWidth:"100%", padding:"10px",height:"300px"}}>
              <div className="searchboxindside" style={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
              <input
  type="text"
  style={{  paddingLeft:"10px", border: '0', outline: 'none', width:"100%"}}
  value={searchQuery}
  onChange={(e) =>{if(e.target.value===""){setRefresh1(!refresh1)}setSearchStarted(true) ; setSearchQuery(e.target.value); if(e.target.value===""){setSearchStarted(false)}}}
  placeholder="Name/PV Id"
/>{" "}&nbsp;&nbsp;
                <i class="fa fa-search" style={{paddingRight:"10px"}} aria-hidden="true"></i>
              </div>
              <h6
                style={{
                  color: "#2D3748",
                  fontFamily: "Helvetica",
                  fontWeight: "700",
                  marginLeft: "1em",
                  marginTop: "5px",
                  fontSize: ".75em",
                }}
              >
              
              </h6>
           
              <button
                className="approverequset"
                onClick={(e) => handleClick1(e)}
                style={{ 
                  backgroundColor: requestView ? "#6D4482" : "#F8F8F8",
                  color: requestView ? "white" : "black",
                  boxSizing:"border-box",
                  fontSize: "0.8em",
                  width:"100%"
                }}
              >
                {" "}
                <i
                  class="fa fa-file"
                  aria-hidden="true"
                  style={{ color: requestView ? "white":"#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Requests
                {searchQuery!==""&&<span style={{float:"right",width:"15%"}}>|&nbsp;{pendingList.length>0?pendingList.length:"NIL"}</span>}
              </button>
              <button 
                className="rejectedrequests"  
                onClick={e=>handleClickReject(e)} 
                style={{
                  backgroundColor: rejectView ?  "#6D4482" : "#F8F8F8",
                  color:rejectView ? "white":"black",
                  fontSize: "0.8em", 
                  width:"100%"

                }}>
                {" "}
                <i
                  class="fa fa-file"
                  aria-hidden="true"
                  style={{  color: rejectView ? "white":"#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Rejected Requests
                {searchQuery!==""&&<span style={{float:"right",width:"15%"}}>|&nbsp;{rejectList.length>0?rejectList.length:"NIL"}</span>}
              </button>
              <button
                className="rejectedrequests"
                onClick={(e) => handleClickAdmission(e)}
                style={{
                  backgroundColor: admissionView ? "#6D4482" : "#F8F8F8",
                  color: admissionView ? "white" : "black",
                  fontSize: "0.8em",
                  width: "100%"

                }}
              >
                {" "}
                <i
                  className="fa fa-file"
                  aria-hidden="true"
                  style={{ color: admissionView ? "white" : "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Admission Pending
                {searchQuery!==""&&<span style={{float:"right",width:"15%"}}>|&nbsp;{admissionList.length>0?admissionList.length:"NIL"}</span>}
              </button>
              
              <button
                className="approverequset"
                onClick={(e) => handleClickApproved(e)}
                style={{ 
                  backgroundColor: approveView ? "#6D4482" : "#F8F8F8",
                  color: approveView ? "white" : "black",
                  fontSize: "0.8em", 
                  width:"100%"

                }}
              >
                {" "}
                <i
                  class="fa fa-file"
                  aria-hidden="true"
                  style={{ color: approveView ? "white":"#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Approved
                {searchQuery!==""&&<span style={{float:"right",width:"15%"}}>|&nbsp;{approvedList.length>0?approvedList.length:"NIL"}</span>}
              </button>
            </div>
            {/* <div className="leftsidewindow">
             
              </div> */}
          </div>
          <div className="col-lg-10 col-md-10">
          <h5
              style={{
                color: "red",
                marginLeft: "10%",
                marginTop: "10px",
                fontFamily: "inter",
                fontSize: "18px",
                fontStyle: "italic",
                fontWeight: "600",
                textAlign: "right",
              }}
            >
            
          {requestView && pendingList.length > 0 && (
  <div>
    {`${pendingList.filter(item => item.enquiry_status === "pending" || item.pe_status === "pending").length} ${
      pendingList.length === 1 ? "request is" : "requests are"
    } pending`}
  </div>
) }

{rejectView && rejectList.length > 0 && (
  <div>
    {`${rejectList.filter(
      item => item.enquiry_status === "rejected" || item.pe_status === "rejected" || item.chairman_status ==='rejected'
    ).length} ${
      rejectList.length === 1 ? "rejected request" : "rejected requests"
    } pending`}
  </div>
)}

{approveView && approvedList.length > 0 && (
  <div>
    {`${approvedList.length} ${
      approvedList.length === 1 ? "Approved request" : "Approved requests"
    } `}
  </div>
)}
  {admissionView && admissionList.length > 0 && (
                <div>
                  {`${admissionList.filter(
                    (item) =>
                      item.admission_status === "pending"

                  ).length
                    } ${admissionList.length === 1 ? "request is" : "requests are"
                    } pending`}
                </div>
              )}
                  {admissionView && admissionList.length === 0 && (
                <div>No admission requests found</div>
              )}
{rejectView && rejectList.length === 0 &&  (
  <div>No rejected requests found</div>
)}



{requestView && pendingList.length === 0 &&  (
  <div>No pending requests </div>
)}

{approveView && approvedList.length === 0 &&  (
  <div>No approved requests </div>
)}

</h5>

          {requestView ? (
         <>           
              <SimpleBar style={{ maxHeight: "1050px" }}>
              {pendingList.length>0&&<div  style={{ display: "flex", marginTop: "3%", width:"62rem", marginLeft:"8rem" }}>
              <h6 className="col-3"></h6>
              <h6 className="col-2"
                style={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#6D4482",
                  // marginLeft: "41%",
                }}
              >
                Primary
              </h6>
              <h6 className="col-2"
                style={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#6D4482",
                  // marginLeft:"18%",
                }}
              >
                Enquiry
              </h6>
              <h6 className="col-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    // marginLeft: "15%",
                  }}
                >
                  Approve
                </h6>
                <h6 className="col-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    // marginLeft: "15%",
                  }}
                >
                  Reject
                </h6>
                </div>}
                {pendingList
    //             .filter((item) => {
    //   // Replace 'fullName' with the property that contains the patient's full name
    //   const fullName = `${item.first_name} ${item.last_name}`;
    //   return fullName.toLowerCase().includes(searchQuery.toLowerCase());
    // })
    .map((item) => (
                  <div onClick={() => setUser(item.pv_enquiry_number)} className="row"  style={{ display: "flex",  width:"62rem",}}>
                    <div
                      className="patientlistview"
                      style={{
                        border:user===item.pv_enquiry_number&&"1px solid #6D4482",
                        display: "flex",
                        marginLeft:"2%",
                        marginBottom: "1%",
                       
                      }}
                    >
                      <div className="col-4">
                        <h6
                          style={{
                            color: "black",
                            fontWeight: "700",
                            fontFamily: "inter",
                            marginTop: "20px",
                            marginLeft: "20px",
                            justifyContent: "center",
                            fontSize: "16px",
                            textTransform: "Capitalize",
                          }}
                        >
                          {/*     <span style={{fontSize:"x-small",color:"#207170"}}><b>{item.pv_enquiry_number}</b></span>-{item.first_name}{item.last_name} {""} */}
                             {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br/><span style={{fontSize:"x-small",color:"#207170"}}><b>{item.pv_enquiry_number}</b></span>

                          
                        </h6>
                      </div>
                     
                      <div className="col-2" style={{ }}>
                        {/* <i class="fa fa-file" aria-hidden="true"></i> */}
                        <div
                          
                          style={{
                            width: "123px",
                            height: "40px",
                            border:"1px solid grey",
                            borderRadius: "5px",
                            marginTop: "20px",
                            textAlign:"center",
                            cursor: "pointer",
                          }}
                        
                        >
                          {item.pe_status === "pending" ? (
                               <div style={{display:"flex"}}>
                               <div  style={{marginTop:"5px",marginLeft:"5px" }}>
                                {itemId!==item.pv_enquiry_number ?
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="
                          #4F87CB" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                             
                             <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                             <path fill="none" d="M0 0h36v36H0z" />
                            <circle
                              cx="13"
                              cy="3"
                              r="3"
                             
                              fill="red"
                              class="clr-i-outline--badged clr-i-outline-path-2--badged clr-i-badge"
                            />
                            <path fill="none" d="M0 0h36v36H0z" />
                          </svg> :<i class="fa fa-envelope-open" aria-hidden="true" style={{fontSize:"25px",color:"#245693"}}></i>}</div> <div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnvelopClick(item.pv_enquiry_number,item.pe_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
                          
                          ) : item.pe_status === "approved" ? (

                            <div style={{display:"flex"}}>
                            <div  style={{marginTop:"5px",marginLeft:"5px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="25"
                              fill="#5ECB5B"
                              class="bi bi-envelope-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                            </svg> </div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnvelopClick(item.pv_enquiry_number,item.pe_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="25"
                              fill="red"
                              class="bi bi-envelope-x-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                            </svg>
                          )}
                        </div>
                      </div>
                      <div className="col-2" style={{ }}>
                        <div
                        
                          style={{
                            flexDirection: "column",
                            width: "123px",
                            height: "40px",
                            border:"1px solid grey",
                            textAlign:"center",
                            borderRadius: "5px",
                            marginTop: "20px",
                            marginLeft: "0px",
                           
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                         
                        >
                          {item.enquiry_status === null ? (

<div style={{display:"flex"}}>
<div  style={{marginTop:"5px",marginLeft:"5px" }}>
                            <i
                              class="fa fa-envelope"
                              aria-hidden="true"
                              style={{
                                fontSize: "23px",
                                fontWeight: "900",
                                color: "rgb(158, 158, 158)",
                                width: "25px",
                                height: "30px",
                                marginLeft: "-10px",
                                marginTop: "0px",
                              }}
                              disabled={
                           
                                item.enquiry_status === null
                              }
                              // onClick={() => navigate("/enquiry")}
                            ></i> </div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"10px",marginTop:"4px"}}   ><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="grey"/><path fill="grey" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
                          ) : item.enquiry_status === "approved" ? (
                            <div style={{display:"flex"}}>
                            <div  style={{marginTop:"5px",marginLeft:"5px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="25"
                              fill="#5ECB5B"
                              class="bi bi-envelope-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                            </svg> </div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}}   onClick={() => handleEnquiryClick(item.pv_enquiry_number,item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
                          ) :item.enquiry_status === "rejected" ? (
                            <div style={{ marginLeft: "-1%" }}>
                             <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="25"
                              fill="red"
                              class="bi bi-envelope-x-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                            </svg>
                            </div>
                          ) :  <div style={{display:"flex"}}>
                          <div  style={{marginTop:"5px",marginLeft:"5px" }}>{itemId1!==item.pv_enquiry_number ? <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="
                          #4F87CB" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                             
                             <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                             <path fill="none" d="M0 0h36v36H0z" />
                            <circle
                              cx="13"
                              cy="3"
                              r="3"
                             
                              fill="red"
                              class="clr-i-outline--badged clr-i-outline-path-2--badged clr-i-badge"
                            />
                            <path fill="none" d="M0 0h36v36H0z" />
                          </svg> :<i class="fa fa-envelope-open" aria-hidden="true" style={{fontSize:"25px",color:"#245693"}}></i>}</div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}}   onClick={() => handleEnquiryClick(item.pv_enquiry_number,item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>}
                        </div>
                      </div>

                  
                      <div className="col-2">
                        {/* Buttons for approval */}
                        {item.pe_status!=='approved' || item.enquiry_status!=="approved" ?   <>  <button
                          style={{
                            width: "100px",
                            height: "40px",
                            borderRadius: "5px",
                            color: "white",
                            backgroundColor: "rgb(97, 198, 95)",
                            marginLeft: "10px",
                            marginTop: "20px",
                            cursor:"pointer"
                          }}
                          onClick={() => handleApproveClick(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="21"
                            viewBox="0 0 22 21"
                            fill="none"
                          >
                            <path
                              d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                              fill="white"
                            />
                          </svg>{" "}
                        </button>
                    </> : item.pe_status==='approved' && item.enquiry_status==="approved" ? <> 
                        
                      
                        <h6 style={{fontSize:"12px",marginTop:"30px",fontFamily:"inter",fontStyle:"italic",marginLeft:"10px"}}>*waiting for trust decision</h6> </> :""} </div>
                      <div className="col-2">
                      {item.pe_status!=='approved' || item.enquiry_status!=="approved" ?   <>  <button
                          onClick={(e) => handleRejectClick(item)}
                          style={{
                            borderRadius: "5px",
                            width: "94px",
                            height: "39px",
                            background: "#C6725F",
                            color: "white",
                            cursor:"pointer",
                            marginTop: "20px",
                            marginLeft: "5px",
                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                              fill="white"
                            />
                          </svg>{" "}
                          &nbsp;{" "}
                        </button>{" "}
                     </>:""} </div>
                    </div>
                  </div>
              ))}
              </SimpleBar>
             
          </>
     ) : 
     
     
       rejectView ? 
        <>                        
             <SimpleBar style={{ maxHeight: "1050px"}}>
             {rejectList.length>0&& <div style={{ display: "flex", marginTop: "3%", width:"62rem" }}>
               <h6 className="col-4" style={{ marginLeft:"2%" }}></h6>
               <h6 className="col-3"
                 style={{
                   fontFamily: "inter",
                   fontSize: "16px",
                   fontWeight: "700",
                   color: "#6D4482",
                   textAlign:"center",
                   marginLeft:"-1%"
                 }}
               >
                 Primary
               </h6>
               <h6 className="col-3"
                 style={{
                   fontFamily: "inter",
                   fontSize: "16px",
                   fontWeight: "700",
                   color: "#6D4482",
                   textAlign:"center",
                   marginLeft:"-1%"
                 }}
               >
                 Enquiry
               </h6>           
             
            </div>}             
             {rejectList
      //        .filter((item) => {
      //   // Replace 'fullName' with the property that contains the patient's full name
      //   const fullName = `${item.first_name} ${item.last_name}`;
      //   return fullName.toLowerCase().includes(searchQuery.toLowerCase());
      // })
      .map((item) => ( <div>
       
      <div className="row" onClick={() => setUser(item.pv_enquiry_number)} style={{ display: "flex",width:"62rem" }}>
     <div className="patientlistview" style={{  border:user===item.pv_enquiry_number&&"1px solid #6D4482",display: "flex", marginLeft:"2%", marginBottom:'1%'  }}>
      
         <div className="col-4">
           <h6
             style={{
               color: "black",
               fontWeight: "700",
               fontFamily: "inter",
               marginTop: "20px",
               marginLeft: "20px",
               justifyContent: "center",
               textTransform: "capitalize",
               fontSize:"16px"
             }}
           >
             {/*     <span style={{fontSize:"x-small",color:"#207170"}}><b>{item.pv_enquiry_number}</b></span>-{item.first_name}{" "}{item.last_name} */}
                 {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br/><span style={{fontSize:"x-small",color:"#207170"}}><b>{item.pv_enquiry_number}</b></span>
             
           </h6>
         </div>
      
         <div className="col-3" style={{ }}>
        
           <div
            
             style={{
               width: "123px",
               height: "40px",
               border:"1px solid grey",
               borderRadius: "5px",
               cursor: "pointer",
               margin:"auto", 
               marginTop: "18px", 
              alignItems: "center"                         
             }}
        
           >
             {item.pe_status === "pending" ? (
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="22"
                 height="21"
                 viewBox="0 0 22 21"
                 fill="none"
               >
                 <path
                   d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                   fill="grey"
                 />
               </svg>
             ) : item.pe_status === "approved" ?  ( <div style={{display:"flex"}} >  <div style={{marginTop:"4%"}} >
               <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="#5ECB5B" class="bi bi-envelope-check-fill" viewBox="0 0 16 16">
               <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
               <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
             </svg> </div> <div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnvelopClick(item.pv_enquiry_number)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
             ) : (
               <div style={{display:"flex"}} >  <div style={{marginTop:"4%"}}> <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="red" class="bi bi-envelope-x-fill" viewBox="0 0 16 16">
               <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
               <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z"/>
             </svg></div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnvelopClick(item.pv_enquiry_number)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
             )}
           </div>
         </div>
         <div className="col-3">
           <div 
             style={{
               flexDirection: "column",
               width: "123px",
               height: "40px",
               border:"1px solid grey",
               margin: "auto",  
               borderRadius: "5px",
               marginTop: "18px",
               textAlign: "center",
               display: "flex",
               alignItems: "center",
             }}
            
           >
             {item.enquiry_status===null ?   <i
               class="fa fa-plus-circle"
               aria-hidden="true"
               style={{
                 fontSize: "23px",
                 fontWeight: "900",
                 color: "#852ea0",
                 width: "44px",
                 height: "20px",
                 marginLeft: "1px",
                 marginTop: "10px",
                 cursor: item.enquiry_status ===null ? "not-allowed" : "pointer", // Change cursor style
               }}
              
               onClick={() => {  if (item.enquiry_status !== null) navigate('/enquiry', { state: { itemId: item.pv_enquiry_number } })}}
             ></i> : item.enquiry_status==="pending" ? <div style={{display:"flex"}} >  <div style={{marginTop:"4%"}}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="grey" marginTop="20" class="bi bi-envelope-check-fill" viewBox="0 0 16 16">
             <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
             <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
           </svg></div>
            <div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnquiryClick(item.pv_enquiry_number)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
            :item.enquiry_status==="rejected" ? <div style={{display:"flex"}}><div style={{marginTop:"4%"}}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="red" class="bi bi-envelope-x-fill" viewBox="0 0 16 16">
               <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
               <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z"/>
             </svg></div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnquiryClick(item.pv_enquiry_number)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div> :
                            <div style={{display:"flex"}}>
                            <div  style={{marginTop:"5px",marginLeft:"5px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="25"
                              fill="#5ECB5B"
                              class="bi bi-envelope-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                            </svg> </div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}}   onClick={() => handleEnquiryClick(item.pv_enquiry_number,item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>}
           </div>
         </div>

      
      
         
          
        
         <div className="col-2">
         {item.pe_status==='approved' && item.enquiry_status==="approved" && item.chairman_status==='rejected' ? <> 
                        
                      
                        <h6 style={{fontSize:"12px",marginTop:"30px",fontFamily:"inter",fontStyle:"italic",marginLeft:"10px",color:"red"}}>*Trust rejected!!</h6> </> :""}
         </div>
         <div className="col-2" style={{marginLeft:"-3%"}}>
        
         </div>
       </div>
     </div></div>
   ))} </SimpleBar></>
   :admissionView?
   <SimpleBar style={{ maxHeight: "800px", }}>
   {admissionView && <div style={{ display: "flex", marginTop: "3%", width: "62rem" }}>
     <h6
       style={{
         fontFamily: "inter",
         fontSize: "16px",
         fontWeight: "700",
         color: "#6D4482",
         marginLeft: "41%",
       }}
     >
       Primary
     </h6>
     <h6
       style={{
         fontFamily: "inter",
         fontSize: "16px",
         fontWeight: "700",
         color: "#6D4482",
         marginLeft: "18%",
       }}
     >
       Enquiry
     </h6>
     <h6
       style={{
         fontFamily: "inter",
         fontSize: "16px",
         fontWeight: "700",
         color: "#6D4482",
         marginLeft: "15%",
       }}
     >

     </h6>
   </div>
   }
   {admissionView &&
     admissionList.length>0&&admissionList


       .map((item) => (
         <div
           className="row" onClick={() => setUser(item.pv_enquiry_number)}
           style={{ display: "flex", width: "62rem" }}
         >
           <div
             className="patientlistview"
             style={{
               border: user === item.pv_enquiry_number && "1px solid #6D4482",
               display: "flex",
               marginBottom: "1%",
               marginLeft: "2%"

             }}
           >
             <div className="col-4">
               <h6
                 style={{
                   color: "black",
                   fontWeight: "700",
                   fontFamily: "inter",
                   marginTop: "20px",
                   marginLeft: "20px",
                   justifyContent: "center",
                   textTransform: "capitalize",
                   fontSize: "16px",

                 }}
               >
                 {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br /><span style={{ fontSize: "x-small", color: "#207170" }}><b>{item.pv_enquiry_number}</b></span>
               </h6>
             </div>
             {/* <div className="col-2"><i class="fa fa-file" aria-hidden="true" style={{marginTop:'30px',cursor:"pointer"}}
//onClick={()=>handleViewDetails(item.pv_enquiry_number)}
>

</i>
   </div> */}
             <div className="col-3" >
               {/* <i class="fa fa-file" aria-hidden="true"></i> */}
               <div
                 style={{
                   width: "123px",
                   height: "40px",
                   border: "1px solid grey",

                   borderRadius: "5px",
                   marginTop: "20px",
                   marginLeft: "20px",
                 }}
               >

                 <div style={{ display: "flex" }}>
                   <div
                     style={{
                       marginTop: "5px",
                       marginLeft: "5px",
                     }}
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="30"
                       height="28"
                       fill="#5ECB5B"
                       class="bi bi-envelope-check-fill"
                       viewBox="0 0 16 16"
                     >
                       <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                       <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                     </svg>{" "}
                   </div>
                   <div
                     style={{
                       width: "1px",
                       backgroundColor: "grey",
                       marginLeft: "25px",
                       marginTop: "5px",
                     }}
                   >
                     {" "}
                   </div>{" "}
                   <div
                     style={{
                       marginLeft: "15px",
                       marginTop: "4px",
                       cursor: "pointer",
                     }}
                     onClick={() => handleEnvelopClick(item.pv_enquiry_number)}
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="25"
                       height="28"
                       viewBox="0 0 32 32"
                     >
                       <circle
                         cx="16"
                         cy="16"
                         r="4"
                         fill="#245693"
                       />
                       <path
                         fill="#245693"
                         d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                       />
                     </svg>
                   </div>
                 </div>

               </div>
             </div>


             <div className="col-3" style={{}}>
               <div
                 style={{
                   flexDirection: "column",
                   width: "123px",
                   height: "40px",
                   border: "1px solid #CDCDCD",
                   borderRadius: "5px",
                   marginTop: "23px",
                   marginLeft: "20px",
                   textAlign: "center",
                   display: "flex",

                   alignItems: "center",
                 }}
               >

                 <div style={{ display: "flex" }}>
                   <div style={{ marginTop: "4%" }}>
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="30"
                       height="25"
                       fill="#5ECB5B"
                       class="bi bi-envelope-check-fill"
                       viewBox="0 0 16 16"
                     >
                       <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                       <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                     </svg>
                   </div>{" "}
                   <div
                     style={{
                       width: "1px",
                       backgroundColor: "grey",
                       marginLeft: "25px",
                       marginTop: "5px",
                     }}
                   >
                     {" "}
                   </div>{" "}
                   <div
                     style={{
                       marginLeft: "15px",
                       marginTop: "4px",
                       cursor: "pointer",
                     }}
                     onClick={() => handleEnquiryClick(item.pv_enquiry_number)}
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="25"
                       height="28"
                       viewBox="0 0 32 32"
                     >
                       <circle
                         cx="16"
                         cy="16"
                         r="4"
                         fill="#245693"
                       />
                       <path
                         fill="#245693"
                         d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                       />
                     </svg>
                   </div>
                 </div>

               </div>
             </div>

             <div className="col-2">
             {item.admission_status === null ? (

<div style={{display:"flex"}}>
<div  style={{marginTop:"5px",marginLeft:"5px" }}>
                            <i
                              class="fa fa-envelope"
                              aria-hidden="true"
                              style={{
                                fontSize: "23px",
                                fontWeight: "900",
                                color: "rgb(158, 158, 158)",
                                width: "25px",
                                height: "30px",
                                marginLeft: "-10px",
                                marginTop: "0px",
                              }}
                              disabled={
                           
                                item.enquiry_status === null
                              }
                              // onClick={() => navigate("/enquiry")}
                            ></i> </div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"10px",marginTop:"4px"}}   ><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="grey"/><path fill="grey" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
                          ) : item.enquiry_status === "pending" ? (
                            <div style={{display:"flex"}}>
                            <div  style={{marginTop:"5px",marginLeft:"5px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="25"
                              fill="#5ECB5B"
                              class="bi bi-envelope-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                            </svg> </div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}}   onClick={() => handleEnquiryClick(item.pv_enquiry_number,item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
                          ) : (
                 ""
               )}
             </div>
           </div>
         </div>
       ))}
 </SimpleBar>
: approveView ? 
<>                        
     <SimpleBar style={{ maxHeight: "1050px"}}>
     {approvedList.length>0&& <div style={{ display: "flex", marginTop: "3%", width:"62rem" }}>
       <h6 className="col-4" style={{ marginLeft:"2%" }}></h6>
       <h6 className="col-3"
         style={{
           fontFamily: "inter",
           fontSize: "16px",
           fontWeight: "700",
           color: "#6D4482",
           textAlign:"center",
           marginLeft:"-1%"
         }}
       >
         Primary
       </h6>
       <h6 className="col-3"
         style={{
           fontFamily: "inter",
           fontSize: "16px",
           fontWeight: "700",
           color: "#6D4482",
           textAlign:"center",
           marginLeft:"-1%"
         }}
       >
         Enquiry
       </h6>           
     
    </div>}             
     {approvedList
//        .filter((item) => {
//   // Replace 'fullName' with the property that contains the patient's full name
//   const fullName = `${item.first_name} ${item.last_name}`;
//   return fullName.toLowerCase().includes(searchQuery.toLowerCase());
// })
.map((item) => ( <div>

<div className="row"onClick={() => setUser(item.pv_enquiry_number)} style={{ display: "flex",width:"62rem" }}>
<div className="patientlistview" style={{ border:user===item.pv_enquiry_number&&"1px solid #6D4482", display: "flex", marginLeft:"2%", marginBottom:'1%'  }}>

 <div className="col-4">
   <h6
     style={{
       color: "black",
       fontWeight: "700",
       fontFamily: "inter",
       marginTop: "20px",
       marginLeft: "20px",
       justifyContent: "center",
       textTransform: "capitalize",
       fontSize:"16px"
     }}
   >
     {/*     <span style={{fontSize:"x-small",color:"#207170"}}><b>{item.pv_enquiry_number}</b></span>-{item.first_name}{" "}{item.last_name} */}
        {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br/><span style={{fontSize:"x-small",color:"#207170"}}><b>{item.pv_enquiry_number}</b></span>
     
   </h6>
 </div>

 <div className="col-3" style={{ }}>

   <div
    
     style={{
       width: "123px",
       height: "40px",
       border:"1px solid grey",
       borderRadius: "5px",
       cursor: "pointer",
       margin:"auto", 
       marginTop: "18px", 
      alignItems: "center"                         
     }}

   >
     { item.pe_status === "approved" ?  ( <div style={{display:"flex"}} >  <div style={{marginTop:"4%"}} >
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="#5ECB5B" class="bi bi-envelope-check-fill" viewBox="0 0 16 16">
       <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
       <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
     </svg> </div> <div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnvelopClick(item.pv_enquiry_number)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
     ) : (
       <div style={{display:"flex"}} >  <div style={{marginTop:"4%"}}> <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="red" class="bi bi-envelope-x-fill" viewBox="0 0 16 16">
       <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
       <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z"/>
     </svg></div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}} onClick={() => handleEnvelopClick(item.pv_enquiry_number)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
     )}
   </div>
 </div>
 <div className="col-3">
   <div 
     style={{
       flexDirection: "column",
       width: "123px",
       height: "40px",
       border:"1px solid grey",
       margin: "auto",  
       borderRadius: "5px",
       marginTop: "18px",
       textAlign: "center",
       display: "flex",
       alignItems: "center",
     }}
    
   >
      {<div style={{display:"flex"}}>
                    <div  style={{marginTop:"5px",marginLeft:"5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="25"
                      fill="#5ECB5B"
                      class="bi bi-envelope-check-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                    </svg> </div><div style={{width:"1px",backgroundColor:"grey" ,marginLeft:"25px",marginTop:'5px'}}>  </div>  <div style={{marginLeft:"15px",marginTop:"4px",cursor:"pointer"}}   onClick={() => handleEnquiryClick(item.pv_enquiry_number,item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693"/><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"/></svg></div></div>
      }   
   </div>
 </div>
</div>
</div></div>
))} </SimpleBar></>
:
   ""}
        </div>
</div>
        {view ? (
          <ViewPopUp
            show={view}
            onHide={() => {
              setView(false);
            }}
          />
        ) : ""}

{view1 ? (
          <EnquiryFormPopUp
            show={view1}
            onHide={() => {
              setView1(false);
            }}
          />
        ) : null}
         {approveShow ? (
          <ApprovePopUp
            show={approveShow}
            onHide={() => {
              setApproveShow(false);
            }}
          />
        ) : null}


{approveCommentShow ? (
          <ApproveCommentPopUp
            show={approveCommentShow}
            onHide={() => {
              setApproveCommentShow(false);
            }}
          />
        ) : null}

{enquiryApprovalComment ? (
          <EnquiryApprovalPopUp
            show={enquiryApprovalComment}
            onHide={() => {
              setEnquiryApprovalComment(false);
            }}
          />
        ) : null}
           {enquiryApproveShow ? (
          <EnquiryApprovePopUp
            show={enquiryApproveShow}
            onHide={() => {
              setEnquiryApproveshow(false);
            }}
          />
        ) : null}
          {rejectShow ? (
          <RejectPopUp
            show={rejectShow}
            onHide={() => {
              setRejectShow(false);
            }}
          />
        ) : null}

{rejectaddComments ? (
          <RejectCommentPopUp
            show={rejectaddComments}
            onHide={() => {
              setRejectaddComments(false);
            }}
          />
        ) : null}

{enquiryRejectComment ? (
          <EnquiryRejectPopUp
            show={enquiryRejectComment}
            onHide={() => {
              setEnquiryRejectComment(false);
            }}
          />
        ) : null}

{rejectEnquiryShow ? (
          <RejectEnquiryPopUp
            show={rejectEnquiryShow}
            onHide={() => {
              setRejectEnquiryShow(false);
            }}
          />
        ) : null}

{
                refererView1 ?
                    <RefererPopUp1
                        show={refererView1}
                        onHide={() => setRefererView1(false)}

                    /> : null
            }
        {
                qualityView ?
                    <QualityPopUp
                        show={qualityView}
                        onHide={() => setQualityView(false)}

                    /> : null
            }

{
                relativeView ?
                    <RelativePopUp
                        show={relativeView}
                        onHide={() => setRelativeView(false)}

                    /> : null
            }



{
                weaknessView ?
                    <WeaknessPopUp
                        show={weaknessView}
                        onHide={() => setWeaknessView(false)}

                    /> : null
            }
            {
                recommenderView ?
                    <RecommenderPopUp
                        show={recommenderView}
                        onHide={() => setRecommenderView(false)}

                    /> : null
            }
            {
                consentView ?
                    <ConsentPopUp
                        show={consentView}
                        onHide={() => setConsentView(false)}

                    /> : null
            }
      </div>
    </>
  );
}
export default AdmissionPatient;
