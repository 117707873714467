import React, {useState, useRef, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function AddCholestrol(props) {
  const alert = useAlert();
    const [hdlChol, setHdlChol]= useState([]);
  const [ldlChol, setLdlChol]= useState([]);
  const [serumChol, setSerumChol]= useState([]);
  const [totalChol, setTotalChol]= useState([]);
  const saveButtonRef = useRef(null)

  useEffect(() => {
    const handleKey = (e) => {

      if (e.altKey && e.key === 's') {
        e.preventDefault()
        saveButtonRef.current.click()
      }else if (e.key === 'Enter') {
        e.preventDefault()
        saveButtonRef.current.click()
      }
    
    }
    document.addEventListener("keydown", handleKey);
    
    return () => {
      document.removeEventListener("keydown", handleKey);
    }
    
  }, []);
	const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }
  

    const handleCholestrol = (e)=> {
        const val = e.target.value
        if(val.length < 5){
        setTotalChol(e.target.value)
        }
        else return false
    }

    const handleHdl = (e)=> {
        const val = e.target.value
        if(val.length < 6){
        setHdlChol(e.target.value)
        }
        else return false
    }
     const handleLdl = (e)=> {
        const val = e.target.value
        if(val.length < 6){
        setLdlChol(e.target.value)
        }
        else return false
    }
     const handleSerum = (e)=> {
        const val = e.target.value
        if(val.length < 6){
        setSerumChol(e.target.value)
        }
        else return false
    }

   const handleSubmit = () => {

        if(hdlChol < 1000 || ldlChol < 1000 || serumChol <1000 || totalChol <1000 ) {

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
            'result':totalChol,
            'hdl':hdlChol,
            'ldl': ldlChol,
            'serum':serumChol,
         'vital_name': "cholesterol",
         'date_time':currentDateTime
           }
     
     
       
           axios.post(`${APIURL}/api/v1/patient/vitals/?patient_id=${id}&timeZone=${timezoneOffset}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
            alert.success("Cholestrol added Successfully")
            props.submitcholestrol() ; setHdlChol(""); setLdlChol("");setSerumChol("");setTotalChol("") 
         
          
       }
       else{
           alert.error(res.data.message)
        
       }
     
         })
        

        }

      else{
     
      if(totalChol>150 ){
        alert.error("total Cholestrol must be less than 150.")
      }
     if(hdlChol>190){
        alert.error("HDL Cholestrol must be less than 190.")
      }
      if(ldlChol>200){
        alert.error("LDL Cholestrol must be less than 200.")
      }
      if(serumChol>250){
        alert.error("Serum Cholestrol must be less than 250.")
      }
        
    }

   }

     
     

    return (
        <div className='flex-col'>
           <input style={{width:'50%'}} type="number" min='0' max='150' placeholder="Cholestrol" value={totalChol} onChange={handleCholestrol} className="form-control"/><br/>
           <input style={{width:'50%'}} type="number" min='0' max='190' placeholder="HDL Cholestrol " value={hdlChol} onChange={handleHdl} className="form-control"/><br/>
           <input style={{width:'50%'}} type="number" min='0' max='200' placeholder="LDL Cholestrol BP" value={ldlChol} onChange={handleLdl} className="form-control"/><br/>
           <input style={{width:'50%'}} type="number" min='0' max='250' placeholder="Serum Cholestrol" value={serumChol} onChange={handleSerum} className="form-control"/><br/>
           <button disabled={ (totalChol < 1 || totalChol=== undefined) &&( hdlChol < 1 || hdlChol===undefined) && (ldlChol < 1 || ldlChol===undefined) && (serumChol < 1 || serumChol ===undefined)} onClick={handleSubmit} ref={saveButtonRef} className='btn btn-primary btn-col'>Add</button> 




    


        </div>
    )
}

export default AddCholestrol
