import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { APIURL } from "../../Global";

import '../../modules/css/bootstrap.min.css';
import '../../modules/css/iofrm-style.css';
import '../../modules/css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './hospprofile.css'
import DeliveryUpdate from './DeliveryUpdate';
import EmergencyUpdate from './EmergencyUpdate';
import HealthCareUpdate from './HealthCareUpdate';
import RegUpdate from './RegUpdate';
import YearUpdate from './YearUpdate';
import LocationUpdate from './LocationUpdate';
import PractiseUpdate from './PractiseUpdate';
import SpecUpdate from './SpecUpdate';
import FacilityUpdate from './FacilityUpdate';
import DescriptionUpdate from './DescriptionUpdate';
import WebsiteUpdate from './WebsiteUpdate';
import MobileUpdate from './MobileUpdate';
import NameUpdate from './NameUpdate';
import noimage from '../../assets/images/icons/noimag.png'
import { useAlert } from 'react-alert';
function EditProfile(props) {

  const [hospData, setHospData] = useState([])
  const [img, setImg] = useState('');
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [state, setState] = useState('')
  const [street, setStreet] = useState('');
  const [country, setCountry] = useState('');
  //const [email, setEmail]=useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [practise, setPractise] = useState('');
  const [spec, setSpec] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [desc, setDesc] = useState('');
  const [descEdit, setDescEdit] = useState(false)
  const [show, setShow] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [previewOn, setPreviewOn] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [locationEdit, setLocationEdit] = useState(false)
  const [practiseEdit, setPractiseEdit] = useState(false)
  const [specEdit, setSpecEdit] = useState(false)
  const [nameEdit, setNameEdit] = useState(false)
  //const [emailEdit, setEmailEdit]= useState(false)
  const [phoneEdit, setPhoneEdit] = useState(false)
  const [websiteEdit, setWebsiteEdit] = useState(false)
  const [facilityEdit, setFacilityEdit] = useState(false)
  const [sizeShow, setSizeShow] = useState(false)
  const [validShow, setValidShow] = useState(false)
  const [contentShow, setContentShow] = useState(false)
  const [uploadShow, setUploadShow] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [flag, setFlag] = useState(false)
  const [emergency, setemergency] = useState('');
  const [year, setyear] = useState('');
  const [healthcare, setHealthcare] = useState('');
  const [delivery, setDelivery] = useState('');
  const [regno, setRegNo] = useState('');
  const [deliveryEdit, setDeliveryEdit] = useState(false)
  const [regNumberEdit, setRegnoEdit] = useState(false)
  const [emergencyEdit, setEmergencyEdit] = useState(false)
  const [yearEdit, setYearEdit] = useState(false)
  const [healthEdit, setHealthEdit] = useState(false)
  const alert = useAlert() 
const[lat,setLat]=useState()
const[lon,setLon]=useState()
  const reader = new FileReader();


  const editLocation = (e) => {
    e.preventDefault();
    setLocationEdit(true)
  }
  const editdelivery = (e) => {
    e.preventDefault();
    setDeliveryEdit(true)
  }
  const editregno = (e) => {
    e.preventDefault();
    setRegnoEdit(true)
  }
  const editEmergency = (e) => {
    e.preventDefault();
    setEmergencyEdit(true)
  }
  const edityear = (e) => {
    e.preventDefault();
    setYearEdit(true)
  }
  const editHealth = (e) => {
    e.preventDefault();
    setHealthEdit(true)
  }

  const editPractise = (e) => {
    e.preventDefault();
    setPractiseEdit(true)
  }

  const editSpec = (e) => {
    e.preventDefault();
    setSpecEdit(true)
  }

  const editFacility = (e) => {
    e.preventDefault();
    setFacilityEdit(true)
  }

  const editName = (e) => {
    e.preventDefault();
    setNameEdit(true)
  }

  /* const editEmail = (e) => {
     e.preventDefault();
     setEmailEdit(true)
   }*/

  const editPhone = (e) => {
    e.preventDefault();
    setPhoneEdit(true)
  }

  const editWebsite = (e) => {
    e.preventDefault();
    setWebsiteEdit(true)
  }

  const editDesc = (e) => {
    e.preventDefault();
    setDescEdit(true)
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
  }

  const handleRemove = async () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    const file = dataURLtoFile(noimage)
    let fd = new FormData()

    fd.append('photo', file, "noname.png")

    await axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, fd, {
      headers: {
        "Authorization": 'Token ' + str,
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          alert.success("Updated successfully")
          setPreviewOn(false)
          setRefresh(!refresh)

        } else {
          alert.error("Error occured.Try again!")
          setPreviewOn(false)

        }

      })
      .catch(err => {
        alert.error("Error occured.Try again!")
        setPreviewOn(false)

      })
  }

 // /api/v1/patient/emergency-contact/

 useEffect(() => {


  const tokenString = sessionStorage.getItem('usertoken');

  let str = tokenString.replace(/["]+/g, '')


  axios.get(`${APIURL}/api/v1/patient/emergency-contact/`, {
    headers: {

      "Authorization": 'Token ' + str
    }
  })
    .then(res => {
      

      if (res.data.status === "success") {

       setemergency(res.data.contact)
      }


    })
    .catch(err => {

    })
}, [refresh]);

  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')


    axios.get(`${APIURL}/api/v1/service-provider/hospital-profile/`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          setHospData(res.data.hospital_profile)
          setStreet(res.data.hospital_profile.street)
          setPractise(res.data.hospital_profile.practise_type)
          setLocation(res.data.hospital_profile.location)
          setState(res.data.hospital_profile.location)
          setSpec(res.data.hospital_profile.specializations)
          setFacilities(res.data.hospital_profile.facilities)
          setDesc(res.data.hospital_profile.description)
          setImg(res.data.hospital_profile.photo)
          //setEmail(hospData.primary_email)
          setPhone(res.data.hospital_profile.mobile_number)
          setWebsite(res.data.hospital_profile.website)
          setName(res.data.hospital_profile.name)
          setLat(res.data.hospital_profile.latitude)
          setLon(res.data.hospital_profile.longitude)
          setemergency(res.data.hospital_profile.emergency_number)
          setyear(res.data.hospital_profile.established_year)
          setHealthcare(res.data.hospital_profile.health_care_type)
          setDelivery(res.data.hospital_profile.delivery_radius)
          setRegNo(res.data.hospital_profile.reg_number)
        }


      })
      .catch(err => {

      })


  }, [refresh]);



  const LocationPopUp = (props) => {
    return (
      <Modal
        {...props}
       size="lg"
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <div style={{paddingRight:"14%"}}>
          <LocationUpdate loc={location} strt={street} lon={lon} lat={lat} />
          </div>
        </Modal.Body>
         
      </Modal>
    )
  }
 
  const DeliveryPopUp = (props) => {
    return (
      <Modal
        {...props}
       size="lg"
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Delivery Radius
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <div style={{paddingRight:"14%"}}>
          <DeliveryUpdate handle={DeliveryHandler} delivery={delivery} />
          </div>
        </Modal.Body>
         
      </Modal>
    )
  }
  const YearPopUp = (props) => {
    return (
      <Modal
        {...props}
       size="lg"
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Established Year
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <div style={{paddingRight:"14%"}}>
          <YearUpdate handle={YearHandler} year={year} />
          </div>
        </Modal.Body>
         
      </Modal>
    )
  }
  const RegPopUp = (props) => {
    return (
      <Modal
        {...props}
       size="lg"
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Registration Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <div style={{paddingRight:"14%"}}>
          <RegUpdate handle={RegHandler}  regno={regno}/>
          </div>
        </Modal.Body>
         
      </Modal>
    )
  }
  const EmergencyPopUp = (props) => {
    return (
      <Modal
        {...props}
       size="lg"
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Emergency Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <div style={{paddingRight:"14%"}}>
          <EmergencyUpdate  handle={EmergencyHandler} emergency1={emergency}/>
          </div>
        </Modal.Body>
         
      </Modal>
    )
  }


  const PractisePopUp = (props) => {
    return (
      <Modal
        {...props}

        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Practice Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">

          <PractiseUpdate handle={PractiseHandler} />

        </Modal.Body>
         
      </Modal>
    )
  }


  const SpecPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Specialization
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <SpecUpdate handle={SpecHandler} />

        </Modal.Body>
         
      </Modal>
    )
  }

  const FacilityPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Facility
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <FacilityUpdate handle={FacilityHandler} />

        </Modal.Body>
         
      </Modal>
    )
  }

  const NamePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Hospital Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <NameUpdate name={hospData.name} />

        </Modal.Body>
         
      </Modal>
    )
  }

  /*const EmailPopUp = (props) => {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Update Email</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <EmailUpdate handle={EmailHandler} />
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Close</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }*/

  const PhonePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Update Phone
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <MobileUpdate handle={PhoneHandler} ph={phone} />

        </Modal.Body>
         
      </Modal>
    )
  }

  const WebsitePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Website
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <WebsiteUpdate handle={WebsiteHandler} wb={website} />

        </Modal.Body>
         
      </Modal>
    )
  }

  const DescriptionPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <DescriptionUpdate handle={DescHandler} des={desc} />

        </Modal.Body>
         
      </Modal>
    )
  }

  const removePicPreview = (e) => {
    e.preventDefault();
    setPreviewOn(false);
    setFlag(true)
    setToggle(false)
  }

  const editImage = () => {
    const fileInput = document.getElementById('imageInput')
    fileInput.click();
  }


  const uploadImage = e => {

    const file = e.target.files[0];

    if (!file) {
      setUploadShow(!uploadShow)
      return false
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow)
      return false;
    }
    if (file.size > 10e6) {
      setSizeShow(!sizeShow)
      return false
    }

    reader.onload = (e) => {
      const img1 = new Image();
      img1.onload = () => {
        setToggle(true)
        setImg(file)
      };
      img1.onerror = () => {
        setPreviewOn(false)
        setContentShow(!contentShow)

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(file);

    const url = URL.createObjectURL(file)
    setImgURL(url)
    setPreviewOn(true)
  }


  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">You have Exceeded the size limit MB</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Select an Image</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Select a valid Image</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Invalid Image Content</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }



  const submitHandle = async () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    let fd = new FormData()


    if (img !== null) {
      fd.append('photo', img)
    }


    await axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, fd, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {



        if (res.data.status === "success") {

          alert.success("Updated successfully")
          setPreviewOn(false)
          setRefresh(!refresh)

          // setEmail() 
        } else if (res.data.status === "error") {



        } else {

          alert.error("Error occured.Try again!")
          setPreviewOn(false)
        }

      })
      .catch(err => {
        alert.error("Error occured.Try again!")
        setPreviewOn(false)

      })


  }


  /*const LocationHandler = ()=>{
    setLocationEdit(false)
    setRefresh(!refresh)
  }*/

  const RegHandler = () => {
    setRegnoEdit(false)
    setRefresh(!refresh)
  }
  const HealthHandler = () => {
    setHealthEdit(false)
    setRefresh(!refresh)
  }
  const DeliveryHandler = () => {
    setDeliveryEdit(false)
    setRefresh(!refresh)
  }
  const YearHandler = () => {
    setYearEdit(false)
    setRefresh(!refresh)
  }
  const EmergencyHandler = () => {
    setEmergencyEdit(false)
    setRefresh(!refresh)
  }
  const PractiseHandler = () => {
    setPractiseEdit(false)
    setRefresh(!refresh)
  }

  const SpecHandler = () => {
    setSpecEdit(false)
    setRefresh(!refresh)
  }

  const FacilityHandler = () => {
    setFacilityEdit(false)
    setRefresh(!refresh)
  }
  /*const EmailHandler = ()=>{
    setEmailEdit(false)
    setRefresh(!refresh)
  }
  const NameHandler = ()=>{
    setNameEdit(false)
    setRefresh(!refresh)
  }*/
  const PhoneHandler = () => {
    setPhoneEdit(false)
    setRefresh(!refresh)
  }
  const WebsiteHandler = () => {
    setWebsiteEdit(false)
    setRefresh(!refresh)
  }
  const DescHandler = () => {
    setDescEdit(false)
    setRefresh(!refresh)
  }


  return (
    <div className="profile-flex-col profile-holder" style={{overflow:"auto"}}>
            <h5 className="title-of-tasks"><i style={{ marginRight: '4px' }} className="fas fa-user-circle"></i>Edit Profile</h5>
      {previewOn === false ?(
      <>
        <div>
          <img className="avatar" src={img ? img : ''} />
        </div>
        {img && !(img instanceof File) && img !== '' && img.includes('noname') && (
  <div>
    <input type="file" id="imageInput" hidden="hidden" onChange={uploadImage} />
    <i style={{ cursor: 'pointer'}} onClick={editImage} id={img !== '' ? 'cam-id' : 'icon-pos'} className="fas fa-camera fa-lg"></i>
  </div>
)}
      </>
    ) : (
      <div>
        <img src={imgURL} className="avatar" />
      </div>
    )}
      
      <br />
      <div className='flex-row'>
        <button onClick={submitHandle} disabled={toggle === false} className="btn btn-primary btn-col" >Update Picture</button>
        <button style={{ marginLeft: '10px' }} disabled={img === '' || img === null} onClick={handleRemove} className="btn btn-primary btn-col" >Remove Picture</button>
      </div>



      <div style={{textAlign:"left"}}>

        {/* <div><label className='bold-label'>Hospital Name : </label><label className='bio-data'>{name}</label>
          <label onClick={editName} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
        </div> */}

        {/* <div><label className='bold-label'>Email : </label><label className='bio-data'>{email===''?<span className='text-danger'>No Data</span>:email}</label>
                <label onClick={editEmail}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
            </div> */}

        <div ><label className='bold-label'>Phone : </label><label className='bio-data'>{phone === '' ? <span className='text-danger'>No Data</span> : phone}</label>
          <label onClick={editPhone} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
        </div>

        <div><label className='bold-label'>Address : </label><label className='bio-data'>{street === '' ? <span className='text-danger'>No Data</span> : street}</label>
          <label onClick={editLocation} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
        </div>

        <div><label className='bold-label'>Emergency Number : </label><label className='bio-data'>{emergency===''?<span className='text-danger'>No Data</span>:emergency}</label>
                <label onClick={editEmergency}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div> 

        {/* <div ><label className='bold-label'>Health Care : </label><label className='bio-data'>{healthcare===[]?<span className='text-danger'>No Data</span>:healthcare}</label>
                <label onClick={editHealth}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div> */}

        <div ><label className='bold-label'>Established Year : </label><label className='bio-data'>{year === '' ?<span className='text-danger'>No Data</span>:year}</label>
                <label onClick={edityear}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
              <div ><label className='bold-label'>Registration Number : </label><label className='bio-data'>{regno === '' ?<span className='text-danger'>No Data</span>:regno}</label>
                <label onClick={editregno}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
              <div ><label className='bold-label'>Delivery Radius: </label><label className='bio-data'>{delivery === '' ?<span className='text-danger'>No Data</span>:delivery}</label>
                <label onClick={editdelivery}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>

        <div ><label className='bold-label'>Website : </label><label className='bio-data'>{website === '' ? <span className='text-danger'>No Data</span> : website}</label>
          <label onClick={editWebsite} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
        </div>

        <div><label className='bold-label'>Description : </label><label className='bio-data'>{desc === '' ? <span className='text-danger'>No Data</span> : desc}</label>
          <label onClick={editDesc} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
        </div>

      </div> 

      {
        <LocationPopUp
          show={locationEdit}
          onHide={() => {
            setLocationEdit(false)
          }} />

      }
          {
        <EmergencyPopUp
          show={emergencyEdit}
          onHide={() => {
            setEmergencyEdit(false)
          }} />

      }
        
          {
        <YearPopUp
          show={yearEdit}
          onHide={() => {
            setYearEdit(false)
          }} />

      }
          {
        <RegPopUp
          show={regNumberEdit}
          onHide={() => {
            setRegnoEdit(false)
          }} />

      }
          {
        <DeliveryPopUp
          show={deliveryEdit}
          onHide={() => {
            setDeliveryEdit(false)
          }} />

      }

      {
        <PractisePopUp
          show={practiseEdit}
          onHide={() => {
            setPractiseEdit(false)
          }} />
      }

      {
        <SpecPopUp
          show={specEdit}
          onHide={() => {
            setSpecEdit(false)

          }} />
      }

      {
        <FacilityPopUp
          show={facilityEdit}
          onHide={() => {
            setFacilityEdit(false)

          }} />
      }

      {
        <NamePopUp
          show={nameEdit}
          onHide={() => {
            setNameEdit(false)

          }} />
      }

      {/*{
      <EmailPopUp
      show={emailEdit}
      onHide={()=>{
        setEmailEdit(false)
        
      }} />
    }*/}

      {
        <PhonePopUp
          show={phoneEdit}
          onHide={() => {
            setPhoneEdit(false)

          }} />
      }

      {
        <WebsitePopUp
          show={websiteEdit}
          onHide={() => {
            setWebsiteEdit(false)

          }} />
      }

      {
        <DescriptionPopUp
          show={descEdit}
          onHide={() => {
            setDescEdit(false)

          }} />
      }

      {
        sizeShow ?
          <SizePopUp
            show={sizeShow}
            onHide={() => {
              setSizeShow(false)
            }} /> : null
      }

      {
        uploadShow ?
          <LoadPopUp
            show={uploadShow}
            onHide={() => {
              setUploadShow(false)
            }} /> : null
      }

      {
        validShow ?
          <ValidPopUp
            show={validShow}
            onHide={() => {
              setValidShow(false)
            }} /> : null
      }

      {
        contentShow ?
          <ContentPopUp
            show={contentShow}
            onHide={() => {
              setContentShow(false)
            }} /> : null
      }



    </div>
  )
}

export default EditProfile
