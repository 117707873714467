import React, { useState } from "react";
import "./profcard.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";

function ProfCard({ data, freeCount, activeCount, handle }) {
  const [userId, setUserId] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [removeError, setRemoveError] = useState(false);
  const [removeErrorMsg, setRemoveErrorMsg] = useState("");
  const [licRemove, setLicRemove] = useState(false);
  const [licSuccess, setLicSuccess] = useState(false);
  const [, setLicFail] = useState(false);
  const [licMsg, setLicMsg] = useState("");
  const alert = useAlert();
  const create_link = `/create-prof/${data.id}`;

  //const suspend_link = `suspend-bdo/${data.id}`;
  const change_password_link = `/changepassword-prof/${data.id}`;
  const update_link = `/update-prof/${data.id}`;
  //const revoke_suspension_link = `revoke-bdo/${data.id}`;
  // const delete_link = `deleteBdo/${data.id}`;

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [suspendShow, setSuspendShow] = useState(false);
  const [isSuspend, setIsSuspend] = useState(data.is_suspended);
  const [modalShow, setModalShow] = useState(false);
  // const ConfirmPopUp = (props) => {
  //   return (
  //     <Modal
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //       backdrop="static"
  //       keyboard={false}
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <h4 id="confirm-reject">
  //           Are you sure to add this user as a Free User ?
  //         </h4>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button variant="secondary" onClick={props.onHide}>
  //           No
  //         </Button>
  //         <Button variant="danger" onClick={props.onCall}>
  //           Yes
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };

  // const SubmitPopUp = (props) => {
  //   return (
  //     <Modal
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       backdrop="static"
  //       keyboard={false}
  //       centered
  //     >
  //       <Modal.Header>
  //         <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <h4 className="text-success">Added User as a Free User</h4>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button className="btn btn-primary btn-col" onClick={props.onHide}>
  //           Ok
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };
  

  const handleDeletePopUp = (id) => {
    setDeleteIndex(id);
    setDeleteShow(true);
  };

  const DeleteConfirmPopup = (props) => {

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Delete User </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to Delete the User ID {deleteIndex}
          </h5>
          <br />

          <div className="form-group "  >
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                
                handleDelete(deleteIndex);

              }}
            >
              {" "}
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleDelete = (item) => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");


    axios
      .post(
        `${APIURL}/api/v1/service-provider/hospital-user-delete/?user_id=${deleteIndex}&role=alliedprofessional`,
        {},
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Deleted successfully")
          handle();
        } else {
        }
        alert.error("Error occcured. Try again!")
        setDeleteIndex("");
        
      })
      .catch((err) => {
      });
  };

  const handleSuspendPopUp = (id) => {
    setDeleteIndex(id);
    setSuspendShow(true);
    setIsSuspend(!isSuspend)
  };
  const SuspendConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Suspend User </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to{data.is_suspended ? "Authorise" : "Suspend"} the User ID {deleteIndex}
          </h5>
          <br />

          <div className="form-group "  >
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleSuspend(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleSuspend = (e) => {
    //	e.preventDefault();

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );


    const data = {
      is_suspended: isSuspend
    }

    axios.put(`${APIURL}/api/v1/service-provider/allied-professional-detail/${deleteIndex}/`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Token " + v2
      },
    })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success(`${isSuspend ? "Suspended" : "Authorised"} Successfully!!`);
          handle()


        } else {
          alert.error("Error occuerd. Try again!")
          // setSubmitMsg("error");
        }
        setDeleteIndex("");
        setSuspendShow(false);
      })
      .catch((err) => {
        alert.error("Error occuerd. Try again!")
        // setSubmitMsg("error");
      });

  };
  return (
    <>
      <div className="doctor-card">
        <div className="card-header12 bdo-card-header">
          <h6 className="dr-id"> ID : {data.id} </h6>
          <h6 style={{textTransform:"capitalize"}}> {data.name} </h6>
        </div>

        <div className="card-content26">
          <h6 >
            <span className="key-labels"> Username </span><br/> <span>{data.username}</span>
          </h6>
          <h6 >
            <span className="key-labels"> Qualification </span> <br/><span style={{textTransform:"capitalize"}}>{" "}
            {data.qualification}</span>
          </h6>
          <h6 >
            <span className="key-labels"> Specialization </span><br/><span style={{textTransform:"capitalize"}}>{" "}
            {data.specializations}</span>
          </h6>
          <h6 >
            <span className="key-labels"> Department </span><br/> <span style={{textTransform:"capitalize"}}>{data.department}{" "}</span>
          </h6>
          <br />

          <h6>
            {data.user ? (
            <div className="test3">
                <span style={{ color: "var(--green)" }} className="bold-class">
                  {" "}
                  Valid User Credentials{" "}
                </span>
              </div>
            ) : (
              <div className="test3">
                <span style={{ color: "var(--red)" }} className="bold-class">
                  {" "}
                  No User Credentials{" "}
                </span>
              </div>
            )}
          </h6>

          <h6>
            {" "}<div className="test3">
            <span className="key-labels"> Status </span>:{" "}
            {data.is_suspended ? (
              <span style={{ color: "var(--red)" }}> Suspended </span>
            ) : (
              <span style={{ color: "var(--green)" }}> Authorized </span>
            )}{" "}
         </div> </h6>

          <div className="contact-div">
            <i className="fa fa-phone key-labels" aria-hidden="true"></i>
            {data.phone}
            <br />
            <i
              className="fa fa-envelope key-labels"
              aria-hidden="true"
            ></i>{" "}
            {data.email}

            <br />
            <br />

          </div>
          <br />
         

          <div className="card-content-divider"></div>
          <div className="bottom-links">
            {data.is_suspended ? (
              <Link to="#"
                onClick={() => {
                  handleSuspendPopUp(data.id);
                }}

              > Authorize |</Link>
            ) : (
              <Link to=""
                onClick={() => {
                  handleSuspendPopUp(data.id);
                }}
                className="delete-warning"> Suspend |</Link>
            )}

            {data.user ? (
              <Link to={change_password_link}>Change Password |</Link>
            ) : (
              <span className="temp-disabled"> Change Password| </span>
            )}
            {data.user ?
              <span className="temp-disabled"> Generate User Credentials|</span>
              :

              <Link to={create_link} state={{ name: data.name }} > Generate User Credentials |</Link>}

            <Link to={update_link}> Edit Details |</Link>

            <Link to=""
              onClick={() => {
                handleDeletePopUp(data.id);
              }}
              className="delete-warning"
            >
              Delete User{" "}
            </Link>
          </div>

        </div>
      </div>

    
      {deleteShow ? (
        <DeleteConfirmPopup
          show={deleteShow}
          onHide={() => {
            setDeleteShow(false);
            setDeleteIndex("");
            handle();
          }}
        />
      ) : (
        ""
      )}

      {suspendShow ? (
        <SuspendConfirmPopup
          show={suspendShow}
          onHide={() => {
            setSuspendShow(false);
            setDeleteIndex("");
            handle();
          }}
        />
      ) : (
        ""
      )}

      
    </>
  );
}

export default ProfCard;
