import axios from "axios";
import React, { useState, useEffect, useRef } from 'react'
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DatePicker } from "rsuite";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";

import SimpleBar from 'simplebar-react';
import FormView from "./FormView";
function Pending(props) {
    const alert = useAlert()
    const [requests, setRequests] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [formShow, setFormShow] = useState(false)
    const [patientDetails, setPatientDetails] = useState({})
    useEffect(() => {
if(props.visitor.length>0){
    setRequests(props.visitor)
}
else{
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/visitor-list/?visitor_type=${props.type}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.status === 200) {

                    setRequests(res.data.visitors)

                }
            })
            .catch((err) => { });
        }
    }, [refresh, props.type,props.visitor]);
    const FormPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <FormView patient={patientDetails} />

                </Modal.Body>
            </Modal>
        );
    };
    const submit = (status, id) => {

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        const data = { status: status,id:id }


        axios.put(`${APIURL}/api/v1/service-provider/visitor-list/`, data, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {


                if (res.data.status === "success") {

                    alert.success("successfully Updated")
                    setRefresh(!refresh)

                } else {


                }

            })

    }

    return (
        <div>
             {requests.length > 0 && <div className="row"style={{ paddingRight: "1%" }}>
                <label className='death-text-style-here col-3 ' ></label>
                <label className='death-text-style-here col-2' >Application Form</label>
                <label className='death-text-style-here col-2' >Purpose of Visit</label>
                <label className='death-text-style-here col-3' >Date</label>
              
                <label className='death-text-style-here col-1' >Approve</label>
                <label className='death-text-style-here col-1' >Reject</label>
                
            </div>}

            <SimpleBar style={{ maxHeight: '800px', paddingLeft: "2%", paddingRight: "2%", marginTop: "1%" }}>
                {requests.length > 0 ? requests.map((item) => {
                    return (
                        <div key={item.id} style={{ paddingRight: "1%" }} className="deathreq_lists row">
                            <div className="col-3" style={{ padding: "1%", fontSize: "18px", textTransform: "capitalize" }}>&nbsp;&nbsp;{item.first_name}{" "}{item.last_name}</div>
                            <div className="col-2" style={{ paddingLeft: "2%" }} >
                                <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", padding: "1%", width: "70%" }} className="row">
                                    <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)", color: "rgba(79, 135, 203, 1)" }} className="col-6">


                                        <span class="fa-stack fa-lg" style={{}}  >
                                            <i class="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px", color: "#4F87CB" }} ></i>
                                            <i class="fa fa-question-circle-o" aria-hidden="true" style={{ fontSize: '12px', color: "#4F87CB" }}></i>
                                        </span>

                                    </div>
                                    <div style={{ paddingTop: "7%" }} className="col-6">
                                        <i onClick={() => { setFormShow(true); setPatientDetails(item) }}
                                            class="fa fa-eye" style={{ fontSize: "23px", marginLeft: "5%", color: "rgba(36, 86, 147, 1)" }} aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2" style={{ padding: "1%", textTransform: "capitalize" }}>{item.purpose.replace("_", " ")}</div>
                            <div className="col-3" style={{ padding: "1%" }}><span style={{ color: "blue" }}>{item.from_date}</span>{item.to_date!==item.from_date && "--"}<span style={{ color: "blue" }}>{item.to_date!==item.from_date && item.to_date}</span></div>
                           
                            <div style={{}} className="col-1"><button onClick={() => submit("approved", item.id)} style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", width: "99%", height: "35px", borderRadius: "5px", backgroundColor: "rgba(97, 198, 95, 1)", marginTop: "6%" }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="21"
                                    viewBox="0 0 22 21"
                                    fill="none"
                                >
                                    <path
                                        d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                                        fill="rgba(255, 255, 255, 1)"
                                    />
                                </svg>{" "}</button></div>
                            <div style={{}} className="col-1"><button onClick={() => submit("rejected", item.id)} style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", width: "99%", height: "35px", borderRadius: "5px", backgroundColor: "rgba(198, 114, 95, 1)", marginTop: "6%" }}>  <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="21"
                                viewBox="0 0 22 21"
                                fill="none"
                            >
                                <path
                                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                                    fill="rgba(255, 255, 255, 1)"
                                />
                            </svg></button></div>
                            
                        </div>
                    )

                }) :

                    <div className="" style={{ marginTop: "10%",color:"red" }}>No Requests Pending!!</div>
                }
            </SimpleBar>
            {formShow ? (
                <FormPopUp
                    show={formShow}
                    onHide={() => {
                        setFormShow(false);
                    }}
                />
            ) : null}
        </div>
    )
}
export default Pending;