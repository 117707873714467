import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
//import Navbar from "../common/Navbar";
import { APIURL } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import LoadingOverlay from 'react-loading-overlay';
import BackButton from "../common/BackButton";
import PROCard from "./PROCard";
function PROList() {
  const [loading,setLoading]=useState(false);
  const [proList, setproList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
prolist();
  }, []);

  const prolist=()=>
  {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pro/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          
          const datas = res.data.pros;

          setFreeCount(res.data.free_user_count);
          setActiveCount(res.data.active_free_users);
          setLoading(false)
         
          setproList(datas);
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }

  const refreshHandle = () => {
    setRefresh(!refresh);
    prolist();
  };
 

  return (
   
   
    <div className="drlist-page">
    

<LoadingOverlay
    active={loading}
    spinner
    styles={{
      spinner: (base) => ({
        ...base,
        width: '50px',
        '& svg circle': {
          stroke: 'rgba(0, 0, 255, 0.5)',
          
        }
      }),
      overlay: (base) => ({
        ...base,
        background: '',
     position:"initial"
        
      })
    }}
   
  >
 
      <h2 className="title-of-page"> PRO List</h2>
      <div className="quick-links">
        <Link to="/add-pro">
          <button type="button" className="btn btn-primary btn-col dr-btn">
            {" "}
            Add PRO to List{" "}
          </button>
        </Link>
        {/* <Link to={{ pathname: "license-details", flag: "pro-page" }}> */}
          {/* <button
            type="button"
            style={{ marginLeft: "10px" }}
            className="btn btn-info dr-btn"
          >
            {" "}
            {t("View Licence Details")}
          </button>
        </Link> */}
      </div>

      {proList.length !== 0 ? (
        <div>
          <div className="drs-container">
            {proList.length > 0
            
              ? proList.map((data, index) => {
           
                  return (
                    <PROCard
                      key={index}
                      data={data}
                      // freeCount={freeCount}
                      // activeCount={activeCount}
                      handle={refreshHandle}
                    />
                
                  );
                })
              : null}
          </div>

          <div className="quick-links">
            <Link to="/add-pro">
              <button type="button" className="btn btn-primary btn-col dr-btn">
                {" "}
                 Add PRO to List{" "}
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <h5 style={{ color: "red", margin: "20px" }}>PRO List is empty </h5>
      )}

      {errorMsg === "error" ? (
        <h5 style={{ color: "red" }}>
          Error in data fetching.Please try again!
        </h5>
      ) : null}
    
    </LoadingOverlay>
    </div>
  );
}

export default PROList;
