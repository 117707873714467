import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';

function EditObservations(props) {

    const[data, setData]= useState(props.data);
     
    const alert = useAlert()    
    const [errormsg,setErrorMsg]=useState("")
    

    const [error,setError]=useState({
      observation_name:'',
        
       });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)





   
      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
      
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


   
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["observation_name"] || input["observation_name"]==="" ) {
      isValid = false;
      errors["observation_name"] = "Enter observation Name";
    }
    if (!input["observation_type"] || input["observation_type"]==="" ) {
      isValid = false;
      errors["observation_type"] = "Enter observation Type";
    }
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
    console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     let dataToSend={}
    if(props.data.observation_name===data.observation_name&&props.data.observation_type===data.observation_type){
      dataToSend = {
      observation_code : data.observation_code
    }
    }else if(props.data.observation_name===data.observation_name){
      dataToSend = {
                                 
      observation_type : data.observation_type,
      observation_code : data.observation_code
    }
    }else{
      dataToSend = {
      observation_name: data.observation_name,                               
      observation_type : data.observation_type,
      observation_code : data.observation_code  

    }}
 
     axios
       .put(`${APIURL}/api/v1/staff/master-observation-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Updated successfully!!'); 
          props.modalClose()
          setData({
            observation_name : '',
            observation_type : '',
            observation_code : ''
         });

         } else {
          alert.error('Observation already present')
         }
       })
       .catch((err) => {
         alert.error('Observation already present')
       });
      

    }

    else {
      
     
   }
   setErrorValidate(true)

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Edit Observations</h4>
        <div style={{ minHeight: 'fit-content', width:'100%' }} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField">
                  <div  style={{display:"flex"}}>
                    <label className='align-rental'>*Observation Name : </label>
                    <textarea type='text' className='form-control title-flex' name='observation_name' value={data.observation_name} onChange={handleTitle} /></div>
                    {error.observation_name ? <div className="error-validation-msg error-feature-inventory">{error.observation_name}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>*Observation Type : </label>
                    <input type='text' className='form-control title-flex' name='observation_type' value={data.observation_type} onChange={handleTitle} />
                    {error.observation_type ? <div className="error-validation-msg error-feature-inventory">{error.observation_type}</div> : null}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Observation Code : </label>
                    <input type='text' className='form-control title-flex' name='observation_code' value={data.observation_code} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
               
               
                
            </div>           
        </div>
        <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>   
        </div>
        </>
    )
}

export default EditObservations;
