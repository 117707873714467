import React, { useState } from 'react';
import "./doctor.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function Doctor({ data, freeCount, activeCount, handle }) {

  const [userId, setUserId] = useState('')
  const [confirmShow, setConfirmShow] = useState(false)
  const [successShow, setSuccessShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [removeConfirm, setRemoveConfirm] = useState(false)
  const [removeSuccess, setRemoveSuccess] = useState(false)
  const [removeError, setRemoveError] = useState(false)
  const [removeErrorMsg, setRemoveErrorMsg] = useState('')
  const [licRemove, setLicRemove] = useState(false)
  const [licSuccess, setLicSuccess] = useState(false)
  const [, setLicFail] = useState(false)
  const [licMsg, setLicMsg] = useState('')
  const alert = useAlert();
  const create_link = `/create-doctor/${data.id}`;

  const suspend_doctor = `/suspenddoctor/${data.id}`;
  const change_password_link = `/changepassword/${data.id}`;
  const edit_doctor = `/editdoctor/${data.id}`;
  const revoke_suspension_link = `revoke/${data.id}`;
  const delete_doctor = `/deletedoctor/${data.id}`;
  // const [deleteShow, setDeleteShow] = useState(false)
  // const [deleteIndex, setDeleteIndex] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [suspendShow, setSuspendShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [isSuspend, setIsSuspend] = useState(data.is_suspended);

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Are you sure to add this user as a Free User ?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onCall}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const SubmitPopUpFree = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'>Added User as a Free User</h4>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>{errorMsg}</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const RemovePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 className='text-danger'>Are you sure to remove this user from Free User ?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onCall}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const RemovalSuccess = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'>Removed User from Free User</h4>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const RemoveFail = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>{removeErrorMsg}</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const LicConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 className='text-danger'>Are you sure to remove this user from the Licence ?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onCall}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const LicSuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'>Removed User from Licence</h4>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const LicFailPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>{errorMsg}</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const handleUser = () => {

    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      is_free_user: true,
      user_id: userId
    }
    axios.post(`${APIURL}/api/v1/hospital/hospital-licenses/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {

          setSuccessShow(true)
        }
        else {
          setErrorMsg(res.data.message)
          setErrorShow(true)
        }
      })
      .catch((err) => {
        setErrorMsg('Error in data submission.Please check again!')
        setErrorShow(true)
      })
  }

  const handleRemove = () => {

    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      user_id: userId
    }
    axios.put(`${APIURL}/api/v1/hospital/hospital-licenses/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {

          setRemoveSuccess(true)
        }
        else {
          setRemoveError(true)
          setRemoveErrorMsg(res.data.message)
        }
      })
      .catch((err) => {
        setRemoveErrorMsg('Error in data submission.Please check again!')
        setRemoveError(true)
      })
  }

  const handleLicense = () => {

    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      user_id: userId
    }
    axios.put(`${APIURL}/api/v1/hospital/hospital-licenses/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {

          setLicSuccess(true)
        }
        else {
          setLicFail(true)
          setLicMsg(res.data.message)
        }
      })
      .catch((err) => {
        setLicMsg('Error in data submission.Please check again!')
        setLicFail(true)
      })
  }

  const SubmitPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success">Deleted Successfully</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  
  const handleDeletePopUp = (id) => {
    setDeleteIndex(id);
    setDeleteShow(true);
  };
  const handleSuspendPopUp = (id) => {
    setDeleteIndex(id);
    setSuspendShow(true);
    setIsSuspend(!isSuspend)
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Delete User </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to Delete the User ID {deleteIndex}
          </h5>
          <br />

          <div className="form-group "  >
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleDelete(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const SuspendConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> {data.is_suspended ? "Authorise" : "Suspend"} User </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to {data.is_suspended ? "Authorise" : "Suspend"} the User ID {deleteIndex}
          </h5>
          <br />

          <div className="form-group "  >
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleSuspend(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleSuspend = (e) => {
    //	e.preventDefault();

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );


    const data = {
      is_suspended: isSuspend
    }

    axios.put(`${APIURL}/api/v1/service-provider/doctors/${deleteIndex}/`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Token " + v2
      },
    })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success(`${isSuspend ? "Suspended" : "Authorised"} Successfully!!`);
          handle()


        } else {
          alert.error("Error occured. Try again")
        }
        setDeleteIndex("");
        setSuspendShow(false);
      })
      .catch((err) => {
        // setSubmitMsg("error");
      });

  };
  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");


    axios
      .post(
        `${APIURL}/api/v1/service-provider/hospital-user-delete/?user_id=${item}&role=doctor`,
        {},
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Deleted successfully")
          handle();
        } else {
          alert.error("Error occured. Try again!")
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
      });
  };

  return (
    <>
      <div className="doctor-card">

        <div className="card-header12" >
          <h6 className="dr-id"> ID : {data.id} </h6>
          <h6 style={{textTransform:"capitalize"}}> {data.name} </h6>
        </div><br/>


        <div className="card-content26">
          <h6>  <span className="key-labels"> Qualification </span> {" "}<br/><span>
            {data.qualification.map((item) => {
              return (

                <span style={{textTransform:"capitalize"}}>{item},</span>

              );
            })}</span></h6>
          <h6 ><span className="key-labels"> Username </span><br/><span >{data.user_name}</span></h6>
          <h6 ><span className="key-labels"> Specialization </span><br/> <span style={{textTransform:"capitalize"}}>{data.specializations}</span></h6>
          <h6 ><span className="key-labels"> Department </span> <br/><span style={{textTransform:"capitalize"}}>{data.department}</span> </h6><br />


          <h6>
            {data.is_app_user ?
              <div className="">
                <span style={{ color: "var(--green)" }} className="bold-class"> Valid User Credentials</span>

              </div>


              :

              <div className="">
                <span style={{ color: "var(--red)" }} className="bold-class"> No User Credentials </span>

              </div>}
          </h6>


          <h6>    <div className=""><span className="key-labels"> Status </span>: {data.is_suspended ?

            <span style={{ color: "var(--red)" }}> Suspended </span>


            :

            <span style={{ color: "var(--green)" }}> Authorized </span>


          } </div></h6>




          <div className="contact-div">
            <i className="fa fa-phone key-labels" aria-hidden="true"></i>{data.phone}<br />
            <i className="fa fa-envelope key-labels" aria-hidden="true"></i> {data.email}
           
          </div>
          <br />
         

          <div className="card-content-divider"></div>
          <div className="bottom-links">

            {data.is_suspended ? (
              <Link to="#"
                onClick={() => {
                  handleSuspendPopUp(data.id);
                }}

              > Authorize |</Link>
            ) : (
              <Link to=""
                onClick={() => {
                  handleSuspendPopUp(data.id);
                }}
                className="delete-warning"> Suspend |</Link>
            )}

            {data.is_app_user ?

              <Link to={change_password_link}>Change Password |</Link>
              :
              <span className="temp-disabled"> Change Password| </span>}

            {data.is_app_user ?
              <span className="temp-disabled"> Generate User Credentials|</span>
              :

              <Link to={create_link} state={{ name: data.name }}  > Generate User Credentials |</Link>}
            <Link to={edit_doctor}> Edit Details |</Link>
            <Link to=""
              onClick={() => {
                handleDeletePopUp(data.id);
              }}
              className="delete-warning"
            >
              Delete User{" "}
            </Link>


            {/* <Link onClick={() => { handleDeletePopUp(data.id) }} className="delete-warning"
            >Delete User </Link> */}

            {/*
             <div className="icons-bottom">
              <Link to={update_link}> <i className="fas fa-edit"></i></Link>
               <i className="far fa-trash-alt"></i>
             </div>

            */}
          </div>
        </div>


      </div>

      {
        confirmShow ?
          <ConfirmPopUp
            show={confirmShow}
            onCall={() => { handleUser(); setConfirmShow(false) }}
            onHide={() => setConfirmShow(false)} /> : null
      }

      {successShow ?

        <SubmitPopUpFree
          show={successShow}
          onHide={() => {
            setSuccessShow(false);
            handle();
          }}
        />
        : null}

      {errorMsg !== '' ?

        <ErrorPopUp
          show={errorShow}
          onHide={() => { setErrorShow(false) }}
        />
        : null}

      {
        removeConfirm ?
          <RemovePopUp
            show={removeConfirm}
            onCall={() => { handleRemove(); setRemoveConfirm(false) }}
            onHide={() => setRemoveConfirm(false)} /> : null
      }

      {removeSuccess ?

        <RemovalSuccess
          show={removeSuccess}
          onHide={() => {
            setRemoveSuccess(false);
            handle();
          }}
        />
        : null}

      {removeErrorMsg !== '' ?

        <RemoveFail
          show={removeError}
          onHide={() => { setRemoveError(false) }}
        />
        : null}

      {
        licRemove ?
          <LicConfirmPopUp
            show={licRemove}
            onCall={() => { handleLicense(); setLicRemove(false) }}
            onHide={() => setLicRemove(false)} /> : null
      }

      {licSuccess ?

        <LicSuccessPopUp
          show={licSuccess}
          onHide={() => {
            setLicSuccess(false);
            handle();
          }}
        />
        : null}

      {licMsg !== '' ?

        <LicFailPopUp
          show={licMsg}
          onHide={() => { setLicMsg(false) }}
        />
        : null}

      {deleteShow ? (
        <DeleteConfirmPopup
          show={deleteShow}
          onHide={() => {
            setDeleteShow(false);
            setDeleteIndex("");
            handle();
          }}
        />
      ) : (
        ""
      )}
      {suspendShow ? (
        <SuspendConfirmPopup
          show={suspendShow}
          onHide={() => {
            setSuspendShow(false);
            setDeleteIndex("");
            handle();
          }}
        />
      ) : (
        ""
      )}
     
    </>
  )
}

export default Doctor;