import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation, Navigate } from "react-router-dom";
import "./login.css";
import "./log.css";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { LoginContext } from "../contexts/LoginContext";
import { APIURL } from "../../Global";
import axios from "axios";
import background from "../../assets/images/bg.jpg";
import { HospLoginContext } from "../contexts/HospitalLoginContext";
import AuthUtil from "./AuthUtil";
import home from "../../assets/images/newww.png";
import LoadingOverlay from "react-loading-overlay";
// import orgData from "../../felixaconfig.json"
function LoginPage() {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [, setHospData] = useContext(HospLoginContext);
  //const [, setHospDocData] = useContext(HospDrLoginContext);
  const location = useLocation();
  const [, setLoginDatas] = useContext(LoginContext);
  const [, setIsLoggedIn] = useContext(LoginStatusContext);
  const [error, setError] = useState(false);
  const [serveCount, setServeCount] = useState("");
  const [aggregator, setAggregator] = useState('');
  const [orgImg, setOrgImg] = useState("")
  const [orgFlag, setOrgFlag] = useState(false)
const [loading,setLoading]=useState(false)
  const [regcount, setRegCount] = useState('');
 
  const [isUser,setIsUser]=useState(false)
  const inputRefs = useRef([])
  const loginButtonRef = useRef (null)
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {  
      e.preventDefault()
      if(index === 1 ) {
        loginButtonRef.current.click();
      }else{
      e.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
    }
  };
  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      const activeElement = document.activeElement;
      if (
        !inputRefs.current.includes(activeElement) &&
        activeElement !== loginButtonRef.current
      ) {
        loginButtonRef.current.click();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);  
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

  useEffect(() => {
    setLoginDatas(null);
    setIsLoggedIn(false);
  }, []);


  useEffect(() => {
    setLoading(true)
    axios.get(`${APIURL}/api/v1/account/customer-info/`,

    )
      .then(res => {
        if (res.data.status === "success") {
          setServeCount(Number(res.data['service provider count']))
          sessionStorage.setItem("project_type", res.data.project_type);
          setLoading(false)
          setAggregator(res.data.aggregation_type);
          setOrgImg(res.data.organization_logo)
          if (res.data.organization_logo !== APIURL + "/media/null" && res.data.organization_logo !== "") {
            setOrgFlag(true)
          }
          setRegCount(res.data.registered_sp_count)

        }
        else{
          setLoading(false)
        }
       
        
      })
      setLoading(false)
      if (sessionStorage.getItem("project_type")) {
      
      }
  }, []);

  const handleLogin = async (e) => {
   
    e.preventDefault();
    await apiCall();
  };

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "loginUsername") {
      setLoginUsername(val);
    }
    if (key === "loginPassword") {
      setLoginPassword(val);
    }
  };
  const togglePasswordVisiblity = (e) => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const apiCall = () => {
    let user=""
    axios
      .post(
        `${APIURL}/api/v1/account/login/`,
        {
          username: loginUsername,
          password: loginPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
  
      .then((res) => {
        if (res.data.status === "success") {
        
          user=res.data.user_type
         
          if (res.data.verification_status === "pending") {
            sessionStorage.setItem("hosp_data", JSON.stringify(res.data));
            sessionStorage.setItem("hospital_pending", "true");
            setHospData(res.data);
          }
          if(user==="config_admin"){
            setIsUser(true)
            console.log("user is true")
            
            sessionStorage.setItem("logindatas", JSON.stringify(res.data));
            sessionStorage.setItem("userid", res.data.user_id);
            sessionStorage.setItem("hospId", res.data.hospital_id);
            sessionStorage.setItem("role", res.data.role);
            sessionStorage.setItem("aggr_type", aggregator);
            setLoginDatas(res.data);
            /* Store Token */
            sessionStorage.setItem("usertoken", JSON.stringify(res.data.token));
    
            sessionStorage.setItem("usertype", res.data.user_type);
           
        }
          axios.get(`${APIURL}/api/v1/account/customer-info/`,

    )
      .then(respond => {
        if (respond.data.status === "success") {
          
          sessionStorage.setItem("project_type", respond.data.project_type);
        
          const filtered=respond.data.features.filter((item) => {
            return item.feature_name==="web_user_login";
          });
          const filtered1=respond.data.features.filter((item) => {
            return item.feature_name==="app_user_login";
          });
          console.log("filtered",filtered)
          sessionStorage.setItem("web_users",JSON.stringify(filtered[0].description))
          sessionStorage.setItem("app_users",JSON.stringify(filtered[0].description))
        
          if(filtered[0].description[user]==="True"){
            setIsUser(true)
            console.log("user is true")
            
            sessionStorage.setItem("logindatas", JSON.stringify(res.data));
            sessionStorage.setItem("userid", res.data.user_id);
            sessionStorage.setItem("hospId", res.data.hospital_id);
            sessionStorage.setItem("role", res.data.role);
            sessionStorage.setItem("aggr_type", aggregator);
            setLoginDatas(res.data);
            /* Store Token */
            sessionStorage.setItem("usertoken", JSON.stringify(res.data.token));
    
            sessionStorage.setItem("usertype", res.data.user_type);
           let arr=[]
           respond.data.features.length>0&&respond.data.features.map((i)=>{
            arr.push(i.feature_name)
         })
            sessionStorage.setItem("features", JSON.stringify(arr));
          }
          else{
            setLoginUsername("");
          setLoginPassword("");
            setError("Invalid User")
          }
        }})      
        } else {
          setLoginUsername("");
          setLoginPassword("");
          setError(res.data.message);
        }
      })
      .catch(() => {
        setLoginUsername("");
        setLoginPassword("");
        setError("There was some error in login");
      });
  };

  const authUrl = sessionStorage.getItem("usertoken")&&AuthUtil.validateAuth();
  if (authUrl&&isUser) {
    return <Navigate to={authUrl} state={{ from: location }} />;
  }

  return (
    <>
     <LoadingOverlay
            active={loading}
            
        >
      <div className="App">
        <div className="appAside">
          <article class="card1">
            <img src={orgFlag ? orgImg : home} class="card__img" />
            <div class="card__data">
              <h2 class="card__title"><b>Elder Care</b></h2>
              <p class="card__description">Remote Care Platform, It Is Easy And Quick...<br />
                Convenient Elder Care Technology Solution.</p>
              <a href=" " class="card__btn">Learn more</a>
            </div>
          </article>

        </div>
        <div className="appForm">
          <div className="formData">
            <div className="formdata1">
              <h2 className="name">Login</h2>
            </div>
            <div className="formFields">
              <div className="formField">
                <input
                  ref={el => inputRefs.current[0] = el} 
                  onKeyDown={(e) => handleKeyDown(e,0)}
                  type="text"
                  className="formFieldInput"
                  name="loginUsername"
                  placeholder={"Username/E-mail Address"}
                  value={loginUsername}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="formField">
                <div
                  className=""
                  style={{ backgroundColor: "#e8f0fe", position: 'relative', borderRadius: 20 }}
                >
                  <input
                    ref={el => inputRefs.current[1] = el} 
                    onKeyDown={(e) => handleKeyDown(e,1)}
                    style={{ backgroundColor: "#e8f0fe" }}
                    type={passwordType}
                    className="formFieldInput"
                    value={loginPassword}
                    name="loginPassword"
                    placeholder={"Password"}
                    onChange={handleChange}
                    required
                  />
<span style={{ float: "right", top: 10, right: 10, position: 'absolute', color: 'black' }}>
                    {/* <button className="btn btn-outline-primary1" onClick={togglePasswordVisiblity}> */}
                    {passwordType === "password" ? (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    )}
                    {/* </button> */}
</span>
                </div>
              </div>

              <div className="forgot-password">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>

              <div className="formField formButtonLayout">
                <button ref={loginButtonRef} className="formFieldButton" onClick={handleLogin} style={{ cursor: "pointer !important" }}>
                  Sign In
                </button>


                {regcount < serveCount ?
                  <Link
                    to="/signup"
                    className="formFieldButton"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    Create an account{" "}
                  </Link> :
                  <Link
                    to="/ErrorNotification"
                    className="formFieldButton"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    Create an account{" "}
                  </Link>}
              </div>
              <div className="formField">
                {error ? (
                  <div>
                    <p className="login-error" style={{ color: "red" }}>
                      {"Error"}:{" "}
                      {error === "Invalid credentials"
                        ? "Invalid credentials"
                        : error === "There was some error in login"
                          ? "There was some error in login"
                          : error}{" "}
                      <br />
                      {"Please try again!"}
                    </p>{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerdata1" />
      </LoadingOverlay>
    </>
  );
}

export default LoginPage;
