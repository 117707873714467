import React, { useState, useEffect, useRef } from 'react'
import { DatePicker } from 'rsuite';
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import moment from "moment";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import isBefore from 'date-fns/isBefore';
import { useAlert } from "react-alert";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
function VolunteerRegistration() {
    const alert = useAlert()
    const navigate = useNavigate();
    const [volunteerInfo, setVolunteerInfo] = useState({})
    const [phone, setPhone] = useState("")
    const [skills, setSkills] = useState([])
    const [skillsArr, setSkillsArr] = useState([])
    const [skills1, setSkills1] = useState({})
    const [languges, setLanguges] = useState([])
    const [language, setLanguage] = useState({ name: "", read: false, write: false, speak: false })
    const [fileName, setFileName] = useState("Upload Photo");
    const [available, setAvailable] = useState("")
    const [errors, setErrors] = useState({})
    const [refrsh,setRefresh]=useState(false)
    const inputRefs = useRef([])
  const submitButtonRef = useRef (null)
  const handleKeyDown = (e, index) => {
    console.log("function" + e.key)
    if (e.key === "Enter") {  
    console.log("function enter")
      e.preventDefault()

      if(index === 16 ) {
        submitButtonRef.current.click();
        console.log("on click")
      }else{
      e.preventDefault();    
      console.log("Index:", index);
      console.log("Length:", inputRefs.current.length);
      const nextIndex = index + 1;
      console.log("Next index:", nextIndex);
      console.log("Next input field:", inputRefs.current[nextIndex]);
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        // move focus to the next input if it exists
        inputRefs.current[nextIndex].focus();
      }
    }
    }
  };
  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
    console.log("useeffect enter")

      const activeElement = document.activeElement;
      if (
        !inputRefs.current.includes(activeElement) &&
        activeElement !== submitButtonRef.current
      ) {
    console.log("useeffect on save")

        submitButtonRef.current.click();
      }
    }else if (e.altKey && e.key === 's') {
    console.log("useeffect alt and s")

      submitButtonRef.current.click(); 
    }else if (e.altKey && e.key === 'c') {
        navigate("/login");
      }
  };

  useEffect(() => {
    console.log("useeffect")
    document.addEventListener("keydown", handleKey);
  
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

    const handleTitle = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;
       
        setErrors(currentstate => ({
            ...currentstate,
            [key]: ""
        }));
        if (key === "no_of_kids") {
            if (val < 1) {
                setVolunteerInfo(current => ({
                    ...current, [key]: ""
                }))
            }
            else {
                setVolunteerInfo(current => ({
                    ...current, [key]: val
                }))
            }
        }
        
        else {
            setVolunteerInfo(current => ({
                ...current, [key]:key==="occupation"? val.toLowerCase():val
            }))
        }
    
}
    const validate = async () => {
        let input = volunteerInfo
        let errors = {};
        let isValid = true;
        setErrors({});
    
        if (!input["first_name"] || input["first_name"] === "") {
          isValid = false;
          errors["first_name"] = "Enter first name";
        }
        if (languges.length===0) {
            isValid = false;
            errors["language"] = "Select Language";
          }
          if (skillsArr.length===0) {
            isValid = false;
            errors["skills"] = "Select skills";
          }
       
       
        if (!input["last_name"] || input["last_name"] === "") {
          isValid = false;
          errors["last_name"] = "Enter last name";
        }
    
        if (!input["state"] || input["state"] === "") {
          isValid = false;
          errors["state"] = "Select a region";
        }
        if (!input["available_from"] || input["available_from"] === ""||available==="") {
            isValid = false;
            errors["available_from"] = "Select available date range";
          }
        if (!input["location"] || input["location"] === "") {
            isValid = false;
            errors["location"] = "Enter location";
          }
        if (input["email"] &&input["email"]!=="") {
          var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );
          if (!pattern.test(input["email"])) {
            isValid = false;
            errors["email"] = "Enter a valid email address.";
          }
        }
    
        if (typeof input["first_name"] !== "undefined") {
          var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
          if (pattern2.test(input["firstname"])) {
            isValid = false;
            errors["first_name"] = "Special Characters are not allowed";
          }
        }
    
        if (typeof input["last_name"] !== "undefined") {
          var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
          if (pattern3.test(input["lastname"])) {
            isValid = false;
            errors["last_name"] = "Special Characters are not allowed";
          }
        }
    
        if (
          !volunteerInfo.mobile_number ||
          volunteerInfo.mobile_number === "" 
        
        ) {
          isValid = false;
          errors["mobile_number"] = "Enter phone number ";
        }
    
        if (input["dob"] === "" ||!input["dob"] ) {
          isValid = false;
          errors["dob"] = "Enter your date of birth";
        }
        if (
          typeof volunteerInfo.gender == "undefined" ||
          volunteerInfo.gender === "" ||
          !volunteerInfo.gender 
        ) {
          isValid = false;
          errors["gender"] = "Select gender";
        }
    
    
    
        setErrors((currentstate) => ({
          ...currentstate,
          ...errors,
        }));
    
        return isValid;
      };
    
    const handleLanguage = (e) => {
        setErrors(currentstate => ({
            ...currentstate,
            language: ""
        }));
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        setLanguage(currentstate => ({
            ...currentstate,
            [key]: val
        }));

    }
    useEffect(() => {

        axios
            .get(`${APIURL}/api/v1/sda/job-skills/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "success") {
                    if (res.data.skills.length > 0) {
                        setSkills(res.data.skills)
                        res.data.skills.map((item) => {
                            setSkills1({ [item]: false })
                        })
                    }
                }
                else {

                }
            }).catch((error) => {

            })



    }, [refrsh])
    const handleContinue = async () => {
        if (
          (await validate()) 
          
        ) {
          submit();
        }
      };
    const submit = () => {
        
            const formData = new FormData();


            if (languges.length > 0) {
                languges.map((item, index) => {
                    formData.append(`languages[${index}]name`, item.name);
                    formData.append(`languages[${index}]speak`, item.speak === true ? "yes" : "no");
                    formData.append(`languages[${index}]read`, item.read === true ? "yes" : "no");
                    formData.append(`languages[${index}]write`, item.write === true ? "yes" : "no");
                })
            }
            formData.append(`available_period`, available);
            if (skillsArr.length > 0) {
                skillsArr.map((item, index) => {
                    formData.append(`job_skill[${index}]`, item);
                })
            }
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            formData.append(`country`, "India");
            Object.keys(volunteerInfo).forEach(key => {
                formData.append(key, volunteerInfo[key]);
            });
            axios.post(`${APIURL}/api/v1/sda/volunteer-registration/?timeZone=${currentTimezone} `, formData, {
                headers: {


                }
            })
                .then(res => {


                    if (res.data.status === "success") {
                        if (res.data.message === "Successfully Created") {
                            alert.success(res.data.message)
                            navigate("/login");

                        }
                        else {
                            alert.error(res.data.message)
                        }


                    } else {
                        setSkills([])
                         setRefresh(!refrsh)
                        alert.error(res.data.message)

                    }

                })
        
    }
    const handlePhoneInput = (value, data, event, formattedValue) => {
        setErrors(currentstate => ({
            ...currentstate,
            mobile_number: ""
        }));
        const rawPhone = value.slice(data.dialCode.length);
        const dialcode = value.slice(0, data.dialCode.length);
        if (value.length === 12) {
            setPhone(value)
            setVolunteerInfo(currentstate => ({
                ...currentstate,
                mobile_number: rawPhone,
                code: dialcode

            }));

        }
        else{
            setPhone("")
            setVolunteerInfo(currentstate => ({
                ...currentstate,
                mobile_number:"",
                code: "91"

            }));

        }

    }
    const validatePhoneInput = (value,) => {
        if (value.length !== 2) {
            if (value.length !== 12) {
                return "Invalid phone number";

            }
            else {
                return true
            }
        }

    }
    const handleGenderRadioButton = (e) => {
        setErrors(currentstate => ({
            ...currentstate,
           gender: ""
        }));
        let genderOpted = e.target.value;
        setVolunteerInfo(currentstate => ({
            ...currentstate,
            gender: genderOpted

        }))
    }
    const handleRadioButton = (e) => {
        setErrors(currentstate => ({
            ...currentstate,
            available_from: ""
        }));
        let val = e.target.value;
        setAvailable(val)
    }
    const handleLanguageRadioButton = (e) => {
        setErrors(currentstate => ({
            ...currentstate,
            language: ""
        }));
        let val = e.target.value;
        if (language[val] === true) {
            setLanguage(currentstate => ({
                ...currentstate,
                [val]: false

            }))
        }
        else {
            setLanguage(currentstate => ({
                ...currentstate,
                [val]: true

            }))
        }
    }
    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }
    const handleDate = (date) => {
        setErrors(currentstate => ({
            ...currentstate,
            dob: ""
        }));
        // setDate(date)
        const a = moment(date).format('YYYY-MM-DD');

        setVolunteerInfo(currentstate => ({
            ...currentstate,
            dob: a
        }));

    }
    const selectRegion = (val) => {
        setErrors(currentstate => ({
            ...currentstate,
            state: ""
        }));
        // let value1=e.target.value;
        setVolunteerInfo(currentstate => ({
            ...currentstate,
            state: val
        }));
    }
    const disabledDate = (date) => {
        let hr = new Date().getHours()
        let mn = new Date().getMinutes()
        const currentDate = moment().set({ hours: hr, minute: mn, second: 59 });
        return currentDate.isBefore(date);
    };
    const reader = new FileReader();
    const handleFile = e => {

        const file = e.target.files[0];

        if (!file) {
            setVolunteerInfo(currentstate => ({ ...currentstate, photo: null }))
            alert.error("Not a file")
            setFileName('Upload Photo');
            return false
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
            setVolunteerInfo(currentstate => ({ ...currentstate, photo: null }))
            alert.error("Not a valid image")
            setFileName('Upload Photo');
            return false;
        }
        if (file.size > 10e6) {
            setVolunteerInfo(currentstate => ({ ...currentstate, photo: null }))
            // setSizeShow(!sizeShow)
            setFileName('Upload Photo');
            return false
        }
        reader.onload = (evt) => {
            const img = new Image();
            img.onload = () => {
                setVolunteerInfo(currentstate => ({ ...currentstate, photo: file }))
                setFileName("Photo Uploaded");
            };

            img.onerror = () => {
                setVolunteerInfo(currentstate => ({ ...currentstate, photo: null }))
                // setContentShow(!contentShow)
                setFileName('Upload Photo');

                return false;
            };
            img.src = evt.target.result;
        };
        reader.readAsDataURL(file);


    };
    useEffect(() => {
        let updatedArray = [];
        for (var key in skills1) {
            if (skills1[key] === true) {
                updatedArray.push(key);
            }
        }

        setSkillsArr(updatedArray);
    }, [skills1])
    const handleFactors = (e) => {
        setErrors(currentstate => ({
            ...currentstate,
           skills: ""
        }));
        const key = e.target.name;

        setSkills1((currentstate) => ({
            ...currentstate,
            [key]: !skills1[key],
        })
        );

    };
    const handleEvent1 = (event, picker) => {
        setErrors(currentstate => ({
            ...currentstate,
            available_from: ""
        }));
        const a = moment(event[0]).format('YYYY-MM-DD HH:mm:ss');
        let b = moment(event[1]).format('YYYY-MM-DD HH:mm:ss');
        setVolunteerInfo(currentstate => ({
            ...currentstate,
            available_from: a,
            available_to: b

        }));
    }
    const handleDeleteMember = (indexToDelete) => {
        const updatedMembers = languges.filter(
            (_, index) => index !== indexToDelete
        );
        setLanguges(updatedMembers);
    };
    return (
        <div className='container' style={{ marginTop: "1%", marginBottom: "1%" }}>
            <div className='enquirycontainer' style={{ paddingTop: "", paddingLeft: "2%", paddingRight: "2%", paddingBottom: "2%" }}>
                <h5 className="enquiryheader">Volunteer registration</h5>
                <div><br />
                    <h6 className='enquiryheader' > Personal Information</h6>
                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'><label className='text-style-here ' >*First Name</label><br />
                        <input ref={el => inputRefs.current[0] = el} onKeyDown={(e) => handleKeyDown(e,0)}name="first_name" value={volunteerInfo.first_name} onChange={handleTitle} className='enquiryinput' />
                        {errors.first_name ? (
                <div className="error-validation-msg">{errors.first_name}</div>
              ) : null}
                        </div>
                    <div className='col-6'><label className='text-style-here ' >*Last Name </label><br />
                        <input ref={el => inputRefs.current[1] = el} onKeyDown={(e) => handleKeyDown(e,1)}name="last_name" value={volunteerInfo.last_name}
                            onChange={handleTitle} className='enquiryinput' />
                            {errors.last_name ? (
                <div className="error-validation-msg">{errors.last_name}</div>
              ) : null}</div>

                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'><label className='text-style-here ' >*DOB</label><br />
                        <DatePicker
                            style={{ border: "none" }}
                            className="enquiryinput deathtime"
                            appearance="default"
                            format="yyyy-MM-dd"
                            onChange={date => { handleDate(date) }}
                            disabledDate={disabledDate}
                            onClear={() => { }}


                        />
                        {errors.dob ? (
                <div className="error-validation-msg">{errors.dob}</div>
              ) : null}</div>
                    <div className='col-6'><label className='text-style-here ' >*Gender </label><br />
                        <div style={{ paddingTop: "1.5%", textAlign: "center" }} className='enquiryinput' >&nbsp; &nbsp; <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={volunteerInfo.gender === "male"}
                            onClick={handleGenderRadioButton}
                        />

                            <label htmlFor="male">Male</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="female"
                                checked={volunteerInfo.gender === "female"}
                                onClick={handleGenderRadioButton}
                            />

                            <label htmlFor="female">Female</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="others"
                                name="gender"
                                value="others"
                                checked={volunteerInfo.gender === "others"}
                                onClick={handleGenderRadioButton}

                            />
                            <label htmlFor="others">Others</label>
                        </div>
                        {errors.gender ? (
                <div className="error-validation-msg">{errors.gender}</div>
              ) : null}
                    </div>

                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'><label className='text-style-here ' >Address Line1</label><br />
                        <input ref={el => inputRefs.current[2] = el} onKeyDown={(e) => handleKeyDown(e,2)} name="city" placeholder='Address' value={volunteerInfo.city} onChange={handleTitle} className='enquiryinput' /></div>
                    <div className='col-6'><label className='text-style-here ' >*Address Line2 </label><br />
                        <input ref={el => inputRefs.current[3] = el} onKeyDown={(e) => handleKeyDown(e,3)} placeholder='Location' name="location" value={volunteerInfo.location}
                            onChange={handleTitle} className='enquiryinput' />
                            {errors.location ? (
                <div className="error-validation-msg">{errors.location}</div>
              ) : null}</div>

                </div>


                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'><label className='text-style-here ' >Country</label><br />

                        <input className='enquiryinput' value={"India"}
                            disabled type="text" /></div>
                    <div className='col-6 row'> <div className='col-6' >
                        <span className="text-style-here">*Region</span>
                        <RegionDropdown
                            className='enquiryinput'
                            country={"India"}
                            value={volunteerInfo.state}
                            onChange={(val) => selectRegion(val)} />
                            {errors.state ? (
                <div className="error-validation-msg">{errors.state}</div>
              ) : null}
                    </div>
                        <div className='col-6' >
                            <span className="text-style-here">Pincode</span>
                            <input ref={el => inputRefs.current[4] = el} onKeyDown={(e) => handleKeyDown(e,4)} className='enquiryinput' type="text" name="pincode" value={volunteerInfo.pincode} onChange={handleTitle} /></div>



                    </div>

                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'>
                        <label className='text-style-here ' >*Phone</label><br />
                        <PhoneInput
                            className='enquiryinput'
                            inputProps={{
                                ref: el => inputRefs.current[5] = el, // Assuming inputRefs is a useRef initialized as useRef([])
                                tabIndex: 0,
                                onKeyDown: e => handleKeyDown(e, 5),
                              }}
                            country={'in'}
                            onlyCountries={['in']}
                            countryCodeEditable={false}
                            value={phone!==""?phone:"91"}
                            onChange={handlePhoneInput}
                            isValid={validatePhoneInput}

                        />
                        {errors.mobile_number ? (
                <div className="error-validation-msg">{errors.mobile_number}</div>
              ) : null}
                    </div>
                    <div className='col-6'>
                        <label className='text-style-here ' >Email </label><br />
                        <input ref={el => inputRefs.current[6] = el} onKeyDown={(e) => handleKeyDown(e,6)} name="email" value={volunteerInfo.email}
                            onChange={handleTitle} className='enquiryinput' />
                            {errors.email? (
                <div className="error-validation-msg">{errors.email}</div>
              ) : null}
                    </div>
                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                <div className='col-6' >
                            <span className="text-style-here">Occupation</span>
                            <input ref={el => inputRefs.current[7] = el} onKeyDown={(e) => handleKeyDown(e,7)} className='enquiryinput' type="text" name="occupation" value={volunteerInfo.occupation} onChange={handleTitle} /></div>



                    

                    <div className='col-6' >
                    <span className="text-style-here">Photo</span>
                        <label  style={{ backgroundColor: "white", border: "1px dashed #104173", height: "40px", width: "98%", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center", gap: "8px", padding: "0 12px", cursor: "pointer" }}>
                            <input type="file" style={{ display: "none" }}
                                onChange={handleFile}
                            />
                            {!volunteerInfo.photo ? <i className="fa fa-cloud-upload" aria-hidden="true"></i> : <i style={{ color: "blue" }} className="fa fa-file-image-o" aria-hidden="true"></i>}
                            {fileName}
                        </label>

                    </div>

                </div>
                <div><br />
                    <h6 className='enquiryheader' > Family Information</h6>
                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-10 row'>
                        <div className='col-4'>
                            <label className='text-style-here ' >Father Name</label><br />
                            <input ref={el => inputRefs.current[8] = el} onKeyDown={(e) => handleKeyDown(e,8)}name="father_name" value={volunteerInfo.father_name} onChange={handleTitle} className='enquiryinput' />
                        </div>
                        <div className='col-4'>
                            <label className='text-style-here ' >Mother Name</label><br />
                            <input ref={el => inputRefs.current[9] = el} onKeyDown={(e) => handleKeyDown(e,9)} name="mother_name" value={volunteerInfo.mother_name} onChange={handleTitle} className='enquiryinput' />
                        </div>
                        <div className='col-4'>
                            <label className='text-style-here ' >Spouse Name</label><br />
                            <input ref={el => inputRefs.current[10] = el} onKeyDown={(e) => handleKeyDown(e,10)}name="spouse_name" value={volunteerInfo.spouse_name} onChange={handleTitle} className='enquiryinput' />
                        </div>
                    </div>
                    <div className='col-2'>
                        <label className='text-style-here ' >Number of Kids</label><br />
                        <input ref={el => inputRefs.current[11] = el} onKeyDown={(e) => handleKeyDown(e,11)} type="number" name="no_of_kids" min={1} value={volunteerInfo.no_of_kids} onChange={handleTitle} className='enquiryinput' />
                    </div>
                </div>
                <div><br />
                    <h6 className='enquiryheader' > Education Qualification</h6>
                </div>
                <div style={{ marginTop: "2%" }}>
                    <label className='text-style-here ' >Graduation</label><br />
                    <input ref={el => inputRefs.current[12] = el} onKeyDown={(e) => handleKeyDown(e,12)}type="text" name="graduation" value={volunteerInfo.graduation} onChange={handleTitle} className='enquiryinput' />
                </div>
                <div style={{ marginTop: "1%" }}>
                    <label className='text-style-here ' >Post Graduation</label><br />
                    <input ref={el => inputRefs.current[13] = el} onKeyDown={(e) => handleKeyDown(e,13)}type="text" name="post_graduation" value={volunteerInfo.post_graduation} onChange={handleTitle} className='enquiryinput' />
                </div>
                <div style={{ marginTop: "1%" }}>
                    <label className='text-style-here ' >Any other</label><br />
                    <input ref={el => inputRefs.current[14] = el} onKeyDown={(e) => handleKeyDown(e,14)} type="text" name="other_education" value={volunteerInfo.other_education} onChange={handleTitle} className='enquiryinput' />
                </div>
                <div><br />
                    <h6 className='enquiryheader' > Previous Experience </h6>
                </div>
                <div style={{ marginTop: "1%" }}>
                    <label className='text-style-here ' >Organisation and Period of employment</label><br />
                    <textarea ref={el => inputRefs.current[15] = el} onKeyDown={(e) => handleKeyDown(e,15)} maxLength="300" name="experience" value={volunteerInfo.experience}
                        onChange={handleTitle} className='enquiryinputtext' type="text" />
                </div>
                <div><br />
                    <h6 className='enquiryheader' >*Job Skills </h6>
                </div>
                
                <div style={{ height: "auto", padding: "1%" }} className='enquiryinput row'>
                    {skills.map((item) => {
                        return (
                            <div className='col-3' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id={item}
                                    name={item}
                                    checked={skills1.item}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>{item}</label></>
                            </div>
                        )
                    })}
                    
                </div>
                {errors.skills ? (
                <div className="error-validation-msg">{errors.skills}</div>
              ) : null}
                <div><br />
                    <h6 className='enquiryheader' > *Language Proficiency </h6>
                </div>
                <div className='row'>
                    <div className='col-6 focused-select' >

                        <select
                            ref={el => inputRefs.current[16] = el} 
                            onKeyDown ={(e) =>handleKeyDown(e,16)}
                            style={{ backgroundColor: "white", paddingLeft: "1%" }}
                            className="enquiryinput"
                            value={language.name}
                            name="name"
                            onChange={handleLanguage}
                            placeholder='Select'
                        >
                            <option hidden value="" ></option>
                            <option value="English" >English</option>
                            <option value="Malayalam" >Malayalam</option>
                            <option value="Hindi" >Hindi</option>
                            <option value="Tamil" >Tamil</option>
                            <option value="Kannada" >Kannada</option>

                        </select>
                        {errors.language ? (
                <div className="error-validation-msg">{errors.language}</div>
              ) : null}
                    </div>
                    <div className='col-5'>
                        <div style={{ paddingTop: "1.5%", textAlign: "center" }} className='enquiryinput' >&nbsp; &nbsp;
                            <input
                                disabled={language.name === ""}
                                type="checkbox"
                                id="speak"
                                name="language"
                                value="speak"
                                checked={language.speak === true}
                                onClick={handleLanguageRadioButton}
                            />

                            <label htmlFor="speak">Speak</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                disabled={language.name === ""}
                                type="checkbox"
                                id="read"
                                name="language"
                                value="read"
                                checked={language.read === true}
                                onClick={handleLanguageRadioButton}

                            />
                            <label htmlFor="read">Read</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                disabled={language.name === ""}
                                type="checkbox"
                                id="write"
                                name="language"
                                value="write"
                                checked={language.write === true}
                                onClick={handleLanguageRadioButton}
                            />

                            <label htmlFor="write">Write</label>


                        </div>
                        
                    </div>
                    <div className='col-1' style={{ textAlign: "center", paddingTop: "0.2%", color: "#245693" }}>
                        <i onClick={() => {
                             let arr=[]
                             languges.length>0&&languges.map((i)=>{
                                arr.push(i.name)
                             })
                            if (language.name) {
                                if(language.read===false&&language.speak===false&&language.write===false){
                                    alert.error("please choose the Proficiency")
                                }
                                
                                else if(!arr.includes(language.name)){
                                setLanguges([...languges, language]);

                                setLanguage({
                                    name: "",
                                    speak: false,
                                    read: false,
                                    write: false
                                });}
                            }
                        }} class="fa  fa-plus-square fa-2x  " aria-hidden="true"></i>
                    </div>
                    {languges.length > 0 && <div className='' style={{ textAlign: "center", marginTop: "2%" }}>
                        {languges.map((item, index) => {
                            return (
                                <div className='row'>
                                    <div className='col-3'></div>
                                    <h6 style={{ textAlign: "left" }} className='enquiryheader col-2' ><i class="fa fa-language" aria-hidden="true"></i>&nbsp; {item.name} </h6>
                                    <h6 style={{ color: item.speak === false && "#8080805c" }} className=' col-1' >&nbsp;<b>Speak</b>  </h6>
                                    <h6 style={{ color: item.read === false && "#8080805c" }} className=' col-1' >&nbsp; <b>Read </b></h6>
                                    <h6 style={{ color: item.write === false && "#8080805c" }} className=' col-1' >&nbsp; <b>Write</b> </h6>
                                    <h6 className=' col-1' >  <i onClick={() => handleDeleteMember(index)} style={{ color: "red" }} class="fa fa-times" aria-hidden="true"></i></h6>

                                </div>

                            )
                        })}
                    </div>}

                </div>
                <div><br />
                    <h6 className='enquiryheader' > *Availability </h6>
                </div>

                <div className=' enquiryinput row' style={{ padding: "0.5%", height: "auto", }} >
                    <div className='col-3'></div>

                    <DateRangePicker
                        className='col-4'
                        appearance="default"
                        style={{ width: "320px" }}
                        placeholder={"Select Date Range"}
                        onChange={handleEvent1}
                        disabledDate={(date) =>
                            isBefore(date, new Date())}

                    >
                    </DateRangePicker>
                    <div className='col-5' style={{ paddingTop: "0.5%" }} >&nbsp; &nbsp; <input
                        type="radio"
                        id="all_days"
                        name="available"
                        value="all_days"
                        checked={available === "all_days"}
                        onClick={handleRadioButton}
                    />

                        <label htmlFor="all_days">All Days</label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <input
                            type="radio"
                            id="weekdays"
                            name="available"
                            value="weekdays"
                            checked={available === "weekdays"}
                            onClick={handleRadioButton}
                        />

                        <label htmlFor="weekdays">Week Days</label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <input
                            type="radio"
                            id="weekends"
                            name="available"
                            value="weekends"
                            checked={available === "weekends"}
                            onClick={handleRadioButton}

                        />
                        <label htmlFor="weekends">Weekends</label>
                    </div>
                   
                </div>
                {errors.available_from ? (
                <div className="error-validation-msg">{errors.available_from}</div>
              ) : null}
                <div className='' style={{ padding: "2%", textAlign:"center" }}>
                    <button ref={submitButtonRef} style={{ backgroundColor: "#9069A5", color: "white", height: "40px", border: "none", width: "18%", borderRadius: "10px" }} onClick={() => { handleContinue() }}>Submit</button>
                </div>

            </div>

        </div>
    )
}
export default VolunteerRegistration;