import React, {useEffect, useState, useContext} from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
//import { BookingContext } from '../../contexts/BookingContext';
import BookingPayment from "./BookingPayment";
function ViewBooking(props) {
  
    const [bookingData, setBookingData]= useState([])
    const [successShow, setSuccessShow]= useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [paymentShow, setPaymentShow]= useState(false)
    const [refresh,setRefresh]= useState(false)
    const [bookingData1, setBookingData1] = useState([]);

    useEffect(()=> {
        const id =props.id

        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
      
        axios
          .get(`${APIURL}/api/v1/patient/appointments/booking/?patient_id=${id}&src=web`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
      
            if (res.data.status === "success") {
                setBookingData(res.data.appointments)
            }
            else{
                setErrorShow(true)
            }
          })
          .catch((err) => {
            setErrorShow(true)
          });

    },[refresh])

    const InvoicePopUpPayment = (props) => {
      // const consultData = config.find(item => { return item.name === 'Consultation' })
  
      return (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <BookingPayment
              invoiceData={bookingData1}
              patient_id = {props.id}
              dataHandlePayment={datahandle}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };


    const datahandle=()=>{
      setPaymentShow(false)
     setRefresh(!refresh)
      
    }
    const handlePayment =(item)=>{
      setBookingData1(item)
      setPaymentShow(true)
    }

    const handleCancel = (bookId) => {

      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");

      const data = {
        patient_id: props.id,
        booking_id: bookId,
        
      }
    
      axios
        .put(`${APIURL}/api/v1/patient/appointments/cancel/`, data , {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
    
          if (res.data.status === "success") {
              setSuccessShow(true)
          }
          else{
              setErrorShow(true)
          }
        })
        .catch((err) => {
          setErrorShow(true)
        });

    }

    const SuccessPopUp = (props)=> {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
    
            <h4 className='text-success'>Appointment Cancelled Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }

    const ErrorPopup =(props)=>{
    
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}>Error in Fetching Data. Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }


    const bookingTiles = bookingData.length > 0 ? bookingData.map(item => {
        return(
            <div key={item.id} className="booking-tile" >
                <div className="booking-header">
                    <h5><i className="far fa-calendar-alt fa-sm"></i>{" "}{item.date}</h5>
                </div>
                <div className="booking-body">
                    <i className="fas fa-user-md"></i>
                    <span>{item.doctor_name}</span>
                    <i className="fas fa-address-card"></i>
                    <span>Token:{item.token}</span>
                    <i className="fas fa-users"></i>
                    <span>{item.slot}</span>
                    <i className="far fa-clock"></i>
                    <span>{item.arrival_time}</span>
                    <i class="fa fa-credit-card" aria-hidden="true"></i>
                    <span>{item.payment_status==="success" ? "not_paid" :item.payment_status==="payment_complete" ? "Paid" :""} </span>
                 
                </div>
                
                {item.payment_status === "success" ? (
      <div className="buttons">
        <span onClick={() => handlePayment(item)} className="cancel-booking">
          Payment
        </span>
        <span onClick={() => handleCancel(item.id)} className="cancel-booking">
          Cancel
        </span>
      </div>
    ) : null}
            </div> 
        )
    }) : errorShow ? <h4 className='text-danger'>Error in Fetching data ! Please try again.</h4>
       : <h4 className='text-muted'>No Bookings available</h4>

    return (
        <>
        { bookingData.length > 0 ? 
          <h3 className='title-of-tasks'>Bookings of <span className='past-payment-name'>{props.name}</span></h3>
          : null
        }
          
            <div className='flex-row booking-container' style={{height:"20%"}}>
                {bookingTiles}

        </div>

        {
        successShow ? 
        <SuccessPopUp 
            show={successShow}
            onHide= {() => {setSuccessShow(false); setRefresh(!refresh)}}/> : null
    }

        {errorShow ?
    <ErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);}}
       
      />: ''
    }

{paymentShow ? (
        <InvoicePopUpPayment
          show={paymentShow}
          onHide={() => {
            setPaymentShow(false);

           
            //setRerender(!reRender)
          }}
        />
      ) : null}
        </>

    )
}

export default ViewBooking
