import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
import moment from "moment";

function ManageScan(props) {
    const [n, setn] = useState(3)
    const [scanErr, setScanErr] = useState("");
    const [doctorName, setDoctorName] = useState("");
    const [permissionScan, setPermissionScan] = useState(false);
    const [submitTrigger, setSubmitTrigger] = useState(true);
    const [, setCurrentColumn] = useState("");
    const [currentIndex, setCurrentIndex] = useState("");
    const [deleteScanConfirmMsg, setDeleteScanConfirmMsg] = useState("");
    const [updatedScanList, setUpdatedScanList] = useState([]);
    const [deleteScanConfirmModalShow, setDeleteScanConfirmModalShow] =
        useState(false);
    const [scanSuggest, setScanSuggest] = useState();
    const alert = useAlert();
    const [searchScan, setSearchScan] = useState("");
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
        ? JSON.parse(sessionStorage.getItem("logindatas"))
        : null;
    const [scanListCounter, setScanListCounter] = useState(0);
    const [scanRefresh, setScanRefresh] = useState(false);
    const [scanDate, setScanDate] = useState();
    let [s, setS] = useState(0);
    const [addscan, setAddscan] = useState(true);
    const [scanIndexToDelete, setScanIndexToDelete] = useState();
    const [display2, setDisplay2] = useState(false);
    const [render, setRender] = useState(false);
    const [dateVal, setDateVal] = useState("");
    const [scanList, setScanList] = useState([
        {
            id: "",
            scan_name: "",
            date: "",
            scan_details: "",
            created_by: "",
            hospital_name: ""
        },
    ]);
    const delConfirmRef = useRef(null)
      useEffect(() => {
        const handleKey = (e) => {
    
          if (e.altKey && e.key === 's') {
            e.preventDefault()
            delConfirmRef.current.click()
          }else if (e.key === 'Enter') {
            e.preventDefault()
            delConfirmRef.current.click()
          }else if (e.altKey && e.key === 'c') {
            e.preventDefault()
            setDeleteScanConfirmModalShow(false);
            
          }
        
        }
        document.addEventListener("keydown", handleKey);
        
        return () => {
          document.removeEventListener("keydown", handleKey);
        }
        
      }, []);
    useEffect(() => { if (scanList.length > 2) { setn(scanList.length+1) } }, [scanList])
    useEffect(() => {

        ScanData();
    }, [scanRefresh]);
    const ScanData = async () => {
        if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
            setScanList([
                {
                    id: "",
                    scan_name: "",
                    date: "",
                    scan_details: "",
                },
            ]);
            setScanListCounter(0);
            const tokenString = sessionStorage.getItem("usertoken");

            let str = tokenString.replace(/["]+/g, "");
            await axios
                .get(`${APIURL}/api/v1/doctor/scans-list/?patient_id=${props.id}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setS(res.data.message.length);
                        if (res.data.message.length > 0) {
                            setScanListCounter(res.data.message.length);

                            setScanList(res.data.message);
                            const today = new Date();
                            const offset = today.getTimezoneOffset();
                            let dateUpdated = new Date(today.getTime() - offset);
                            const m = moment(dateUpdated, "YYYY-MM-DD");

                            for (let index = 0; index < res.data.message.length; index++) {
                                let ot = moment.utc(res.data.message[index].date).toDate();
                                var ms = moment(ot, "YYYY-MM-DD");
                                let timediff = moment
                                    .duration(
                                        moment(ms, "YYYY-MM-DD").diff(moment(m, "YYYY-MM-DD"))
                                    )
                                    .asDays();
                                if (timediff >= 0) {
                                    setScanDate(res.data.message[index].date);
                                    break;
                                } else {
                                    setScanDate("");
                                }
                            }
                        }
                    }
                });
        }
    };
    useEffect(() => {

        if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
            const tokenString = sessionStorage.getItem("usertoken");

            let str = tokenString.replace(/["]+/g, "");

            axios
                .get(
                    `${APIURL}/api/v1/doctor/scans/?patient_id=${props.id}&search=${searchScan}`,
                    {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    }
                )
                .then((res) => {
                    if (res.status == 200) {
                        setScanSuggest(res.data.message);
                    }
                });
        }
    }, [searchScan]);
    const deleteRowScan = (index) => {
        let newlist = [];
        if (index > scanListCounter - 1) {
            newlist = scanList.filter((scan, i) => {
                return i !== index;
            });

            setScanList(newlist);
            setAddscan(true);
            if (newlist.length <= 0) {
                setScanList([
                    {
                        id: "",
                        scan_name: "",
                        date: "",
                        scan_details: "",
                    },

                    // setScanDate(""),
                ]);
            }
        } else {
            handleDeleteRowScan(index);
        }
    };
    const handleDeleteRowScan = (index) => {
        setDeleteScanConfirmMsg("success");
        setDeleteScanConfirmModalShow("true");
        setScanIndexToDelete(index);

    };
    const renderEditableScan = (cellInfo) => {
        if (cellInfo.index > scanListCounter - 1 || scanListCounter === 0) {
            return (
                <div>
                    <input
                        style={{ backgroundColor: "#fafafa" }}
                        value={scanList[cellInfo.index][cellInfo.column.id]}
                        className="form-control"
                        name="scan_name"
                        onChange={(e) => {
                            setAddscan(false);
                            e.preventDefault();
                            setCurrentIndex(cellInfo.index);
                            setCurrentColumn(cellInfo.column.id);
                            setDisplay2(true);
                            setSearchScan(e.target.value);
                            const scans = scanList;
                            scans[cellInfo.index].scan_name = e.target.value;
                            setScanList(scans);
                            if (e.target.value === "") {
                                setDisplay2(false);
                            }
                        }}
                        autoComplete="off"
                    />

                    {currentIndex === cellInfo.index &&
                        cellInfo.column.id === "scan_name" &&
                        display2 && (
                            <div className="suggestions-box">
                                {scanSuggest.map((item, i) => {
                                    return (
                                        <div
                                            className="scan-item"
                                            key={i}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRender(!render);
                                                const scans = scanList;
                                                scans[cellInfo.index].scan_name = item.scan_name;
                                                scans[cellInfo.index].id = item.id;
                                                setScanList(scans);
                                                setDisplay2(false);
                                            }}
                                        >
                                            <p className="search-item">{item.scan_name}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{ backgroundColor: "#fafafa" }}
                        className="form-control scan-name-col"
                        name="scan"
                        value={scanList[cellInfo.index].scan}
                        onBlur={(e) => {
                            const scans = scanList;
                            scans[cellInfo.index].scan = e.target.innerHTML;

                            setScanList(scans);
                        }}
                        autoComplete="off"
                        dangerouslySetInnerHTML={{
                            __html: scanList[cellInfo.index][cellInfo.column.id],
                        }}
                    />
                </div>
            );
        }
    };
    const handleDeleteScan = (index) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const ts_id = scanList[index].id;
        axios
            .delete(`${APIURL}/api/v1/doctor/scans-details/${ts_id}`, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            })

            .then((res) => {
                if (res.data.status == "success") {
                    alert.success(res.data.message)
                    setScanRefresh(!scanRefresh);
                    setAddscan(true);
                    setScanDate("");
                } else {
                    setAddscan(true);
                }
            })
            .catch((err) => {

                setAddscan(true);
            });
    };
    const DeleteScanConfirmPopup = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 id="confirm-reject">Please confirm to delete Scheduled Scan</h4>
                </Modal.Body>
                <Modal.Footer>
                   

                    <Button
                        variant="danger"
                        ref={delConfirmRef}
                        onClick={() => {
                            setDeleteScanConfirmModalShow(false);
                            handleDeleteScan(props.index);
                            setScanIndexToDelete(null);
                        }}
                    >
                        {" "}
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const handleUpdateScan = (i) => {
        if(scanList[i].date===""){
            alert.error("scan date is mandatory")
        }
        else{
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        const fd = new FormData();

        fd.append(`scan`, scanList[i].scan);

        fd.append(`date`, scanList[i].date);

        fd.append(`scan_details    `, scanList[i].scan_details);

        axios
            .put(`${APIURL}/api/v1/doctor/scans-details/${scanList[i].id}/`, fd, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success("Successfully Updated");
                    setAddscan(true);
                    setScanRefresh(!scanRefresh)

                } else {
                    alert.error("error");
                }
            })
            .catch((err) => {
                alert.error("error");
            });
        }
    };
    const openEditScan = (index) => {
        handleUpdateScan(index);
    };
    useEffect(() => {
        if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
            if (permissionScan === true) {
                const tokenString = sessionStorage.getItem("usertoken");
                const userid = sessionStorage.getItem("userid");
                let str = tokenString.replace(/["]+/g, "");
                if (scanErr === "nil") {

                const scans1 = updatedScanList;
                let values1 = [];

                for (let i = s; i < scans1.length; i++) {
                    values1.push({
                        patient: props.id,
                        doctor: userid,
                        scan_details: scans1[i].scan_details,
                        date: scans1[i].date,
                        scan: scans1[i].id,
                    });
                }
                const scans = {
                    scans: values1,
                };
                axios
                    .post(`${APIURL}/api/v1/doctor/scans-list/`, scans, {
                        headers: {
                            Authorization: "Token " + str,
                            "Content-Type": "application/json",
                        },
                    })

                    .then((res) => {
                        if (res.data.status === "success") {
                            setPermissionScan(false);
                           
                            setAddscan(true);
                
                            
                            setDoctorName(login_datas.doctor_name);
                           
                            
                            setScanRefresh(!scanRefresh);
                           
                            alert.success("successfully submitted")
                        } else {
                            alert.error(res.data.message);
                           
                            setAddscan(true);
                            setPermissionScan(false);
                        }
                    })
                    .catch((err) => {
                     
                    });
            }
        }
    }

    }, [scanErr !== "nil",submitTrigger]);
    const handleSubmitScan = () => {
        setScanErr("nil");
        s = scanList.length;
        let isValid = true;
        const updatedScans = scanList.filter((row, i) => {
            return row.scan !== "" && row.date !== "";
        });

        const scanData = scanList.filter((row, i) => {
            return row.scan !== "" || row.date !== "";
        });

        scanData.map((item) => {
            if (item.scan_name === "") {
                alert.error("*Scan name in Scan Schedules table is mandatory!");
                setScanErr("error");
                isValid = false;
            } else if (item.id === "") {
                alert.error("*Select Scan name from the dropdown!");
                setScanErr("error");
                isValid = false;
            } else if (item.date === "") {
                alert.error("*Date in Scan Schedules Table is mandatory!");
                setScanErr("error");
                isValid = false;
            }
            else if(item.scan_name!== ""||item.id !== ""||item.date !== "") {
                setUpdatedScanList(updatedScans);
                setSubmitTrigger(!submitTrigger);
                setPermissionScan(true);
            }

        });
    };
    const renderEditScan = (cellInfo) => {
        if (cellInfo.index > scanListCounter - 1 || scanListCounter === 0) {
            return (
                <button
                    className="btn btn-primary btn-col btn-square btn-smallest"
                    onClick={handleSubmitScan}
                >
                    Save
                </button>
            );
        } else if (scanList[cellInfo.index].isEdit) {
            return (
                <button
                    className="btn btn-primary btn-col btn-square btn-smallest"
                    onClick={() => {
                        openEditScan(cellInfo.index);
                    }}
                >
                    Save
                </button>
            );
        } else {
            return (
                <i
                    style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                    onClick={() => {
                        let procedures = scanList;
                        procedures.forEach((procedure) => {
                            procedure.isEdit = false;
                        });
                        scanList[cellInfo.index].isEdit = true;
                        setScanList(procedures);
                        setRender(!render);
                    }}
                    className="fas fa-edit"
                    aria-hidden="true"
                ></i>
            );
        }
    };
    const renderEditableScanDate = (cellInfo) => {
        if (
            cellInfo.index > scanListCounter - 1 ||
            scanList[cellInfo.index].isEdit
        ) {
            const today = new Date();

            const offset = today.getTimezoneOffset();

            let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

            let DateExtracted = dateUpdated.toISOString().split("T")[0];
            return (
                <div>
                    <input
                        type="date"
                        data-date={dateVal}
                        className="scan-date-col date-custom-format"
                        min={DateExtracted}
                        onChange={(e) => {
                            e.preventDefault();

                            let date = e.target.value;
                            let y = []
                            let x = scanList.filter(x => {
                                return x.scan_name === scanList[cellInfo.index].scan_name
                            })
                            console.log(x)
                            if (x.length > 0) {
                                y = x.filter(x => {
                                    return x.date === date
                                })
                                console.log(y)
                            }
                            if (y.length > 0) {
                                const scans = scanList;
                                scans[cellInfo.index].date = "";
                                alert.error("already exist")
                                const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

                                setDateVal(m);
                                return
                            }
                            else {
                                const scans = scanList;
                                scans[cellInfo.index].date = date;

                                setScanList(scans);
                                const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

                                setDateVal(m);
                            }
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{ backgroundColor: "#fafafa" }}
                        className="form-control scan-date-col"
                        name="date"
                        value={scanList[cellInfo.index].date}
                        onBlur={(e) => {
                            const scans = scanList;
                            scans[cellInfo.index].date = e.target.innerHTML;

                            setScanList(scans);
                        }}
                        autoComplete="off"
                        dangerouslySetInnerHTML={{
                            __html: scanList[cellInfo.index][cellInfo.column.id],
                        }}
                    />
                </div>
            );
        }
    };
    const renderAddedByScan = (cellInfo) => {
        const createdBy = scanList[cellInfo.index].created_by;
        const hospitalName = scanList[cellInfo.index].hospital_name;

        const value = `${createdBy}-${hospitalName}`;

        return (
            scanList[cellInfo.index].created_by && scanList[cellInfo.index].hospital_name &&
            <input
                style={{ backgroundColor: "#fafafa", whiteSpace: "pre-line" }}
                className="form-control scan-name-col"
                readOnly
                value={value}
                cols="100"
                rows="2"
            />
        );
    };
    const renderDetails = (cellInfo) => {
        if (
            cellInfo.index > scanListCounter - 1 ||
            scanList[cellInfo.index].isEdit
        ) {
            let data = "";
            if (scanList[cellInfo.index] && scanList[cellInfo.index].scan_details) {
                data = scanList[cellInfo.index].scan_details;
            }
            return (
                <div>
                    <textarea
                        className="text-area"
                        placeholder="Add Scan Details"
                        cols="100"
                        rows="2"
                        defaultValue={data}
                        onChange={(e) => {
                            e.preventDefault();
                            data = e.target.value;

                            const scans = scanList;
                            scanList[cellInfo.index].scan_details = data;

                            setScanList(scans);
                        }}
                    />
                </div>
            );
        } else {
            return (
                <textarea
                    className="scan-text"
                    value={scanList[cellInfo.index].scan_details}
                    cols="100"
                    rows="2"
                />
            );
        }
    };
    const AddRowScan = () => {
        setAddscan(false);
        const list = scanList;
        list.push({
            id: "",
            scan_name: "",
            date: "",
            scan_details: " ",
        });
        setScanList(list);
        setDateVal('')
        setRender(true);
    };
    const columnsScans = [
        {
            Header: <i className="fa fa-trash"></i>,
            Cell: (props) => {
                return (
                    <i
                        style={{ marginLeft: "30%" }}
                        className="fa fa-trash trash-btn"
                        onClick={() => {
                            deleteRowScan(props.index, props);
                        }}
                    ></i>
                );
            },
            width: 50,
            sortable: false,
            filterable: false,
        },
        {
            Header: "Scan Name",
            accessor: "scan_name",
            Cell: renderEditableScan,
            style: {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
            width: 250,

            sortable: false,
        },
        {
            Header: "Date (YYYY-MM-DD)",
            accessor: "date",
            Cell: renderEditableScanDate,
            sortable: false,
        },

        {
            Header: "Scan Details",
            accessor: "scan_details",
            Cell: renderDetails,
            sortable: false,
            filterable: false,
        },

        {
            Header: "Save/Edit",
            Cell: renderEditScan,
            sortable: false,
            filterable: false,
        },
        {
            Header: "Added By",

            Cell: renderAddedByScan,
            width: 200,
            sortable: false,
            filterable: false,
        },

        {
            Header: "Add New",
            Cell: (props) => {
                return props.index === scanList.length - 1
                    ? scanList[props.index].id
                        ? addscan && (
                            <button
                                className="btn btn-primary btn-col"
                                onClick={AddRowScan}
                            >
                                {" "}
                                +
                            </button>
                        )
                        : null
                    : null;
            },

            sortable: false,
            filterable: false,
        },
    ];
    return (
        <div>
            <ReactTable
                columns={columnsScans}
                data={scanList}
                pageSize={n}
      showPagination={false}
                style={{ color: "black" }}
                className="observation-table"
            ></ReactTable>
            
            {deleteScanConfirmMsg === "success" ? (
                      <DeleteScanConfirmPopup
                        show={deleteScanConfirmModalShow}
                        onHide={() => setDeleteScanConfirmModalShow(false)}
                        index={scanIndexToDelete}
                      />
                    ) : (
                      ""
                    )}
        </div>
    )
}
export default ManageScan