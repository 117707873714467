import React, { useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import 'react-table-v6/react-table.css';
import moment from "moment";
import "./wound.css"
import PatientSearch from '../../common/PatientSearch';
import General from './General';
import Assessment from './Assessment';
import Actions from './Actions';
import Management from './Management';
function WoundDoc() {
    const location = useLocation();
    let navigate = useNavigate();
    const [patient, setPatient] = useState({ 
        id: location.state ? location.state.id : "",
        full_name: location.state ? location.state.name : "",
    room:location.state ? location.state.room :"",
mobile_number:location.state ? location.state.mobile_number:""})
    const [selected, setSelected] = useState("general")
     const[woundId,setWoundID]=useState("")
    // const [refresh,setRefresh]=useState(false)
    const [general,setGeneral]=useState({})
    // useEffect(() => {
    //     if(patient.id){
    //     const tokenString= sessionStorage.getItem('usertoken');
       
    
    //     let str= tokenString.replace(/["]+/g, '');
    //      axios.get(`${APIURL}/api/v1/service-provider/wound-info/?timeZone=Asia/Kolkata&patient_id=${patient.id}`, 
    //      { headers: {
    //        'Content-Type': 'application/json',
    //        "Authorization" : 'Token '+str  }})
    //         .then(res=>{
        
    //        if(res.data.status==="success"){
    //  if(general.id){
    //         const filteredData = res.data.details.filter((data) => data.id===general.id);
    //         setWoundInfo(filteredData[0])
    //         // setProcedure(res.data.procedure_details)
    // }
    //         }
    //      })
       
    //     }
    // },[refresh])
    return (
        <div className='' style={{ paddingTop: "2%", paddingBottom: "2%" ,paddingRight:"2%"}}>
          
            <div className='row'>
                <div className='col-3'style={{paddingLeft:"2%",paddingRight:"2%"}}>
                <h5 className="enquiryheader">{selected==="management"?"Wound Management":"Wound Documentation Chart"}</h5>
                    <div className='wound_leftdiv'>

                        <div onClick={() => ""} className={selected === "general" ? "selected_wound" : "not_selected_wound"}>
                            <div className="svg-div"><svg style={{ color: "rgba(16, 65, 115, 1)" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7zm12-4h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 0 0-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55c.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75s-.75-.34-.75-.75s.34-.75.75-.75zM19 19H5V5h14v14z" /></svg>
                            </div> &nbsp;&nbsp;<h6 style={{paddingTop:"3%", color: selected === "general" ? "white" : "rgba(134, 134, 134, 1)" }}>General</h6>
                        </div><br />
                        <div onClick={() => ""} className={selected === "assessment" ? "selected_wound" : "not_selected_wound"}>
                        <div className="svg-div"><svg style={{ color:  "rgba(16, 65, 115, 1)" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" /></svg>
                            </div>&nbsp;&nbsp;<h6 style={{ paddingTop:"3%",color: selected === "assessment" ? "white" : "rgba(134, 134, 134, 1)" }}>Assessment</h6>
                        </div><br />
                        <div onClick={() => ""} className={selected === "action" ? "selected_wound" : "not_selected_wound"}>
                        <div className="svg-div"><svg style={{ color:"rgba(16, 65, 115, 1)" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15.78 15.84S18.64 13 19.61 12c3.07-3 1.54-9.18 1.54-9.18S15 1.29 12 4.36C9.66 6.64 8.14 8.22 8.14 8.22S4.3 7.42 2 9.72L14.25 22c2.3-2.33 1.53-6.16 1.53-6.16zm-1.5-9a2 2 0 0 1 2.83 0a2 2 0 1 1-2.83 0zM3 21a7.81 7.81 0 0 0 5-2l-3-3c-2 1-2 5-2 5z" /></svg>
                            </div>&nbsp;&nbsp;<h6 style={{paddingTop:"3%", color: selected === "action" ? "white" : "rgba(134, 134, 134, 1)" }}>Action</h6>
                        </div>
                        <div style={{border:" 1px solid rgba(14, 57, 122, 1)", margin:"1%"}}></div>
                        <div onClick={() =>""} className={selected === "management" ? "selected_wound" : "not_selected_wound"}>
                        <div className="svg-div"><svg style={{  color:  "rgba(16, 65, 115, 1)" }}xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3.4 22L2 20.6l2.2-2.2l-2.2-.6l.525-1.925l2.175.6l-.575-2.2l1.925-.525l.6 2.2l3.425-3.425l-2.2-.6L8.4 10l2.175.575L10 8.4l1.925-.525l.6 2.2L15.95 6.65l-2.2-.6l.525-1.925l2.2.575l-.6-2.175L17.8 2l.6 2.2L20.6 2L22 3.4l-2.2 2.2l2.2.6l-.525 1.925l-2.175-.6l.575 2.2l-1.925.525l-.6-2.2l-3.425 3.425l2.2.6L15.6 14l-2.175-.575L14 15.6l-1.925.525l-.6-2.2L8.05 17.35l2.2.6l-.525 1.925l-2.2-.575l.6 2.175L6.2 22l-.6-2.2L3.4 22Z"/></svg>
                         </div>&nbsp;&nbsp;<h6 style={{paddingTop:"3%", color: selected === "management" ? "white" : "rgba(134, 134, 134, 1)" }}>Wound Report</h6>
                        </div>
                       
                    </div>
                    
                </div>

                <div className='col-9' style={{paddingTop:"1%"}}>
              
            {selected==="general"&&!location.state&&<PatientSearch onSuccess={(data)=>{setPatient(data)}}  />}<br/>

                   {patient.id&& <div className='row' style={{ marginTop: "1%" }}>
                        <div className='col-2'></div>
                        <div className='col-2'><label className='text-style-here ' ><i style={{color:"Highlight"}} class="fa fa-user" aria-hidden="true"></i></label>&nbsp;
                            <label  className='' style={{ backgroundColor: "white",fontWeight:"600", cursor: "default" ,textTransform:"capitalize"}} >{patient.full_name}</label></div>&nbsp;&nbsp;&nbsp;
                        <div className='col-2'><label className='text-style-here ' ><i style={{color:"Highlight"}}class="fa fa-id-card" aria-hidden="true"></i></label>&nbsp;
                        <label  className='' style={{ backgroundColor: "white", cursor: "default",fontWeight:"600" }} >{patient.id}</label></div>&nbsp;&nbsp;&nbsp;
                           
                        <div className='col-2'><label className='text-style-here '><i style={{color:"Highlight"}}class="fa fa-bed" aria-hidden="true"></i></label>&nbsp;
                        <label  className='' style={{ backgroundColor: "white", cursor: "default",fontWeight:"600" }} >{patient.room}</label></div>&nbsp;&nbsp;&nbsp;
                        <div className='col-2'><label className='text-style-here '><i style={{color:"Highlight"}}class="fa fa-mobile" aria-hidden="true"></i></label>&nbsp;
                        <label  className='' style={{ backgroundColor: "white", cursor: "default",fontWeight:"600" }} >{patient.mobile_number!==null&&patient.mobile_number!=="null"?patient.mobile_number:""}</label></div>&nbsp;&nbsp;&nbsp;
                     
                    </div>}
                    <div >
                        {selected==="general"?<General patientId={patient.id}  onSuccess={(data) =>{setGeneral(data);setSelected("assessment")}}/>
                        :selected==="assessment"?<Assessment  onSuccess={(data)=>{setGeneral(currentstate => ({
                            ...currentstate,
                           ...data}));setSelected("action")}}/>
                        :selected==="action"?<Actions  general={general}  patientId={patient.id} onSuccess={(data)=>{setWoundID(data); navigate("/wound_report", {
                            state: { name: patient.full_name, id: patient.id, age: patient.age, gender: patient.gender,woundId:data,room:patient.room,mobile_number:patient.mobile_number },
                          })}}/>
                        :""}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WoundDoc
