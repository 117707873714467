import React, { useState, useCallback, useEffect } from "react";
//import {useHistory} from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-date-picker";
import peace from "../../../assets/images/icons/peacevillage.png";
import axios from "axios";
import Select from "react-select";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
//import VerifyOTPPatient from "../BDO/VerifyOTPPatient";
//import PaymentRegistration from "../BDO/PaymentRegistration";
//import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar';
import "./enquiry.css";
import "react-table-v6/react-table.css";
//import GeneratePatientInvoice from './GeneratePatientInvoice';
import moment from "moment";

function EnquiryData() {
  const [eqDate, seteqDate] = useState("")
  const [eqnum, setEqnum] = useState("")
  const [otherRec, setOtherRec] = useState("")
  const [isValidEq, setIsValidEq] = useState(true);
  const location = useLocation();
  const pat_id = location.state ? location.state.itemId : "";
  const [useMalayalamInput1, setUseMalayalamInput1] = useState(false); // Initial state is set to false
  let navigate = useNavigate();
  const [userDatas, setUserDatas] = useState({
    firstname: "",
    lastname: "",
    email: "",
    age: "",
    consent: "",
    gender: "",
    dob: "",
    country: "India",
    state: "",
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
    identitycard: { value: "", label: "" },
    pincode: "",
    identitynumber: "",
    province: "",
    city: "",
    location: "",
    report: "",

    own_landDetails: "",
    successordetails: "",
    finincome: "",
    post_caredetails: "",
    bystandername: "",
    bystanderconatct: "",
  });
  const [member, setMember] = useState({});
  const [members, setMembers] = useState([]);
  const [refer, setrefer] = useState({});
  const [isValidIdentity, setIsValidIdentity] = useState(true);
  const [identityNumber, setIdentityNumber] = useState("");
  const [identityCard, setIdentityCard] = useState("")
  // const[referer,setReferer]=useState({value:'',lable:''})
  const [allRefer, setAllRefer] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const alert = useAlert();
  const [dob, setDob] = useState();
  const [, setFormattedDob] = useState("");
  const [checkDataArray, setCheckDataArray] = useState([]);
  const [checkDataArray1, setCheckDataArray1] = useState([]);
  const [checkData, setCheckData] = useState({
    self_sufficient: false,
    consciousness: false,
    attention: false,
    memory: false,
    spatial_perception: false,
    talkative: false,
    handicapped: false,
    sight_issue: false,
    hear_issue: false,
    heterosexual: false,
    other: false,
  });
  const [checkData1, setCheckData1] = useState({
    smoking: false,
    alcohol: false,
    drug_addict: false,
    betel_use: false,
    tobacco: false,
  });
  const [fileName1, setFileName1] = useState("Upload Report");
  const [isIdentityNumberDisabled, setIdentityNumberDisabled] = useState(false);
  const [fileName, setFileName] = useState("Upload Consent form");
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  const [error, setError] = useState({
    username: "",

    phone: "",
  });

  const identityoptions = [
    { value: '', label: 'Select type' },
    { value: "pan", label: "PAN" },
    { value: "passport", label: "PASSPORT" },
    { value: "aadhar", label: "AADHAR" },
    { value: "driving_license", label: "DRIVING LICENCE" },
    { value: "ration_card", label: "RATION CARD" },
    { value: "voter_id", label: "VOTER ID" },
  ];

  const recommendoptions = [
    { value: "village_officer", label: "Village Officer" },
    { value: "ward_member", label: "Ward Member" },
    { value: "panchayat_member", label: "Panchayath Member" },
    { value: "mayor", label: "Mayor" },
    { value: "advocate", label: "Advocate" },
    { value: "police", label: "Police" },
    { value: "mla", label: "MLA" },
    { value: "mp", label: "MP" },
    { value: "others", label: "Others" },
  ];

  const validatePhoneInput = (value,) => {
    if (value.length !== 2) {
      if (value.length !== 12) {
        return "Invalid phone number";

      }
      else {
        return true
      }
    }

  }


  const handleChange = (e) => {
    e.preventDefault();
    const val = trimspace(e.target.value);
    const key = e.target.name;

    if (key === "pincode") {
      if (val < 1) {
        setUserDatas((currentstate) => ({
          ...currentstate,
          [key]: "",
        }));
      } else {
        setUserDatas((currentstate) => ({
          ...currentstate,
          [key]: val,
        }));
      }
    } else {
      setUserDatas((currentstate) => ({
        ...currentstate,
        [key]: val,
      }));
    }

  };
  const handleChangeidnum = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      identitynumber: "",
    }));
    setIsValidIdentity(true)
    e.preventDefault();
    setError((currentstate) => ({
      ...currentstate,
      identitynumber: "",
    }));
    setIsValidIdentity(true)
    const x = e.target.value
    const val = x.trim()
    const key = e.target.name;

    setUserDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
    if (
      identityCard !== null &&
      identityNumber !== "" &&
      identityCard === userDatas.identitycard.value &&
      identityNumber === val
    ) {

      setError((currentstate) => ({
        ...currentstate,
        identitynumber: "",
      }));
      setIsValidIdentity(true)

      // No need to call handleCheckExistingIdentity
    } else {
      const data = {
        idcard_no: val,
        idcard_type: userDatas.identitycard.value,
      };
      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                identitynumber: `This identity number is already registered!`,
              }));
              setIsValidIdentity(false);
            } else {
              setIsValidIdentity(true);
            }
          } else {
            setIsValidIdentity(true);
          }
        })
        .catch((err) => { });
    }
  }

  const handleChangeenqnum = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      enquiry_number: "",
    }));
    setIsValidEq(true)
    const x = e.target.value
    const val = x.trim()
    const key = e.target.name;

    setUserDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
    if (eqnum.toLowerCase() === (e.target.value).toLowerCase()) {
      setError((currentstate) => ({
        ...currentstate,
        enquiry_number: "",
      }));
      setIsValidEq(true)
    }
    else {
      e.preventDefault();


      const data = {
        pv_enquiry_number: val

      };

      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                enquiry_number: res.data.message,
              }));
              setIsValidEq(false);
            } else {
              setIsValidEq(true);
            }
          } else {
            setIsValidEq(true);
          }
        })
        .catch((err) => { });

    }
  };
  const handleDeleteMember = (indexToDelete) => {
    const updatedMembers = members.filter(
      (_, index) => index !== indexToDelete
    );
    setMembers(updatedMembers);
  };

  const handleDeleteRecommender = (indexToDelete) => {
    const updatedMembers = allRefer.filter(
      (_, index) => index !== indexToDelete
    );
    setAllRefer(updatedMembers);
  };

  const handleRefer = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setrefer((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };
  const handlemember = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    if (key === "age") {
      if (val < 1) {
        setMember((currentstate) => ({
          ...currentstate,
          [key]: "",
        }));
      } else {
        setMember((currentstate) => ({
          ...currentstate,
          [key]: val,
        }));
      }
    } else {
      setMember((currentstate) => ({
        ...currentstate,
        [key]: val,
      }));
    }
  };

  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios

      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${pat_id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const dob1 = res.data[0].dob
          setEqnum(res.data[0].pv_enquiry_number)
          seteqDate(res.data[0].enquiry_date !== null ? new Date(res.data[0].enquiry_date) : "")
          // res.data[0].dob !== "" ? new Date(res.data[0].dob)  : null;
          setUserDatas({
            pe_date: new Date(res.data[0].pe_date),
            id: res.data[0].id,
            firstname: res.data[0].first_name,
            lastname: res.data[0].last_name,
            email: res.data[0].email !== null ? res.data[0].email : "",
            enquiry_report: "",
            gender: res.data[0].gender,
            dob: dob1 !== null ? dob1 : "",
            age: dob1 !== null ? (new Date().getFullYear() - new Date(dob1).getFullYear()) : "",
            country: res.data[0].country,
            state: res.data[0].state,
            phone: {
              dialcode:
                res.data[0].country_code !== null
                  ? res.data[0].country_code
                  : "",
              rawphone: "",
              mobile_number:
                res.data[0].country_code !== null &&
                  res.data[0].mobile_number !== null
                  ? res.data[0].mobile_number.startsWith(res.data[0].country_code)
                    ? res.data[0].mobile_number
                    : res.data[0].country_code + res.data[0].mobile_number
                  : // Check if mobile_number doesn't start with "91" (country code)
                  res.data[0].mobile_number !== null &&
                    !res.data[0].mobile_number.startsWith("91")
                    ? "91" + res.data[0].mobile_number
                    : // Handle the case where mobile_number is null
                    res.data[0].mobile_number !== null
                      ? res.data[0].mobile_number
                      : "91",

            },
            identitycard: {
              value: res.data[0].idcard_type,
              label: res.data[0].idcard_type,
            },
            pincode: res.data[0].pincode,
            pv_enquiry_number: res.data[0].pv_enquiry_number !== null ? res.data[0].pv_enquiry_number : "",
            identitynumber: res.data[0].idcard_no,
            province: res.data[0].province,
            city: res.data[0].city,
            location: res.data[0].location,
            parent: res.data[0].parent_name,
            marital_status: res.data[0].marital_status,
            is_home: res.data[0].is_home,
            is_aliveparent: res.data[0].is_aliveparent,
            own_land: res.data[0].own_land !== null && res.data[0].own_land !== "no" ? true : false,
            own_landDetails: res.data[0].own_land === "yes" || res.data[0].own_land === "no" || res.data[0].own_land === null
              ? ""
              : res.data[0].own_land,


            own_property: res.data[0].own_property !== null && res.data[0].own_property !== "no" ? true : false,
            successordetails:
              res.data[0].own_property === "yes" || res.data[0].own_property === "no" || res.data[0].own_property === null
                ? ""
                : res.data[0].own_property,
            post_care: res.data[0].post_care !== null && res.data[0].post_care !== "no" ? true : false,
            post_caredetails:
              res.data[0].post_care === "yes" || res.data[0].post_care === "no" || res.data[0].post_care === null
                ? ""
                : res.data[0].post_care,
            own_rationcard: res.data[0].own_rationcard,
            own_income: res.data[0].own_income !== null && res.data[0].own_income !== "no" ? true : false,
            finincome:
              res.data[0].own_income === "yes" || res.data[0].own_income === "no" || res.data[0].own_income === null
                ? ""
                : res.data[0].own_income,
            physical_support: res.data[0].physical_support,
            police_case: res.data[0].police_case,
            undergoing_treatment: res.data[0].undergoing_treatment,
            treatment_place: res.data[0].treatment_place,
            care_details: res.data[0].care_details,
            autobiography: res.data[0].autobiography,
            investigator: res.data[0].investigator,
            report: res.data[0].field_report,
            bystandername: res.data[0].bystander_name,
            bystanderconatct:
              res.data[0].bystander_contact !== null && res.data[0].bystander_contact !== "undefined"
                ? res.data[0].bystander_contact
                : "91",
          });
          console.log(userDatas.own_land)
          // Check if both identity card and identity number are present
          if (
            res.data[0].idcard_type !== null &&
            res.data[0].idcard_no !== ""
          ) {
            setIdentityCard(res.data[0].idcard_type,)
            setIdentityNumber(res.data[0].idcard_no,)
            // Both fields are present, disable the input
            // setIdentityNumberDisabled(true);
          } else {
            // At least one field is missing, enable the input
            // setIdentityNumberDisabled(false);
          }
          const responseData = res.data[0];
          //  setFileName(responseData.consent_form)
          // const shortFileName = responseData.consent_form.length > 10 ? responseData.consent_form.slice(0, 10) + '...' : responseData.consent_form;
          //   setFileName(shortFileName);


          setFileName1("Upload report");

          const relativesString = responseData.relatives || "[]";
          const relatives = JSON.parse(relativesString);

          // Update the state with the parsed relatives
          setMembers(relatives);
          const recommenderString = responseData.govt_member || "[]";
          const relatives1 = JSON.parse(recommenderString);

          // Update the state with the parsed relatives
          setAllRefer(relatives1);
          // Parse qualities from the response and split them into an array
          const qualities = responseData.qualities
            ? responseData.qualities.split(",")
            : [];

          // Create a copy of the checkData state
          const updatedCheckData = { ...checkData };

          // Update the boolean values in checkData based on qualities
          qualities.forEach((quality) => {
            let propertyName = quality;
            if (propertyName && updatedCheckData.hasOwnProperty(propertyName)) {
              updatedCheckData[propertyName] = true;
            }
          });

          // Update the state with the modified checkData
          setCheckData(updatedCheckData);

          const weakness = responseData.weakness
            ? responseData.weakness.split(",")
            : [];

          // Create a copy of the checkData state
          const updatedCheckData1 = { ...checkData1 };

          // Update the boolean values in checkData based on qualities
          weakness.forEach((quality) => {
            let propertyName = quality;
            if (
              propertyName &&
              updatedCheckData1.hasOwnProperty(propertyName)
            ) {
              updatedCheckData1[propertyName] = true;
            }
          });

          // Update the state with the modified checkData
          setCheckData1(updatedCheckData1);
        } else {
          alert.error(res.data.message);
        }
      });
  }, [pat_id]);

  const handleDOB = (date) => {
    setDob(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);
      const age = new Date().getFullYear() - new Date(date).getFullYear();
      setUserDatas((currentstate) => ({
        ...currentstate,
        dob: date,
        age: age
      }));
    }
    else if (date === null || date === "") {
      setUserDatas(currentstate => ({
        ...currentstate,
        dob: "",
        age: ""
      }));
    }
  };

  const selectRegion = (val) => {
    // let value1=e.target.value;
    setUserDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setUserDatas((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
  };
  const reader = new FileReader();
  const uploadImage1 = async (e) => {

    const key = e.target.name;
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);

    if (!file) {
      alert.error("Please Select a File");
      setFileName1("Upload File");
      return;
    }

    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
      alert.error("Select a valid image or PDF file");
      setFileName("Upload File");
      return;
    }

    if (file.size > 10e6) {
      alert.error("Select a file smaller than 10MB");
      setFileName1("Upload File");
      return;
    }

    const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;

    if (file.type.startsWith('image/')) {
      setFileName1(shortFileName);
      setUserDatas((currentData) => ({
        ...currentData,
        enquiry_report: file, // Store the base64 encoded image data
        imageUrl1: imageUrl, // Optional for image preview
      }));
    } if (file.type === 'application/pdf') {
      // Handle PDF file
      setFileName1(file.name);
      setUserDatas((currentData) => ({
        ...currentData,
        enquiry_report: file,
        imageUrl: imageUrl,
      }));

    }
  };

  const uploadImage = async (e) => {

    const key = e.target.name;
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);

    if (!file) {
      alert.error("Please Select a File");
      setFileName("Upload File");
      return;
    }

    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
      alert.error("Select a valid image or PDF file");
      setFileName("Upload File");
      return;
    }

    if (file.size > 10e6) {
      alert.error("Select a file smaller than 10MB");
      setFileName("Upload File");
      return;
    }

    const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;

    if (file.type.startsWith('image/')) {
      setFileName(shortFileName);
      setUserDatas((currentData) => ({
        ...currentData,
        consent_form: file, // Store the base64 encoded image data
        imageUrl1: imageUrl, // Optional for image preview
      }));
    } if (file.type === 'application/pdf') {
      // Handle PDF file
      setFileName(file.name);
      setUserDatas((currentData) => ({
        ...currentData,
        consent_form: file,
        imageUrl: imageUrl,
      }));

    }
  };
  const handleRadio = (e) => {
    let val = e.target.value;
    let key = e.target.name;
    if (key === "married") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        marital_status: val === "yes" ? true : val === "no" ? false : "",
      }));
    } else if (key === "is_home") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        is_home: val === "yes" ? true : val === "no" ? false : "",
      }));
    } else if (key === "post_care") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        post_care: val === "yes" ? true : false,
      }));
    } else if (key === "is_aliveparent") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        is_aliveparent: val === "yes" ? true : val === "no" ? false : "",
      }));
    } else if (key === "own_land") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        own_land: val === "yes" ? true : false,
      }));
    } else if (key === "own_property") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        own_property: val === "yes" ? true : false,
      }));
    } else if (key === "own_rationcard") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        own_rationcard: val === "yes" ? true : val === "no" ? false : "",
      }));
    } else if (key === "own_income") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        own_income: val === "yes" ? true : false,
      }));
    } else if (key === "physical_support") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        physical_support: val === "yes" ? true : val === "no" ? false : "",
      }));
    } else if (key === "police_case") {
      setUserDatas((currentstate) => ({
        ...currentstate,
        police_case: val === "yes" ? true : val === "no" ? false : "",
      }));
    }
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {

    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setUserDatas((currentstate) => ({
      ...currentstate,
      phone: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
  };
  const handlePhoneInputmember = (value, name, data, event, formattedValue) => {
    // Check if the phone number length is less than 12 characters
    if (value.length < 12) {
      setMember((currentState) => ({
        ...currentState,
        phone: "91",

      }));
    } else {
      setMember((currentState) => ({
        ...currentState,
        phone: value,
        error: '', // Clear the error message if the length is valid
      }));
    }
  };


  const handleChangeAge = (event) => {
    const inputAge = parseInt(event.target.value, 10);
    if (!isNaN(inputAge) && inputAge >= 1 && inputAge <= 150) {


      // Calculate DOB from age and set it
      const currentDate = new Date();
      const birthYear = currentDate.getFullYear() - inputAge;
      const birthDate = new Date(currentDate);
      birthDate.setFullYear(birthYear);

      handleDOB(birthDate);
      setUserDatas((currentState) => ({
        ...currentState,
        dob: birthDate.toISOString().split('T')[0],
        age: event.target.value
      }));
    } else if (event.target.value === "" || event.target.value === null) {
      setDob(null);
      setUserDatas((currentState) => ({
        ...currentState,
        dob: null,
        age: null
      }));
    }
  };
  const handlePhoneInputrefer = (value, name, data, event, formattedValue) => {
    // Check if the phone number length is less than 12 characters

    if (value.length < 12) {
      setrefer((currentState) => ({
        ...currentState,
        phone: "91",

      }));
    } else {
      setrefer((currentState) => ({
        ...currentState,
        phone: value,
        error: '', // Clear the error message if the length is valid
      }));
    };
  }


  const handlePhoneInputwm = (value, data, event, formattedValue) => {
    setUserDatas((currentstate) => ({
      ...currentstate,
      wm_contact: value,
    }));
  };

  const handleCheckbox1 = (e) => {
    const val = e.target.name;
    setCheckData1((data) => ({ ...data, [val]: !checkData1[val] }));
  };

  const handlePhoneInputrf = (value, data, event, formattedValue) => {
    setUserDatas((currentstate) => ({
      ...currentstate,
      referer_contact: value,
    }));
  };
  useEffect(() => {
    let updatedArray = [];
    for (var key in checkData1) {
      if (checkData1[key] === true) {
        updatedArray.push(key);
      }
    }

    setCheckDataArray1(updatedArray);
  }, [checkData1]);


  const handleCheckExisting = () => {
    setIsValidEmail(true);
    if (userDatas.email !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));


      const data = {
        email: userDatas.email
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                email: `This email is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => { });
    }
  };

  const handleMalayalamInputToggle1 = () => {
    setUseMalayalamInput1((prevState) => !prevState);
  };

  const handleReferChange = (data, e) => {
    setrefer((currentstate) => ({
      ...currentstate,
      referer: data.value,
    }));
  };

  const IdentityChange = (data) => {
    setUserDatas((current) => ({
      ...current,
      identitycard: data,
    }));
  };
  const handleContinue = async () => {

    if (
      (await validate()) &&
      (isValidEmail || isValidPhone || isValidIdentity)
    ) {
      if (validate && isValidIdentity && eqDate !== null && eqDate !== "" && isValidEq&&(error.phone===undefined||error.phone==="")) {
        handleUpdate();
      }
    }
  };
  const validate = async () => {

    let input = userDatas;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["firstname"] || input["firstname"] === "") {
      isValid = false;
      errors["firstname"] = "Enter first name";
    }
    if (!input["pv_enquiry_number"] || input["pv_enquiry_number"] === "") {
      isValid = false;
      errors["enquiry_number"] = " Enter enquiry number";
    }

    // if (!input["lastname"] || input["lastname"] === "") {
    //   isValid = false;
    //   errors["lastname"] = "Enter last name";
    // }

    // if (!input["state"] || input["state"] === "") {
    //   isValid = false;
    //   errors["state"] = "Select a region";
    // }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    if (isValidIdentity === false) {
      isValid = false;
      errors["identitynumber"] = "IdentityCard number already present!";
    }

    if (input["phone"] !== "" && input["phone"] !== null) {
      const phoneNumber = input["phone"];

      // Use a regular expression to match a 10-digit number

      if (phoneNumber.mobile_number.length < 10 && phoneNumber.mobile_number.length !== 2) {

        isValid = false;
        alert.error("please enter 10 digit mobile number")
      }
    }

    if (input["email"] !== "" && input["email"] !== null) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
      }
    }

    if (input["bystanderconatct"] !== "91" && input["bystanderconatct"] !== null && input["bystanderconatct"] !== "") {
      const phoneNumber = input["bystanderconatct"];

      // Use a regular expression to match a 10-digit number

      if (phoneNumber.length < 12) {
        isValid = false;
        errors["bystanderconatct"] = "Enter a valid 10-digit phone number";
      }
    }

    if (eqDate === "" || eqDate === null) {
      isValid = false;
      errors["enquiry_date"] = " Enter enquiry date";
    }


    if (typeof input["firstname"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern2.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }
    if (!isValidEq) {
      isValid = false;
      errors["enquiry_number"] = " similar enquiry number found";
    }

    if (typeof input["lastname"] !== "undefined") {
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern3.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }

    // if (!userDatas.phone.dialcode || userDatas.phone.dialcode === "" || !userDatas.phone.rawphone || userDatas.phone.rawphone === "") {
    //   isValid = false;
    //   errors["phone"] = "Enter phone number";
    // }

    // if (typeof userDatas.dob == "undefined" || userDatas.dob === "" || userDatas.dob === null) {
    //   isValid = false;
    //   errors["dob"] = "Enter your date of birth";
    // }
    if (
      typeof userDatas.gender == "undefined" ||
      userDatas.gender === "" ||
      userDatas.gender === null
    ) {
      isValid = false;
      errors["gender"] = "Select gender";
    }

    if (
      typeof userDatas.consent_form == "undefined" ||
      userDatas.consent_form === "" ||
      userDatas.consent_form === null ||
      userDatas.consent_form === undefined
    ) {
      isValid = false;
      errors["consent"] = "Please upload consent";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };
  useEffect(() => {
    let updatedArray = [];
    for (var key in checkData) {
      if (checkData[key] === true) {
        updatedArray.push(key);
      }
    }

    setCheckDataArray(updatedArray);
  }, [checkData]);

  const handlePhoneInputbystander = (
    value,
    name,
    data,
    event,
    formattedValue
  ) => {
    //  const rawPhone = value.slice(data.dialCode.length);

    //const dialcode = value.slice(0, data.dialCode.length);

    setUserDatas((currentstate) => ({
      ...currentstate,
      bystanderconatct: value,
    }));
  };

  const handleCheckbox = (e) => {
    const val = e.target.name;
    setCheckData((data) => ({ ...data, [val]: !checkData[val] }));
  };

  const handleUpdate = async () => {

    if
      ((userDatas.bystandername !== null && userDatas.bystandername !== "") &&
      (userDatas.bystanderconatct === "91" || userDatas.bystanderconatct === "")) {
      alert.error(" bystander name and contact should add");
    }
    else if ((userDatas.bystandername === "" || userDatas.bystandername === null || userDatas.bystandername === undefined) && (userDatas.bystanderconatct.length > 2 || userDatas.bystanderconatct === undefined)) {
      alert.error(" bystander name and contact should add");
    }
    else if (
      userDatas.identitycard.value &&
      userDatas.identitycard.value !== "" &&
      userDatas.identitynumber === null
    ) {
      alert.error(" enter identity number");
    } else {
      const tokenString = sessionStorage.getItem("usertoken");
      const str = tokenString.replace(/["]+/g, "");

      const commonData = new FormData();
      commonData.append("first_name", userDatas.firstname);
      commonData.append("email", userDatas.email);
      commonData.append("gender", userDatas.gender);

      // Add other common data here

      const govtMemberData = new FormData();
      govtMemberData.append("govt_member", JSON.stringify(allRefer));
      govtMemberData.append("relatives", JSON.stringify(members));
      govtMemberData.append("qualities", checkDataArray);
      govtMemberData.append("weakness", checkDataArray1);
      govtMemberData.append("enquiry_status", "pending");

      try {
        // Send govt_member, relatives, qualities, and weakness data first
        const govtMemberResponse = await axios.put(
          `${APIURL}/api/v1/service-provider/complete-enquiry/?pv_enquiry_number=${pat_id}`,
          govtMemberData,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        );

        if (govtMemberResponse.data.status === "success") {
          alert.success(
            "Successfully updated govt_member, relatives, qualities, and weakness"
          );

          // Now send the other data
          const otherData = new FormData();
          otherData.append(
            "idcard_type",
            userDatas.identitycard.value !== null
              ? userDatas.identitycard.value
              : ""
          );
          otherData.append(
            "idcard_no",
            userDatas.identitynumber !== null ? userDatas.identitynumber : ""
          );
          if (userDatas.state !== null) {
            otherData.append("state", userDatas.state);
          }
          if (userDatas.lastname !== null) {
            otherData.append("last_name", userDatas.lastname);
          }

          otherData.append("dob", userDatas.dob !== "" && userDatas.dob !== null ? moment(userDatas.dob).format("YYYY-MM-DD") : "");

          if (userDatas.phone.mobile_number !== "91") {
            otherData.append("mobile_number", userDatas.phone.mobile_number);
          }
          if (userDatas.phone.mobile_number === "91") {
            otherData.append("mobile_number", "");
          }
          if (userDatas.bystanderconatct !== "91") {
            otherData.append("bystander_contact", userDatas.bystanderconatct);
          }
          if (userDatas.bystanderconatct === "91") {
            otherData.append("bystander_contact", "");
          }
          if (userDatas.bystandername) {
            otherData.append("bystander_name", userDatas.bystandername);
          }
          if (userDatas.bystandername === "") {
            otherData.append("bystander_name", "");
          }

          if (userDatas.pincode !== null) {
            otherData.append("pincode", userDatas.pincode);
          }
          if (userDatas.province !== null) {

            otherData.append("province", userDatas.province);
          }


          if (userDatas.city !== null) {
            otherData.append("city", userDatas.city);
          }
     

          if (userDatas.post_care ===true) {
            if ((userDatas.post_caredetails !== undefined && userDatas.post_caredetails !== "")) {
              otherData.append("post_care", userDatas.post_caredetails);
            }
            else {
              otherData.append("post_care", "yes");
            }
          }
          else {
            otherData.append("post_care", "no");
          }
          if (userDatas.location) {
            otherData.append("location", userDatas.location);
          }
          if (userDatas.parent !== null) {
            otherData.append("parent_name", userDatas.parent);
          }
          if (userDatas.marital_status) {
            otherData.append("marital_status", userDatas.marital_status);
          }
          if (userDatas.marital_status === false) {
            otherData.append("marital_status", "no");
          }
          if (userDatas.is_home) {
            otherData.append("is_home", userDatas.is_home);
          }
          if (userDatas.is_home === false) {
            otherData.append("is_home", "no");
          }
          if (userDatas.is_aliveparent) {
            otherData.append("is_aliveparent", userDatas.is_aliveparent);
          }
          if (userDatas.is_aliveparent === false) {
            otherData.append("is_aliveparent", "no");
          }
          if (userDatas.own_land ===true) {
            if ((userDatas.own_landDetails !== undefined && userDatas.own_landDetails !== "")) {
              otherData.append("own_land", userDatas.own_landDetails);
            }
            else {
              otherData.append("own_land", "yes");
            }
          }
          else {
            otherData.append("own_land", "no");
          }

          

          if (
            (userDatas.successordetails === "" || userDatas.successordetails === undefined) &&
            userDatas.own_property
          ) {
            otherData.append("own_property", "yes");
          }
          if (

            userDatas.own_property === false
          ) {
            otherData.append("own_property", "no");
          }
          if (userDatas.successordetails && userDatas.own_property !== false) {
            otherData.append("own_property", userDatas.successordetails);
          }
          if (userDatas.own_rationcard) {
            otherData.append("own_rationcard", userDatas.own_rationcard);
          }
          if (userDatas.own_rationcard === false) {
            otherData.append("own_rationcard", "no");
          }
          if (userDatas.finincome === "" && userDatas.own_income) {
            otherData.append("own_income", "yes");
          }
          if (userDatas.own_income === false) {
            otherData.append("own_income", "no");
          }
          if (userDatas.finincome !== "" && userDatas.own_income !== false) {
            otherData.append("own_income", userDatas.finincome);
          }
          if (userDatas.physical_support) {
            otherData.append("physical_support", userDatas.physical_support);
          }
          if (userDatas.physical_support === false) {
            otherData.append("physical_support", "no");
          }
          if (userDatas.police_case) {
            otherData.append("police_case", userDatas.police_case);
          }
          if (userDatas.police_case === false) {
            otherData.append("police_case", "no");
          }
          if (userDatas.undergoing_treatment !== null) {
            otherData.append(
              "undergoing_treatment",
              userDatas.undergoing_treatment
            );
          }
          if (userDatas.treatment_place !== null) {
            otherData.append("treatment_place", userDatas.treatment_place);
          }
          if (userDatas.care_details !== null) {
            otherData.append("care_details", userDatas.care_details);
          }
          if (userDatas.autobiography !== null) {
            otherData.append("autobiography", userDatas.autobiography);
          }
          if (userDatas.investigator) {
            otherData.append("investigator", userDatas.investigator);
          }

          if (userDatas.report !== null) {
            otherData.append("field_report", userDatas.report);
          }
          if (userDatas.consent_form) {
            otherData.append("consent_form", userDatas.consent_form);
          }

          otherData.append("enquiry_report", userDatas.enquiry_report);

          if (userDatas.pv_enquiry_number && userDatas.pv_enquiry_number.toLowerCase() !== eqnum.toLowerCase()) {
            otherData.append('pv_enquiry_number', userDatas.pv_enquiry_number);
          } if (userDatas.enquiry_date) {
            otherData.append("enquiry_date", userDatas.enquiry_date);
          }
          // Add other data fields here

          // Append the common data to the otherData FormData
          for (const pair of commonData.entries()) {
            otherData.append(pair[0], pair[1]);
          }
          const otherDataResponse = await axios.put(
            `${APIURL}/api/v1/service-provider/complete-enquiry/?pv_enquiry_number=${pat_id}&reopen=enquiry`,
            otherData,
            {
              headers: {
                Authorization: "Token " + str,
              },
            }
          );

          if (otherDataResponse.data.status === "success") {
            alert.success("Successfully updated other data");
            navigate("/admission");
          } else {
            alert.error("Error in submitting other data");
          }
        } else {
          alert.error(
            "Error in submitting govt_member, relatives, qualities, and weakness"
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
        alert.error("An error occurred while updating data");
      }
    }
  };
  const trimspace = (val) => {

    return val.startsWith(' ')
      ? val.trimLeft()
      : val;

  }
  const handlePEDate = (date) => {
    seteqDate(date)
    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();
      dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))
      let DateExtracted = dateUpdated.toISOString().split('T')[0];

      setUserDatas(currentstate => ({
        ...currentstate,
        enquiry_date: DateExtracted,

      }));
    }
    else if (date === null || date === "") {
      setUserDatas(currentstate => ({
        ...currentstate,
        enquiry_date: "",

      }));
    }
    setError(currentstate => ({
      ...currentstate,
      enquiry_date: ""
    }))
  }
  return (
    <div>
      <div className="">
        <div
          className="hosp-patient-form"
          style={{ marginTop: "1%", width: "80%" }}
        >
          <div style={{ display: "flex" }}>
            <img src={peace} className="" width="250px" />
            {/* <h1
              className=""
              style={{
                fontStyle: "italic",
                fontColor: "black",
                fontWeight: "300",
                textAlign: "left",
                fontSize: "39px",
              }}
            >
              {" "}
              Peace Village
            </h1> */}
            <h6
              className=""
              style={{
                fontStyle: "italic",
                color: "black",
                fontWeight: "300",
                textAlign: "right",
                fontSize: "13px",
                marginLeft: "50%",
              }}
            >
              Peace Village Foundation Charitable Trust,
              <br /> Pinangode p.o.,Wayanad,
              <br /> Ph:04936251055,8281345535,
              <br />
              www.peacevillage.in,email:info@peacevillage.in
            </h6>
          </div>
          <h4 className="title-of-tasks">Enquiry Form</h4>
          <br />
          <div className="form-align">
            <div className="item item-left-row">

              <div className="row">
                <div className="col-6">
                  <label className="text-style-here"> *Enquiry Number: </label>
                  <input value={userDatas.pv_enquiry_number} type="text" name="pv_enquiry_number" onChange={handleChangeenqnum} onBlur={(event) => {

                    // Check if both identityCard and identityNumber have values and match the input

                  }} maxLength='30' className="form-control" />
                  {error.enquiry_number ? <div className="error-validationmsg">{error.enquiry_number}</div> : null}


                </div>
                <div className="col-6">
                  <label className="text-style-here">*Enquiry Date:</label>

                  <DatePicker
                    className="enquiryinput"
                    onChange={date => { handlePEDate(date) }}
                    style={{ width: "80%", border: "none" }}
                    maxDate={new Date()}
                    minDate={userDatas.pe_date}
                    value={eqDate}

                  />
                  {error.enquiry_date ? <div className="error-validationmsg">{error.enquiry_date}</div> : null}
                </div>
              </div>


            </div>


            <div className="item item-left-row">
              <label className=""> First Name: </label>
              <input
                value={userDatas.firstname}
                className="form-control"
                type="text"
                name="firstname"
                disabled={userDatas.firstname}
                maxLength="30"
              />

              {error.firstname ? (
                <div className="error-validation-msg">{error.firstname}</div>
              ) : null}
            </div>
            <div className="item item-left-row">
              <label className=""> Last Name: </label>{" "}
              <input
                className="form-control"
                value={userDatas.lastname}
                type="text"
                name="lastname"
                onChange={handleChange}
                maxLength="30"
              />
              {error.lastname ? (
                <div className="error-validation-msg">{error.lastname}</div>
              ) : null}
            </div>
            <div className="item item-left-row">
              <label className=""> Father/Mother Name: </label>
              <input
                value={userDatas.parent}
                className="form-control"
                type="text"
                name="parent"
                onChange={handleChange}
                maxLength="30"
              />
            </div>

            <div className="item item-left-row">
              <label className="phone-hov"> Phone: </label>
              <PhoneInput
                country={"in"}
                onlyCountries={["in"]}
                countryCodeEditable={false}
                isValid={validatePhoneInput}
                value={
                  userDatas.phone.mobile_number
                    ? userDatas.phone.mobile_number
                    : ""
                }
                onChange={handlePhoneInput}
              // onBlur={handleCheckExistingPhone}
              />
              {error.phone ? (
                <div className="error-validation-msg"> {error.phone}</div>
              ) : null}
            </div>

            <div className="item item-left-row">
              <label className=""> Email Address: </label>
              <input
                className="form-control"
                type="email"
                value={userDatas.email}
                name="email"
                onChange={handleChange}
                //  onBlur={handleCheckExisting}
                maxLength="50"
              />

              {error.email ? (
                <div className="error-validation-msg"> {error.email}</div>
              ) : null}
            </div>

            <div
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              className="form-control gender-div"
            >
              <span className="text-style-here" > Date of Birth:</span>
              <DatePicker
                onChange={(date) => {
                  handleDOB(date);
                }}
                style={{ width: "80%", border: "none" }}
                maxDate={new Date()}
                value={userDatas.dob}
              />


              <span className="text-style-here"
                style={{ fontSize: "10px", marginLeft: "10px", color: "grey" }}
              >

                /
              </span>
              <input
                type="number"
                min="1"
                max="150"
                placeholder="Age"
                name="age"
                value={userDatas.age}
                onChange={handleChangeAge}
                style={{ borderRadius: "5px", width: "30%" }}
              ></input>
              {error.dob ? (
                <div className="error-validation-msg"> {error.dob}</div>
              ) : null}
            </div>
            <div className="item item-left-row" style={{ marginTop: "-20px" }} >
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: userDatas.identitycard.value !== null&&userDatas.identitycard.value !== "" ? "50%" : "100%" }}>
                  <label className="text-style-here"> Identity card: </label>
                  <Select
                    value={userDatas.identitycard.value !== "" ? userDatas.identitycard : "Select type"}
                    onChange={(data, e) => {
                      IdentityChange(data, e);
                    }}
                    options={identityoptions}

                    name="identitycard"
                    isDisabled={userDatas.identitynumber}
                    className="select-currency select-flex"
                  />
                </div>
                {userDatas.identitycard.value !== null &&userDatas.identitycard.value !== ""? (
                  <div style={{ width: "40%" }}>
                    <span className="text-style-here" > Identity Card Number:</span>
                    <input
                      className="form-control"
                      value={userDatas.identitynumber}
                      type="text"
                      name="identitynumber"

                      placeholder=""
                      onChange={handleChangeidnum}
                      maxLength="20"


                    />
                    {error.identitynumber ? (
                      <div className="error-validation-msg">
                        {" "}
                        {error.identitynumber}
                      </div>
                    ) : null}
                  </div>
                ) : null}

              </div>
              {/* {error.identitycard ? <div className="error-validation-msg"> {error.identitycard}</div> : null} */}
            </div>

            <div
              className="form-control gender-div"
              style={{ alignSelf: "end", marginTop: "1%" }}
            >
              <span> Gender:</span>
              <input
                type="radio"
                name="gender"
                value="male"
                checked={userDatas.gender === "male"}
                //onClick={handleGenderRadioButton}
                disabled={userDatas.gender !== ""}
              />&nbsp;
              <label htmlFor="">Male</label>

              <input
                type="radio"
                name="gender"
                value="female"
                checked={userDatas.gender === "female"}
                // onClick={handleGenderRadioButton}
                disabled={userDatas.gender !== ""}
              />&nbsp;

              <label htmlFor="">Female</label>

              <input
                type="radio"
                name="gender"
                value="others"
                checked={userDatas.gender === "others"}
                disabled={userDatas.gender !== ""}
              //onClick={handleGenderRadioButton}
              />&nbsp;
              <label htmlFor="others">Others</label>

              {error.gender ? (
                <div className="error-validation-msg"> {error.gender}</div>
              ) : null}
            </div>
            <div
              className="form-control gender-div"
              style={{ alignSelf: "end" }}
            >
              <input
                type="radio"
                name="married"
                value="yes"
                checked={userDatas.marital_status === true}
                onClick={handleRadio}
              />&nbsp;
              <label htmlFor="">Married</label>

              <input
                type="radio"
                //id="female"
                name="married"
                value="no"
                checked={userDatas.marital_status === false}
                onClick={handleRadio}
              />&nbsp;

              <label htmlFor="">Unmarried</label>

              {error.gender ? (
                <div className="error-validation-msg"> {error.gender}</div>
              ) : null}
            </div>

            {/* {userDatas.identitycard.value !== null ? (
              <div className="item item-left-row">
                <span> Identity Card Number:</span>
                <input
                  className="form-control"
                  value={userDatas.identitynumber}
                  type="text"
                  name="identitynumber"
                 // disabled={isIdentityNumberDisabled}
                  placeholder=""
                  onChange={handleChange}
                  maxLength="20"
                  onBlur={(event) => {
                    // Check if both identityCard and identityNumber have values and match the input
                    if (
                      identityCard !== null &&
                      identityNumber !== "" &&
                      identityCard === userDatas.identitycard.value &&
                      identityNumber === userDatas.identitynumber
                    ) {
                      setError((currentstate) => ({
                        ...currentstate,
                        identitynumber: "",
                      }));
                    } else {
                      // Call handleCheckExistingIdentity
                      handleCheckExistingIdentity(event);
                    }
                  }}
                  
                />
                {error.identitynumber ? (
                  <div className="error-validation-msg">
                    {" "}
                    {error.identitynumber}
                  </div>
                ) : null}
              </div>
            ) : null} */}
            <div className="item item-left-row">
              <label className=""> Address Line1: </label>
              <input
                className="form-control"
                type="text"
                value={userDatas.city}
                name="city"
                placeholder="city"
                onChange={handleChange}
              />
              {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
            </div>
            <div className="item item-left-row">
              <label className="">Address Line2: </label>
              <input
                className="form-control"
                type="text"
                value={userDatas.province}
                name="province"
                placeholder="province"
                onChange={handleChange}
              />

            </div>

            <div className="item item-left-row">
              <label className=""> Address Line3: </label>
              <input
                className="form-control"
                value={userDatas.location}
                type="text"
                name="location"
                placeholder="location"
                disabled={userDatas.location}
              />
            </div>

            <div className="country-selector item-left-row">
              <label className=""> Country: </label>
              <input
                className="form-control"
                value={"India"}
                disabled
              //onChange={(val) => selectCountry(val)}
              />
            </div>
            <div className="region-selector  item-left-row">
              <label className=""> Region: </label>
              <RegionDropdown
                country={"India"}
                value={userDatas.state}
                onChange={(val) => selectRegion(val)}
              />
              {error.state ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.state}
                </div>
              ) : null}
            </div>

            <div className="item item-left-row" style={{}}>
              <label className=""> Pincode: </label>
              <input
                className="form-control"
                placeholder="pincode"
                value={userDatas.pincode}
                type="text"
                name="pincode"
                onChange={handleChange}
                maxLength="10"
              />
              {error.pincode ? (
                <div className="error-validation-msg"> {error.pincode}</div>
              ) : null}
            </div>

            <div
              className="form-control gender-div"
              style={{ textAlign: "left" }}
            >
              <span> Do you own a home? :</span>
              <input
                type="radio"
                id="is_home"
                name="is_home"
                value="yes"
                checked={userDatas.is_home === true}
                onClick={handleRadio}
              />
              <label htmlFor="male">Yes</label>

              <input
                type="radio"
                id="is_home"
                name="is_home"
                value="no"
                checked={userDatas.is_home === false}
                onClick={handleRadio}
              />

              <label htmlFor="female">No</label>
            </div>

            <div
              className="form-control gender-div"
              style={{ textAlign: "left" }}
            >
              <span> Is your father/mother alive? :</span>
              <input
                type="radio"
                id="is_aliveparent"
                name="is_aliveparent"
                value="yes"
                checked={userDatas.is_aliveparent === true}
                onClick={handleRadio}
              />
              <label htmlFor="male">Yes</label>

              <input
                type="radio"
                id="is_aliveparent"
                name="is_aliveparent"
                value="no"
                checked={userDatas.is_aliveparent === false}
                onClick={handleRadio}
              />

              <label htmlFor="female">No</label>
            </div>
            <div
              className="form-control gender-div"
              style={{ textAlign: "left" }}
            >
              <span> Do you own land? :</span>
              <input
                type="radio"
                id="own_land"
                name="own_land"
                value="yes"
                checked={userDatas.own_land === true}
                onClick={handleRadio}
              />
              <label htmlFor="male">Yes</label>

              <input
                type="radio"
                id="own_land"
                name="own_land"
                value="no"
                checked={userDatas.own_land === false}
                onClick={handleRadio}
              />

              <label htmlFor="female">No</label>
            </div>

            {userDatas.own_land === true ? (
              <div className="item item-left-row">
                <label className=""> Own land Details: </label>
                <input
                  className="form-control"
                  value={userDatas.own_landDetails}
                  type="text"
                  name="own_landDetails"
                  onChange={handleChange}
                  placeholder="own land details"
                />
              </div>
            ) : (
              ""
            )}
            <div
              className="form-control gender-div"
              style={{ textAlign: "left" }}
            >
              <span> Are you the successor of any property? :</span>
              <input
                type="radio"
                id="own_property"
                name="own_property"
                value="yes"
                checked={userDatas.own_property === true}
                onClick={handleRadio}
              />
              <label htmlFor="male">Yes</label>

              <input
                type="radio"
                id="own_property"
                name="own_property"
                value="no"
                checked={userDatas.own_property === false}
                onClick={handleRadio}
              />

              <label htmlFor="female">No</label>
            </div>

            {userDatas.own_property === true ? (
              <div className="item item-left-row">
                <label className=""> Successor property Details: </label>
                <input
                  className="form-control"
                  value={userDatas.successordetails}
                  type="text"
                  name="successordetails"
                  onChange={handleChange}
                  placeholder="successor property details"
                />
              </div>
            ) : (
              ""
            )}
            <div
              className="form-control gender-div"
              style={{ textAlign: "left" }}
            >
              <span> Is your name added in Ration Card? :</span>
              <input
                type="radio"
                id="own_rationcard"
                name="own_rationcard"
                value="yes"
                checked={userDatas.own_rationcard === true}
                onClick={handleRadio}
              />
              <label htmlFor="male">Yes</label>

              <input
                type="radio"
                id="own_rationcard"
                name="own_rationcard"
                value="no"
                checked={userDatas.own_rationcard === false}
                onClick={handleRadio}
              />

              <label htmlFor="female">No</label>
            </div>

            <div className="form-control gender-div" style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
              <span style={{ flex: 1 }}>
                Did receive any pension/insurance/other incomes?:
              </span>
              <label htmlFor="own_income" style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  id="own_income"
                  name="own_income"
                  value="yes"
                  checked={userDatas.own_income === true}
                  onClick={handleRadio}
                />
                Yes
              </label>

              <label htmlFor="own_income">
                <input
                  type="radio"
                  id="own_income"
                  name="own_income"
                  value="no"
                  checked={userDatas.own_income === false}
                  onClick={handleRadio}
                />
                No
              </label>
            </div>

            {userDatas.own_income === true ? (
              <div className="item item-left-row">
                <label className=""> Other financial income Details: </label>
                <input
                  className="form-control"
                  value={userDatas.finincome}
                  type="text"
                  name="finincome"
                  onChange={handleChange}
                  placeholder="other financial income details"
                />
              </div>
            ) : (
              ""
            )}
            <div
              className="form-control gender-div"
              style={{ textAlign: "left" }}
            >
              <span> Any other physical support ? :</span>
              <input
                type="radio"
                id="physical_support"
                name="physical_support"
                value="yes"
                checked={userDatas.physical_support === true}
                onClick={handleRadio}
              />
              <label htmlFor="male">Yes</label>

              <input
                type="radio"
                id="physical_support"
                name="physical_support"
                value="no"
                checked={userDatas.physical_support === false}
                onClick={handleRadio}
              />

              <label htmlFor="female">No</label>
            </div>

            <div
              className="form-control gender-div"
              style={{ textAlign: "left" }}
            >
              <span> Any police cases ? :</span>
              <input
                type="radio"
                id="police_case"
                name="police_case"
                value="yes"
                checked={userDatas.police_case === true}
                onClick={handleRadio}
              />
              <label htmlFor="male">Yes</label>

              <input
                type="radio"
                id="police_case"
                name="police_case"
                value="no"
                checked={userDatas.police_case === false}
                onClick={handleRadio}
              />

              <label htmlFor="female">No</label>
            </div>

            <div className="form-control gender-div" style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
              <span style={{ flex: 1 }}>
                Is there a provision for posthumous care if disabled? :
              </span>
              <label htmlFor="post_care" style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  id="post_care"
                  name="post_care"
                  value="yes"
                  style={{ backgroundColor: "#f5f5f5" }}
                  checked={userDatas.post_care === true}
                  onClick={handleRadio}
                />
                Yes
              </label>

              <label htmlFor="post_care">
                <input
                  type="radio"
                  id="post_care"
                  name="post_care"
                  value="no"
                  style={{ backgroundColor: "#f5f5f5" }}
                  checked={userDatas.post_care === false}
                  onClick={handleRadio}
                />
                No
              </label>
            </div>

            <div className="item item-left-row">
              <label className="">If yes, why not take over now?</label>
              {userDatas.post_care ? (
                <input
                  className="form-control"
                  value={userDatas.post_caredetails}
                  type="text"
                  name="post_caredetails"
                  onChange={handleChange}
                />
              ) : (
                <input
                  className="form-control"
                  value=""
                  type="text"
                  name="post_caredetails"
                  onChange={handleChange}
                  disabled
                />
              )}
            </div>
          </div>
          <br />
          <div>
            <div style={{ marginTop: "20px", justifyContent: "left" }}>
              <label className="" style={{ textAlign: "left" }}>
                {" "}
                <b>Qualities : </b>{" "}
              </label>
              <br />
              <div
                className="attribute-checkboxes"
                style={{
                  backgroundColor: "#f5f5f5",
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  width: "95%",
                  padding: "1%",
                  marginTop: "20px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <input
                    type="checkbox"
                    id="self_sufficient"
                    name="self_sufficient"
                    checked={checkData.self_sufficient}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="self_sufficient">
                    Self Sufficient
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    id="consciousness"
                    name="consciousness"
                    checked={checkData.consciousness === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="consciousness">Consciousness</label>
                  <br />
                  <input
                    type="checkbox"
                    id="attention"
                    name="attention"
                    checked={checkData.attention === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="attention">Attention</label>
                </div>

                <div style={{ flex: 1 }}>
                  <input
                    type="checkbox"
                    id="memory"
                    name="memory"
                    checked={checkData.memory === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="memory">Memory Loss</label>
                  <br />
                  <input
                    type="checkbox"
                    id="spatial_perception"
                    name="spatial_perception"
                    checked={checkData.spatial_perception === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="spatial_perception">Spatial perception</label>{" "}
                  <br />
                  <input
                    type="checkbox"
                    id="talkative"
                    name="talkative"
                    checked={checkData.talkative === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="talkative">Talkative</label>
                </div>
                <div style={{ flex: 1 }}>
                  <input
                    type="checkbox"
                    id="handicapped"
                    name="handicapped"
                    checked={checkData.handicapped === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="handicapped">Handicapped</label>
                  <br />
                  <input
                    type="checkbox"
                    id="sight_issue"
                    name="sight_issue"
                    checked={checkData.sight_issue === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="sight_issue">Sight Issue</label>
                  <br />
                  <input
                    type="checkbox"
                    id="hear_issue"
                    name="hear_issue"
                    checked={checkData.hear_issue === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="hear_issue">Hear Issue</label>
                </div>
                <div style={{ flex: 1 }}>
                  <input
                    type="checkbox"
                    id="heterosexual"
                    name="heterosexual"
                    checked={checkData.heterosexual === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="heterosexual">Heterosexual</label>
                  <br />
                  <input
                    type="checkbox"
                    id="other"
                    name="other"
                    checked={checkData.other === true}
                    onChange={handleCheckbox}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="other">Other</label>
                  <br />
                </div>
              </div>
            </div>
            <br />
            <div style={{ marginTop: "20px", justifyContent: "left" }}>
              <label className="text-style-here" style={{ textAlign: "left" }}>
                {" "}
                <b>Weakness : </b>{" "}
              </label>
              <div
                className="attribute-checkboxes"
                style={{
                  backgroundColor: "#f5f5f5",
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  width: "95%",
                  padding: "1%",
                  paddingBottom: "1%",
                  marginTop: "20px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <input
                    type="checkbox"
                    id="smoking"
                    name="smoking"
                    checked={checkData1.smoking}
                    onChange={handleCheckbox1}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="smoking">
                    Smoking
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    id="alcohol"
                    name="alcohol"
                    checked={checkData1.alcohol === true}
                    onChange={handleCheckbox1}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="alcohol">Alcohol </label>
                  <br />
                  <input
                    type="checkbox"
                    id="drug_addict"
                    name="drug_addict"
                    checked={checkData1.drug_addict === true}
                    onChange={handleCheckbox1}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="drug_addict">Drug Addict</label>

                </div>
                <div style={{ flex: 1 }}>

                  <input
                    type="checkbox"
                    id="betel_use"
                    name="betel_use"
                    checked={checkData1.betel_use === true}
                    onChange={handleCheckbox1}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="betel_use">Betel Use</label>
                  <br />
                  <input
                    type="checkbox"
                    id="tobacco"
                    name="tobacco"
                    checked={checkData1.tobacco === true}
                    onChange={handleCheckbox1}
                  />&nbsp;
                  <label className="text-style-here" htmlFor="betel_use">Tobacco Use</label>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="" style={{ marginLeft: "2%" }}>
            <h6 style={{ textDecoration: "underline" }} className="">
              <b> Details of Members in home </b>
            </h6>
            <div className="row" style={{ marginLeft: "2%", alignItems: "center" }}>
              <div className="item  col-2">
                <label className="text-style-here">Name: </label>
                <input
                  value={member.name}
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={handlemember}
                  maxLength="30"
                />
              </div>
              <div className="item  col-1">
                <label className="text-style-here">Age: </label>
                <input
                  value={member.age}
                  className="form-control"
                  type="number"
                  name="age"
                  onChange={handlemember}
                  maxLength="30"
                />
              </div>
              <div className="item col-2">
                <label className="">Relation: </label>
                <input
                  value={member.relation}
                  className="form-control"
                  type="text"
                  name="relation"
                  onChange={handlemember}
                  maxLength="30"
                />
              </div>
              <div className="item col-2">
                <label className="text-style-here">Occupation: </label>
                <input
                  value={member.job}
                  className="form-control"
                  type="text"
                  name="job"
                  onChange={handlemember}
                  maxLength="30"
                />
              </div>
              <div className="item  col-3" style={{ marginTop: ".7rem" }}>
                <label className="text-style-here">Phone: </label>
                <PhoneInput
                  country={"in"}
                  onlyCountries={["in"]}
                  countryCodeEditable={false}
                  value={member.phone}
                  isValid={validatePhoneInput}
                  name="phone"
                  onChange={handlePhoneInputmember}
                  className="enquiryinput"
                  style={{ width: "100%" }}
                />

              </div>
              <div className="col-1">
                <button
                  style={{ marginTop: "2rem", padding: "15%", backgroundColor: "#1269B9", color: "white", borderRadius: "10px", width: "100px" }}
                  onClick={() => {
                    if (member.name) {
                      setMembers([...members, member]);

                      setMember({
                        name: "",
                        age: "",
                        job: "",
                        relation: "",
                        phone: "91",
                      });
                    }
                  }}
                  className=""
                  disabled={members.length > 4}
                >
                  Add
                </button>
              </div>
            </div>

            {members.length > 0 && (
              <div style={{ width: "90%", marginLeft: "8%" }}>
                <div className="row">
                  {members.map((item, index) => {
                    return (
                      <>
                        <div
                          className="col-1"
                          style={{
                            backgroundColor: "aliceblue",
                            padding: "1%",
                            marginTop: "1%",
                          }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className="col-2"
                          style={{
                            backgroundColor: "aliceblue",
                            padding: "1%",
                            marginTop: "1%",
                            textAlign: "center",
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          className="col-2"
                          style={{
                            backgroundColor: "aliceblue",
                            padding: "1%",
                            marginTop: "1%",
                            textAlign: "center",
                          }}
                        >
                          {item.age} years
                        </div>
                        <div
                          className="col-2"
                          style={{
                            backgroundColor: "aliceblue",
                            padding: "1%",
                            marginTop: "1%",
                            textAlign: "center",
                          }}
                        >
                          {item.relation}
                        </div>
                        <div
                          className="col-2"
                          style={{
                            backgroundColor: "aliceblue",
                            padding: "1%",
                            marginTop: "1%",
                            textAlign: "center",
                          }}
                        >
                          {item.job}
                        </div>
                        <div
                          className="col-2"
                          style={{
                            backgroundColor: "aliceblue",
                            padding: "1%",
                            marginTop: "1%",
                            textAlign: "center",
                          }}
                        >
                          {item.phone !== "91" ? item.phone : ""}
                        </div>
                        <div
                          className="col-1"
                          style={{
                            backgroundColor: "aliceblue",
                            padding: "1%",
                            marginTop: "1%",
                            textAlign: "center",
                          }}
                        >
                          <i
                            class="fa fa-trash"
                            style={{ color: "red" }}
                            onClick={() => handleDeleteMember(index)}
                            aria-hidden="true"
                          ></i>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <br />
          <div className="" style={{ marginLeft: "2%" }}>
            <h6 style={{ textDecoration: "underline" }} className="">
              {" "}
              <b> Name of bystander if any emergency arrive</b>{" "}
            </h6>
            <div className="row" style={{ marginLeft: "2%" }}>
              <div className="item  col-2">
                <label className="">Bystander Name: </label>
                <input
                  value={userDatas.bystandername}
                  className="form-control"
                  type="text"
                  name="bystandername"
                  onChange={handleChange}
                  maxLength="30"
                />
              </div>
              <div className="item  col-3">
                <label className="">Bystander Phone: </label>
                <PhoneInput
                  country={"in"}
                  onlyCountries={["in"]}
                  countryCodeEditable={false}
                  value={userDatas.bystanderconatct}
                  onChange={handlePhoneInputbystander}
                  isValid={validatePhoneInput}
                  className="enquiryinput"
                  style={{ width: "120%" }}
                />

              </div>

            </div>
          </div>
          <div style={{ textAlign: "left", paddingLeft: "12%" }}>
            <div className="item item-left-row" style={{ marginLeft: "2%", marginTop: "3%" }}>
              <label className="">Any treatments for diseases : </label>
              <textarea

                className="form-control"
                value={userDatas.undergoing_treatment}
                name="undergoing_treatment"
                onChange={handleChange}
              />
              {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
            </div>

            <div className="item item-left-row" style={{ marginLeft: "2%", marginTop: "3%" }}>
              <label className="">Treatment place name : </label>
              <textarea
                maxLength="1000"
                className="form-control"
                value={userDatas.treatment_place}
                name="treatment_place"
                onChange={handleChange}
              />
              {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
            </div>
            <br />

            <div className="item item-left-row" style={{ marginLeft: "2%" }}>
              <label className="">Care details for patient : </label>
              <textarea

                className="form-control"
                value={userDatas.care_details}
                name="care_details"
                onChange={handleChange}
              />
              {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
            </div>
            <br />

            <div className="item item-left-row" style={{ marginLeft: "2%" }}>
              <label className="">Autobiography details : </label>
              <textarea

                className="form-control"
                value={userDatas.autobiography}
                name="autobiography"
                onChange={handleChange}
              />
              {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
            </div>
            <br />

            {/* <div className="item item-left-row" style={{ marginLeft: "2%" }}>
              <label className="">Details of Investigator : </label>
              <textarea className="form-control" value={userDatas.investigator} name="investigator" onChange={handleChange}
              />
              {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
            {/* </div><br /> */}

            <div className="item item-left-row" style={{ marginLeft: "2%" }}>
              <label className="">Field Findings: </label>
              <textarea
                className="enquiryinputtext"
                value={userDatas.report}
                name="report"
                onChange={handleChange}
                style={{ height: "200px", backgroundColor: "#f5f5f5" }}
              />

              <button
                style={{
                  backgroundColor: "#1269B9",
                  color: "white",
                  width: "200px",
                }}
                onClick={handleMalayalamInputToggle1}
              >
                Switch to Malayalam Input
              </button>
              {useMalayalamInput1 && (
                <iframe
                  title="Google Malayalam Input"
                  src={process.env.PUBLIC_URL + "/malayalam.html"}
                  width="100%"
                  height="400px"
                ></iframe>
              )}
              {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
            </div>
            <br />
            <br />
          </div>
          <div>
            <div className=" recommend" style={{ marginLeft: "2%" }}>
              <h6 style={{ textDecoration: "underline" }} className="">
                <b> Recommendations </b>
              </h6>
              <div
                className="row"
                style={{ alignItems: "center", marginLeft: "2%", display: "flex", justifyContent: "flex-start" }}
              >
                <div className={refer.referer === "others" ? "item  col-2" : "item col-4"} style={{ marginTop: "2.1rem" }}>
                  <Select
                    value={refer.referer ? refer.referer.value : ""}
                    onChange={(data, e) => {
                      handleReferChange(data, e);
                    }}
                    options={recommendoptions}
                    name="referer"
                    className="select-currency select-flex"
                  />
                </div>
                {refer.referer === "others" && <div style={{ marginTop: "30px" }} className="item  col-2">
                  <input
                    value={otherRec}
                    className="form-control"
                    type="text"
                    name="other"

                    onChange={(e) => {
                      setOtherRec(e.target.value);
                    }}

                    maxLength="30"
                  />
                </div>}
                <div className={refer.referer === "others" ? "item  col-2" : "item col-3"}>
                  <label className="">Name: </label>
                  <input
                    value={refer.name}
                    className="form-control"
                    type="text"
                    name="name"
                    disabled={!refer.referer || refer.referer.value === ""}
                    onChange={handleRefer}
                    maxLength="30"
                  />
                </div>

                <div className="item  col-3">
                  <label className="">Phone: </label>
                  <PhoneInput
                    country={"in"}
                    onlyCountries={["in"]}
                    countryCodeEditable={false}
                    value={refer.phone}
                    name="phone"
                    isValid={validatePhoneInput}
                    onChange={handlePhoneInputrefer}
                    className="enquiryinput"
                    style={{ width: "100%" }}
                  />

                </div>
                <div className="item col-1">
                  {" "}
                  <button
                    style={{ marginTop: "2rem", padding: "15%", backgroundColor: "#1269B9", color: "white", borderRadius: "10px", width: "100px" }}
                    onClick={() => {
                      if (refer.name) {
                        if (refer.referer === "others") {
                          const updatedUserData = {
                            ...refer,
                            referer: otherRec
                          };
                          setAllRefer([...allRefer, updatedUserData]);


                        } else {
                          setAllRefer([...allRefer, refer]);
                        }
                        setrefer({
                          referer: { value: "", name: "" },
                          name: "",
                          phone: "91",
                        });
                      }
                    }}
                    className=""
                    disabled={allRefer.length > 2}

                  >
                    Add
                  </button>
                </div>
              </div>

              {allRefer.length > 0 && (
                <div style={{ width: "90%", marginLeft: "8%" }}>
                  <div className="row">
                    {allRefer.map((item, index) => {
                      return (
                        <>
                          <div className="row">
                            <div
                              className="col-1"
                              style={{
                                backgroundColor: "aliceblue",
                                padding: "1%",
                                marginTop: "1%",
                              }}
                            >
                              {index + 1}
                            </div>
                            <div
                              className="col-2"
                              style={{
                                backgroundColor: "aliceblue",
                                padding: "1%",
                                marginTop: "1%",
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {item.referer}
                            </div>
                            <div
                              className="col-2"
                              style={{
                                backgroundColor: "aliceblue",
                                padding: "1%",
                                marginTop: "1%",
                                textAlign: "center",
                              }}
                            >
                              {item.name}{" "}
                            </div>

                            <div
                              className="col-2"
                              style={{
                                backgroundColor: "aliceblue",
                                padding: "1%",
                                marginTop: "1%",
                                textAlign: "center",
                              }}
                            >
                              {item.phone !== "91" ? item.phone : ""}
                            </div>
                            <div
                              className="col-1"
                              style={{
                                backgroundColor: "aliceblue",
                                padding: "1%",
                                marginTop: "1%",
                                textAlign: "center",
                              }}
                            >
                              <i
                                class="fa fa-trash"
                                style={{ color: "red" }}
                                onClick={() => handleDeleteRecommender(index)}
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <div className="flex-row">
            <div
              style={{ backgroundColor: "#E4DD9F", width: "200px" }}
              className="custom-file"
            >
              <input
                type="file"
                name="image"
                className="custom-file-input"
                id="inputGroupFile01"
                onChange={uploadImage1}
              />
              <label
                style={{
                  backgroundColor: "#1269B9",
                  color: "white",
                  border: "1px dotted white",
                }}
                className="custom-file-label"
                htmlFor="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
              >
                {fileName1}{" "}
              </label>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div
              style={{ backgroundColor: "#E4DD9F", width: "200px" }}
              className="custom-file"
            >
              <input
                type="file"
                name="consent"
                className="custom-file-input"
                id="inputGroupFile02"
                onChange={uploadImage}
              />
              <label
                style={{
                  backgroundColor: "#1269B9",
                  color: "white",
                  border: "1px dotted white",
                }}
                className="custom-file-label"
                htmlFor="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
              >
                {fileName}{" "}
              </label>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              onClick={() => {
                handleContinue();
              }}
              className="inv-button "
            >
              Submit
            </button>
          </div>
          <div>
            {" "}
            {error.consent ? (
              <div className="error-validation-msg"> {error.consent}</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryData;
