import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Keyboard from 'react-simple-keyboard';
import { Button } from 'react-bootstrap';
import 'react-simple-keyboard/build/css/index.css';
import { IoIosCloseCircleOutline } from "react-icons/io";
import "./enquiry.css"
function PrimaryEnquiry() {
  const [pedate,setPeDate]=useState("")
    const [previewOn, setPreviewOn] = useState(false);
    const [validShow ,setValidShow] =useState(false)
    const [referrer1, setReferrer1] = useState({
      name: '',
      contact: '91',
      comments: '',
    });
  
    const [referrer2, setReferrer2] = useState({
      name: '',
      contact: '91',
      comments: '',
    });
    const [allreferrer, setAllReferrer] = useState([])

    const [alertMsg,setAlertMsg]=useState("")
    const [alertShow ,setAlertShow] =useState(false)
    const [pvShow ,setPVShow] =useState(false)
    const [invalidPic, setInvalidPic] = useState(null);
    const [dob, setDob] = useState();
    const alert = useAlert()
    const [fileName,setFileName]=useState("Primary Form");
    const [img, setImg] = useState("");
    const [uploadShow ,setUploadShow] =useState(false)
    const [isValidEmail,setIsValidEmail]=useState(true);
    const [isValidPhone,setIsValidPhone]=useState(true);
    const [isValidIdentity, setIsValidIdentity] = useState(true);
    const [isValidEq, setIsValidEq] = useState(true);
    const [userList,setUserList]=useState([]);
    const [useMalayalamInput, setUseMalayalamInput] = useState(false); // Initial state is set to false
    const [useMalayalamInput1, setUseMalayalamInput1] = useState(false); // Initial state is set to false
    const [keyboardLayout, setKeyboardLayout] = useState('english'); // Default layout
    const [userDatas, setUserDatas] = useState({ 
        firstname: "",
    lastname: '',
    email: "",

    enquiry:"",
    gender: "",
    dob: "",
    age:"",
    country: "India",
    state: "",
    phone: {
        dialcode: "",
        rawphone: "",
        mobile_number: "",
    },
    phone1: {
      dialcode1: "",
      rawphone1: "",
      mobile_number1: "",
  },
    identitycard: { value: '', label: '' },
    pincode: "",
    identitynumber:"",
    idcard: "",
    province: "",
    city:"",
    img_url:"",
 
    location: "",
reference:"",
report:"",
recommend:""});

const handleReferrer1Change = (e) => {
  const value = e.target.value;
  const key=e.target.name
  if(key==="name"&&value===""){
    setReferrer1((currentstate) => ({
      ...currentstate,
      name: "",
      contact:"91",
      comments:""
    }))
  }
  setReferrer1((currentstate) => ({
    ...currentstate,
    [key]: value,
    
  }))
};

const handleReferrer2Change = (e) => {
  const value = e.target.value;
  const key=e.target.name
  if(key==="name"&&value===""){
    setReferrer2((currentstate) => ({
      ...currentstate,
      name: "",
      contact:"91",
      comments:""
    }))
  }
  setReferrer2((currentstate) => ({
    ...currentstate,
    [key]: value,
    
  }))
};

const[pvId,setPvId]=useState("")
const [error,setError]=useState({
    username:"",
    //email: '',
    phone:"",
  
   });

   
  const handleChangeidnum = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      identitynumber: "",
    }));
    setIsValidIdentity(true)
   
   
    const x=e.target.value
    const val = x.trim()
    const key = e.target.name;
    
      setUserDatas((currentstate) => ({
        ...currentstate,
        [key]: val,
      }));
    
        const data = {
          idcard_no: val,
          idcard_type: userDatas.identitycard.value,
        };
        axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                identitynumber: `This identity number is already registered!`,
              }));
              setIsValidIdentity(false);
            } else {
              setIsValidIdentity(true);
            }
          } else {
            setIsValidIdentity(true);
          }
        })
        .catch((err) => {});
      
    }
    const handleChangeenqnum = (e) => {
      setError((currentstate) => ({
        ...currentstate,
        enquiry_number: "",
      }));
      setIsValidEq(true)
      const x=e.target.value
    const val = x.trim()
      const key = e.target.name;
      
        setUserDatas((currentstate) => ({
          ...currentstate,
          [key]: val,
        }));
     
      e.preventDefault();
      
      const data = {
        pv_enquiry_number:val
      };

      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                enquiry_number: res.data.message,
              }));
              setIsValidEq(false);
            } else {
              setIsValidEq(true);
            }
          } else {
            setIsValidEq(true);
          }
        })
        .catch((err) => { });
    
      
  };

    // const newReferrer = {
    //   refername: referrer.refername,
    //   refercontact: referrer.refercontact,
    //   comment: referrer.comment,
    // };
    
    // // Create a new referrer1 object by combining values from refername1, refercontact1, and comment1
    // const newReferrer1 = {
    //   refername: referrer.refername1,
    //   refercontact: referrer.refercontact1,
    //   comment: referrer.comment1,
    // };
 
 
    const IdentityChange = (data) => {
    

        setUserDatas(current => ({
          ...current, identitycard: data
        }))
      }
    
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
   const handleCheckExisting=e=>{
     
     setIsValidEmail(true);
   if(e.target.value!== ""){
          setError(currentstate=>({
                 ...currentstate,
               email:  ''}));
     e.preventDefault();
     let key=e.target.name;
   
   
       const data = {
               email: e.target.value
           }
        
             axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
        'Content-Type': 'application/json'}})
       .then(res =>{
             
               if(res.data.status === "success"){
                 if(res.data.is_existing_user === true){
                
                       setError(currentstate=>({
                 ...currentstate,
               email: `This ${key} is already registered!`}));
                    setIsValidEmail(false);
                    }
                     else{
                       setIsValidEmail(true);
                     }
                      
                       
                   }
               else{
           
                 setIsValidEmail(true);
                     
               }
                   
               })
       .catch(err =>{
      
       
     })
   }
   
   }

   

   const AlertPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4  className="alerttext" id="confirm-reject " style={{color:"red"}}>{alertMsg}. Do you want to continue?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setAlertShow(false);
              handleRgisterAnyway();
              
            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
 

   const ViewPopup=(props)=>{
    

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
        <div>
            

            <div className='flex-col'>  
                <h4 > You PV Enquiry id is <b><h4 style={{color:"green", fontWeight:"700"}}>{pvId}</h4> </b> Please note for future enquiry</h4>
            </div>

        </div>
  
        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  const reader = new FileReader();
  const uploadImage1 = async (e) => {
    
    const key = e.target.name;
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
  
    if (!file) {
      alert.error("Please Select a File");
      setFileName("Upload File");
      return;
    }
  
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
      alert.error("Select a valid image or PDF file");
      setFileName("Upload File");
      return;
    }
  
    if (file.size > 10e6) {
      alert.error("Select a file smaller than 10MB");
      setFileName("Upload File");
      return;
    }
   
      const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;
  
      if (file.type.startsWith('image/')) {
        setFileName(shortFileName);
        setUserDatas((currentData) => ({
          ...currentData,
          pe_report: file, // Store the base64 encoded image data
          imageUrl1: imageUrl, // Optional for image preview
        }));
      }  if (file.type === 'application/pdf') {
      // Handle PDF file
      setFileName(file.name);
      setUserDatas((currentData) => ({
        ...currentData,
        pe_report: file,
        imageUrl: imageUrl,
      }));
    
  }
  };

  
const identityoptions = [
  {value:'',label:'Select type'},
  {value:'pan',label:'PAN'},                       
{value:'passport',label:'PASSPORT'},
{value:'aadhar',label:'AADHAR'},
{value:'driving_license',label:'DRIVING LICENCE'},
{value:'ration_card',label:'RATION CARD'},
{value:'voter_id',label:'VOTER ID'},]

  
    const handleContinue = async (e) => {
      e.preventDefault();
 
        // User not found in userList, proceed with registration
        if (await( validate() && isValidEmail) || isValidPhone || isValidIdentity ) {
         

          if(userDatas.identitycard && userDatas.identitycard.value!=="" && userDatas.identitynumber===""){
            alert.error(" enter identity number")
          }
          else if((referrer1.name !== "" && (referrer1.contact === ""||referrer1.contact === "91"))  || 
          ( (referrer2.name !== "" && (referrer2.contact === ""||referrer2.contact === "91")) 
        ) ){
            alert.error("referrer name and contact is manadatory")
          }
          else if(pedate!==""&&isValidIdentity&&isValidEq&&referrer1.error===undefined&&referrer2.error===undefined&&(error.phone===undefined||error.phone==="")){
           handleRegister();
          }
          
        }
      }
    
    

    const  handleRegister=()=>{
    

      let reff=[]
     
      if(referrer1.name&&referrer1.contact&&referrer1.contact!=="91"&&referrer1.name!==""&&referrer1.name!==null){
        reff.push(referrer1)
      }
     
      if(referrer2.name&&referrer2.contact&&referrer2.contact!=="91"&&referrer2.name!==""&&referrer2.name!==null){
        reff.push(referrer2)
      }
      
        
if(userDatas.location!=="" && userDatas.img_url!==""){



        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
             const formData = new FormData();

            formData.append('first_name', userDatas.firstname);

formData.append('gender', userDatas.gender);

formData.append('location', userDatas.location);

reff.length>0&&reff.map((item,i)=>{
  formData.append(`referer[${i}]name`, item.name);

      formData.append(`referer[${i}]contact`, item.contact);
      formData.append(`referer[${i}]comments`, item.comments);
  })
  if (userDatas.pv_enquiry_number) {
    formData.append('pv_enquiry_number', userDatas.pv_enquiry_number);
  }
  if (userDatas.pe_date) {
    formData.append('pe_date', userDatas.pe_date);
  }
if (userDatas.dob) {
  formData.append('dob', userDatas.dob);
}
if (userDatas.lastname) {
  formData.append('last_name', userDatas.lastname);
}
if (userDatas.email) {
  formData.append('email', userDatas.email);
}
if (userDatas.phone.dialcode==="") {
  formData.append('country_code', "91");
}
if (userDatas.phone.rawphone) {
  formData.append('mobile_number', userDatas.phone.dialcode+userDatas.phone.rawphone);
}
// if (userDatas.phone1.rawphone1) {
//   formData.append('referer_contact', userDatas.phone1.mobile_number1);
// }
if (userDatas.pincode) {
  formData.append('pincode', userDatas.pincode);
}
if (userDatas.province) {
  formData.append('province', userDatas.province);
}
if (userDatas.city) {
  formData.append('city', userDatas.city);
}
if (userDatas.state) {
  formData.append('state', userDatas.state);
}
if (userDatas.country) {
  formData.append('country', userDatas.country);
}
if (userDatas.identitycard.value) {
  formData.append('idcard_type', userDatas.identitycard.value);
}
if (userDatas.identitynumber) {
  formData.append('idcard_no', userDatas.identitynumber);
}


if (userDatas.pe_report) {
  formData.append("pe_report", userDatas.pe_report);
}

if (img) {
  formData.append('photo', img); // Assuming this is a base64 encoded image
}
if (userDatas.enquiry) {
  formData.append('pe_enquiry', userDatas.enquiry);
}
if(allreferrer.length>0){
  formData.append("referer", JSON.stringify(allreferrer))}


              axios.post(`${APIURL}/api/v1/service-provider/pri-enquiry/`, formData,{ headers: {
                'Content-Type': 'application/json',
                 "Authorization": "Token "+str
                 }})
               .then(res =>{
                 
                       
                       if(res.data.status === "success"){


                       
                       alert.success(res.data.message)
                        setPvId(res.data.pe_id)
                        setPVShow(true);
                        setImg("")
                        setFileName("Primary Form")
                        setAllReferrer([])
                        setUserDatas({
                            firstname: "",
                            lastname: '',
                            email: "",
                        
                            enquiry:"",
                            gender: "",
                            dob: "",
                            country: "India",
                            state: "",
                            phone: {
                              
                              dialcode:91,
                                rawphone: "",
                                mobile_number: "",
                            },
                            phone1: {
                              dialcode1:91,
                              rawphone1: "",
                              mobile_number1: "",
                          },
                            identitycard: { value: '', label: '' },
                            pincode: "",
                            identitynumber:"",
                            idcard: "",
                            province: "",
                            city:"",
                            img_url:"",
                           
                            location: "",
                        reference:"",
                        pe_report:"",
                        recommend:""


                        })
                        setDob("")
                        setPreviewOn(false)
                       }
                      else{
                        if(res.data.message==="similar active accounts found"||res.data.message==="similar accounts found")  {
                          setAlertMsg(res.data.message)
                          setAlertShow(true)
                        }
                        else if(res.data.message==="similar identitys found"){
                          alert.error("Enter valid identity card number")
                        }
                        
                      }
                    })  
                     
                    
                  }
                  else{
                    alert.error(" enter all mandatory fields")
                  }   
                
    }

    const  handleRgisterAnyway=()=>{
      
      let reff=[]
     
      if(referrer1.name&&referrer1.contact&&referrer1.contact!=="91"&&referrer1.name!==""&&referrer1.name!==null){
        reff.push(referrer1)
      }
     
      if(referrer2.name&&referrer2.contact&&referrer2.contact!=="91"&&referrer2.name!==""&&referrer2.name!==null){
        reff.push(referrer2)
      }
      
if(userDatas.location!=="" && userDatas.img_url!==""){
      const tokenString= sessionStorage.getItem('usertoken');
 
      let str= tokenString.replace(/["]+/g, '');
           const formData = new FormData();

          formData.append('first_name', userDatas.firstname);

formData.append('gender', userDatas.gender);

formData.append('location', userDatas.location);
formData.append('save_anyway', true);
if (userDatas.pv_enquiry_number) {
  formData.append('pv_enquiry_number', userDatas.pv_enquiry_number);
}
if (userDatas.pe_date) {
  formData.append('pe_date', userDatas.pe_date);
}
if (userDatas.email) {
formData.append('email', userDatas.email);
}
if (userDatas.lastname) {
  formData.append('last_name', userDatas.lastname);
  }
  if (userDatas.dob) {
    formData.append('dob', userDatas.dob);
    }
if (userDatas.phone.dialcode) {
formData.append('country_code', userDatas.phone.dialcode);
}
if (userDatas.phone.rawphone) {
formData.append('mobile_number', userDatas.phone.dialcode+userDatas.phone.rawphone);
}
reff.length>0&&reff.map((item,i)=>{
  formData.append(`referer[${i}]name`, item.name);

      formData.append(`referer[${i}]contact`, item.contact);
      formData.append(`referer[${i}]comments`, item.comments);
  })
if (userDatas.pincode) {
formData.append('pincode', userDatas.pincode);
}
if (userDatas.province) {
formData.append('province', userDatas.province);
}
if (userDatas.city) {
formData.append('city', userDatas.city);
}
if (userDatas.state) {
formData.append('state', userDatas.state);
}
if (userDatas.country) {
formData.append('country', userDatas.country);
}
if (userDatas.identitycard.value) {
formData.append('idcard_type', userDatas.identitycard.value);
}
if (userDatas.identitynumber) {
formData.append('idcard_no', userDatas.identitynumber);
}
// if (userDatas.reference) {
// formData.append('referer', userDatas.reference);
// }
if (userDatas.report) {
formData.append('field_report', userDatas.report);
}

if (img) {
formData.append('photo', img); // Assuming this is a base64 encoded image
}
if (userDatas.enquiry) {
formData.append('pe_enquiry', userDatas.enquiry);
}


            axios.post(`${APIURL}/api/v1/service-provider/pri-enquiry/`, formData,{ headers: {
              'Content-Type': 'application/json',
               "Authorization": "Token "+str
               }})
             .then(res =>{
               
                     
                     if(res.data.status === "success"){


                    
                     alert.success(res.data.message)
                      setPvId(res.data.pe_id)
                      setPVShow(true);
                      setImg("")
                      setFileName("Primary Form")
                      setUserDatas({
                          firstname: "",
                          lastname: '',
                          email: "",
                      
                          enquiry:"",
                          gender: "",
                          dob: "",
                          country: "India",
                          state: "",
                          phone: {
                            
                              rawphone: "",
                              mobile_number: "",
                          },
                          phone1: {
                            
                            rawphone1: "",
                            mobile_number1: "",
                        },
                          identitycard: { value: '', label: '' },
                          pincode: "",
                          identitynumber:"",
                          idcard: "",
                          province: "",
                          city:"",
                          img_url:"",
                         
                          location: "",
                      reference:"",
                      report:"",
                      recommend:""


                      })
                      setDob("")
                      setPreviewOn(false)
                     
                    }
                  })  
                   
                  
                }
                else{
                  alert.error(" Enter all mandatory fields")
                }      
  }

  


    const validate= async()=>{
        let input = userDatas;
        let errors = {};
        let isValid = true;
      setError({});
    
    
        if (!input["firstname"] || input["firstname"]==="" ) {
          isValid = false;
          errors["firstname"] = " Enter first name";
        }
        if (!input["pv_enquiry_number"] || input["pv_enquiry_number"]==="" ) {
          isValid = false;
          errors["enquiry_number"] = " Enter enquiry number";
        }
        if (!isValidEq ) {
          isValid = false;
          errors["enquiry_number"] = " similar enquiry number found";
        }
        if (!input["pe_date"] || input["pe_date"]==="" ) {
          isValid = false;
          errors["pe_date"] = " Enter primary enquiry date";
        }
      
        if (!input["pincode"] || input["pincode"]==="" ) {
          isValid = false;
          errors["pincode"] = " Enter pincode";
        }
        
        if (!input["location"] || input["location"]==="" ) {
            isValid = false;
            errors["location"] = " Enter location";
          }
        if(userDatas.phone.mobile_number.length>2&&userDatas.phone.mobile_number.length<12){
          isValid=false;
          errors["phone"] = " Contact number must have  10 digits";
        }
    
      if(referrer1.name!==""&&referrer1.contact.length!==12){
        isValid = false;
        setReferrer1({
          ...referrer1,
          error: "Contact number must have  10 digits", // Update the input value as entered
         
        });
      }
      if(referrer1.contact.length===2||referrer1.contact.length===12){
        isValid = true;
       delete referrer1.error
      }
      if(referrer2.name!==""&&referrer2.contact.length!==12){
        isValid = false;
        setReferrer2({
          ...referrer2,
          error: "Contact number must have  10 digits", // Update the input value as entered
         
        });
      }
      if(referrer2.contact.length===2||referrer2.contact.length===12){
        isValid = true;
        delete referrer2.error
      }
     
    
      
      
        if (!input["img_url"] || input["img_url"]==="") {
          isValid = false;
          errors["img_url"] = " Upload a photo";
        }
    
    
        if (isValidIdentity === false) {
          isValid = false;
          errors["identitynumber"] = "IdentityCard number already present!";
        }
    
    
        if(isValidPhone===false){
          isValid = false;
          errors["phone"] = "Phone number already registered!";
        }
        if(isValidEmail===false){
          isValid = false;
          errors["email"] = "Email already registered!";
        }
     
    
    
    
    
      
    
    
        if (input["email"] !== "") {
    
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(input["email"])) {
            isValid = false;
            errors["email"] = " Enter a valid email address.";
          }
        }

     
    
    
        if (typeof input["firstname"] !== "undefined") {
            
          var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
          if (pattern2.test(input["firstname"])) {
            isValid = false;
            errors["firstname"] = "Special Characters are not allowed";
          }
        }
    
        if (typeof input["lastname"] !== "undefined") {
            
          var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
          if (pattern3.test(input["lastname"])) {
            isValid = false;
            errors["lastname"] = "Special Characters are not allowed";
          }
        }
    
    
      
         if(typeof userDatas.gender=="undefined" ||  userDatas.gender==="" ||  userDatas.gender=== null){
          isValid = false;
          errors["gender"] = " select gender";
        }
    
    
    
    
         setError(currentstate=>({
              ...currentstate,
              ...errors
            }));
        
    
        return isValid;
    }
    
    const trimspace=(val)=>{
    
      return  val.startsWith(' ')
      ? val.trimLeft()
      : val;
    }
   
  const uploadImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setUploadShow(!uploadShow);
      //setInvalidPic('Please Select an Image')
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      //setInvalidPic('Select valid image')
      return false;
    }
    if (file.size > 10e6) {
     
      return false;
    }

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImg(file);
        setInvalidPic(null);
      };

      img.onerror = () => {
        setPreviewOn(false);
        
        //setInvalidPic('Invalid Image Content')
        return false;
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    const objectURL = URL.createObjectURL(file);
setError((currentstate) => ({
  ...currentstate,
  img_url: "",
}));
    setUserDatas((currentstate) => ({
      ...currentstate,
      img_url: objectURL,
    }));
    setPreviewOn(true);

   
  };

    const handleGenderRadioButton = (e) => {
        let genderOpted = e.target.value;
        setUserDatas(currentstate => ({
            ...currentstate,
            gender: genderOpted

        }))
        setError(currentstate=>({
          ...currentstate,
          gender: ""
        }))
    }

  const handlemalayalamInput =(e)=>{
    e.preventDefault();
    setUseMalayalamInput(true)
  }

  const handleMalayalamInputToggle = () => {
    setUseMalayalamInput((prevState) => !prevState);
  };
  const handleMalayalamInputToggle1 = () => {
    setUseMalayalamInput1((prevState) => !prevState);
  };

    const handlePhoneInput = (value, data, event, formattedValue) => {
      
      debugger;
        const rawPhone = value.slice(data.dialCode.length);
        const dialcode = value.slice(0, data.dialCode.length);
        const numericValue = rawPhone.replace(/\D/g, ''); // Remove non-numeric characters
        setError({ ...error, phone: null });
        setUserDatas(currentstate => ({
          ...currentstate,
          phone: {
              dialcode: dialcode,
              rawphone: rawPhone,
              mobile_number: value
          }

      }));
       
        if(numericValue.length===0 && dialcode.length===2){
          setError({ ...error, phone: "" });
          setUserDatas(currentstate => ({
            ...currentstate,
            phone: {
                dialcode: dialcode,
                rawphone: "",
                mobile_number: "91"
            }

        }));
        }
        
        else {
          
        }
       

    }
    const handlePhoneInput1 = (value, data, event, formattedValue) => {
      const rawPhone = value.slice(data.dialCode.length);
      const dialcode = value.slice(0, data.dialCode.length);
      setUserDatas(currentstate => ({
          ...currentstate,
          phone1: {
              dialcode1: dialcode,
              rawphone1: rawPhone,
              mobile_number1: value
          }

      }));

  }
    const handleDOB = (date) => {
        setDob(date);
        let dateUpdated = date;
        if (date !== null) {
            const offset = date.getTimezoneOffset();
            dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))
            let DateExtracted = dateUpdated.toISOString().split('T')[0];
            const age = new Date().getFullYear() - new Date(DateExtracted).getFullYear();
            setUserDatas(currentstate => ({
                ...currentstate,
                dob: DateExtracted,
                age:age
            }));
        }
        else  if (date === null||date === "") {
          setUserDatas(currentstate => ({
            ...currentstate,
            dob: "",
            age:""
        }));
        }
    }
    const handlePEDate= (date) => {
      setPeDate(date)
      let dateUpdated = date;
      if (date !== null) {
          const offset = date.getTimezoneOffset();
          dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))
          let DateExtracted = dateUpdated.toISOString().split('T')[0];
         
          setUserDatas(currentstate => ({
              ...currentstate,
              pe_date: DateExtracted,
              
          }));
      }
      else  if (date === null||date === "") {
        setUserDatas(currentstate => ({
          ...currentstate,
          pe_date: "",
         
      }));
      }
      setError(currentstate=>({
        ...currentstate,
        pe_date: ""
      }))
  }
    
    const handleChangeAge = (event) => {
      const inputAge = parseInt(event.target.value, 10);
      if (!isNaN(inputAge) && inputAge >= 1 && inputAge <= 150) {
        // Convert age to DOB format (current date - input age in years)
        const currentDate = new Date();
        const birthYear = currentDate.getFullYear() - inputAge;
        const birthDate = new Date(currentDate);
        birthDate.setFullYear(birthYear);
  
        setDob(birthDate);
        setUserDatas((currentState) => ({
          ...currentState,
          dob: birthDate.toISOString().split('T')[0],
          age:event.target.value
        }));
     
      } else if(event.target.value==="") {
        setDob(null);
        setUserDatas((currentState) => ({
          ...currentState,
          dob: null,
          age:null
        }));
      }
    };
    const selectRegion = (val) => {
        // let value1=e.target.value;
        setUserDatas(currentstate => ({
            ...currentstate,
            state: val
        }));
    }
    const handleConsentRadioButton=(e)=>{
    
        if(userDatas.phone !==""){
        let consentOpted = e.target.value;
      
        setUserDatas(currentstate=>({
          ...currentstate,
          consent: consentOpted
    
        }))
    }
}
const handleChange=e=>{
    e.preventDefault();
    const val=trimspace(e.target.value);
    const key=e.target.name;

    setUserDatas(currentstate=>({
      ...currentstate,
      [key]: val
    }));    
    if(key!=="pv_enquiry_number"){
      setError(currentstate=>({
        ...currentstate,
        [key]: ""
      }))
      
    }
  

    
}
    return (
      <div className='container' style={{ paddingTop: "2%", paddingBottom: "2%" }}>
        <div className='enquirycontainer' style={{ paddingTop: "1%",paddingLeft:"2%",paddingRight:"2%" }}>
            <h6 className="enquiryheader">Patient Primary Enquiry</h6>
            <div className='row'>
              <div className='col-6' style={{ padding: "2%" }}>
                <div className='row primary-enquiry'>
                  <div className='col-6' style={{paddingLeft:0}}>
                    <span>*Enquiry Number</span>
                    <input value={userDatas.pv_enquiry_number}  type="text" name="pv_enquiry_number"  onChange={handleChangeenqnum} maxLength='30' className='enquiryinput'  />
                    { error.enquiry_number ? <div className="error-validationmsg">{error.enquiry_number}</div> : null}
                  </div>
                  <div className='col-6' style={{padding:0}}>
                    <span>*PE Date</span>                        
                    <DatePicker
                      className="enquiryinput"
                      onChange={date => { handlePEDate(date) }}
                      style={{ width: "80%", border: "none" }}
                      maxDate={new Date()}
                      name="pe_date"
                      value={pedate}
                    />
                    { error.pe_date ? <div className="error-validationmsg">{error.pe_date}</div> : null}
                  </div>
                </div>   
                <div className='row primary-enquiry'>                
                <span style={{padding:0}}>Date of Birth</span>
                <div className='enquiryinput' style={{ textAlign: "center", padding: "1%" }}>
                  <DatePicker
                    onChange={date => { handleDOB(date) }}
                    style={{ width: "80%", border: "none" }}
                    maxDate={new Date()}
                    value={dob}
                  />
                  {""}
                  <span style={{fontSize:"10px",marginLeft:"10px",color:"grey"}}> OR </span>
                  <input  
                    type="number" 
                    min="1" 
                    max="150"  // Ensure the entered value is within the specified range
                    value={userDatas.age}    
                    placeholder="Age" name="age"  onChange={handleChangeAge} style={{marginLeft:"3%",borderRadius:"5px",width:"30%"}}>
                  </input>
                </div>
                </div>
                <div className='row primary-enquiry'>
                  <span>Phone</span>
                  <PhoneInput                            
                    country={'in'}
                    onlyCountries={['in']}
                    countryCodeEditable={false}
                    value={userDatas.phone.mobile_number}
                    onChange={handlePhoneInput}
                    //  onBlur={handleCheckExistingPhone}
                  />
                  {error.phone ? <div className="error-validationmsg"> {error.phone}</div>  : null}
                </div> 
                <div className='row primary-enquiry'>
                    <div className={userDatas.identitycard.value!==""?'col-6':"col-12"} style={{padding:0}}>
                      <span>Identity Card</span>
                      <Select
                        value={userDatas.identitycard.value!==""?userDatas.identitycard:"Select type"}
                        onChange={(data,e)=>{IdentityChange(data,e)}}
                        options={identityoptions}
                        name='identitycard'
                        style={{width:"100%"}}
                        isDisabled={userDatas.identitynumber!==""}
                      /> 
                    </div>
                    {userDatas.identitycard.value!=="" ?
                    <div className='col-6' style={{paddingRight:0}}> 
                      <span>Identity Card Number</span>
                      <input className='enquiryinput' type="text"value={userDatas.identitynumber}  name="identitynumber" onChange={handleChangeidnum} />
                      {error.identitynumber ? (
                      <div className="error-validationmsg">
                        {" "}
                        {error.identitynumber}
                      </div>
                      ) : null}
                    </div>:""}                        
                  </div>  
                  <div className='row primary-enquiry'>                    
                    <span>Address Line 2</span>
                    <input className='enquiryinput' placeholder='Province' type="text"name="province" value={userDatas.province} onChange={handleChange} />
                  </div>
                  <div className='row primary-enquiry'>
                    <span>Country</span>
                    <input 
                      className='enquiryinput' 
                      value={"India"}
                      disabled type="text" 
                    />                          
                  </div>
                  <h6 style={{marginLeft:"35%",marginTop:"2%",color:"#245693",fontSize:"15px"}}> <b> Referrer 1 </b></h6>
                  <div className='row'>
                  <div className='col-6' style={{ padding: "2%" ,marginTop:"2%" }}>
                    <span>Name</span>
                    <input className='enquiryinput' type="text" name="name" value={referrer1.name} onChange={handleReferrer1Change} />
                  </div>                         
                  <div className='col-6' style={{ padding: "2%",marginTop:"1%"  }}>
                    <span>Contact no</span>
                    <PhoneInput                
                      country={'in'}
                      onlyCountries={['in']}
                      countryCodeEditable={false}
                      value={referrer1.contact}
                      disabled={referrer1.name===""} 
                      name="contact"
                      onChange={(value) => {  
                        // Remove non-numeric characters from the input value                
                        const numericValue = value.replace(/\D/g, "");
                        // Check if the numericValue has less than 10 digits
                        if (numericValue.length < 12 && numericValue.length!==2) {
                          setReferrer1({
                            ...referrer1,
                            conatct:"91",
                            error: "Contact number must have at least 10 digits",
                          });
                        } else if(numericValue.length===2){
                          setReferrer1({
                            ...referrer1,
                            contact:"91",
                            error: "",
                          });
                        } else {
                          setReferrer1({
                            ...referrer1,
                            contact: value, // Update the input value as entered
                            error: null,
                          });
                        }
                      }}
                      className='enquiryinput'
                      style={{ width: "100%",marginTop:"1%" }}
                    />
                    {referrer1.error ? <div className="error-validationmsg">{referrer1.error}</div> : null}
                  </div> 
                </div>
                <span>Comments</span>
                <textarea className='enquiryinputtext' name="comments" style={{cursor:referrer1.name===""&&"not-allowed"}} value={referrer1.comments} disabled={referrer1.name===""}  maxLength={250} onChange={handleReferrer1Change} type="textarea" />
                <button onClick={handleMalayalamInputToggle}>Switch to Malayalam Input</button> 
                <span style={{fontSize:"10px",color:"grey"}}>*referer name is mandatory before entering comments and maximum 250 characters</span> 
                {useMalayalamInput && (       
                <iframe
                  title="Google Malayalam Input"
                  src={process.env.PUBLIC_URL + '/malayalam.html'}
                  width="100%"
                  height="400px"
                ></iframe>   
                )}        
              </div>
              <div className='col-6' style={{ padding: "2%" }}>
                <div className='row' >
                  <div className='col-8'>
                    <div className='row primary-enquiry'>
                      <div className='col-6' style={{paddingLeft:0}}>
                        <span>*First Name</span>
                        <input value={userDatas.firstname}  type="text" name="firstname"   onChange={handleChange} maxLength='30' className='enquiryinput'  />
                        { error.firstname ? <div className="error-validationmsg">{error.firstname}</div> : null}
                      </div>
                      <div className='col-6' style={{padding:0}}>
                        <span>Last Name</span>
                        <input className='enquiryinput' type="text" name="lastname" value={userDatas.lastname} onChange={handleChange} />
                        { error.lastname ? <div className="error-validationmsg">{error.lastname}</div> : null}
                      </div>
                    </div>    
                    <div className='row primary-enquiry'>                      
                      <span>*Gender</span>
                      <div className='enquiryinput' style={{ textAlign: "center", padding: "2%" }}>
                      <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="male"
                        checked={userDatas.gender === "male"}
                        onClick={handleGenderRadioButton}
                      />
                      <label htmlFor="male">Male</label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="female"
                        checked={userDatas.gender === "female"}
                        onClick={handleGenderRadioButton} 
                      />
                      <label htmlFor="female">Female</label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <input
                        type="radio"
                        id="others"
                        name="gender"
                        value="others"
                        checked={userDatas.gender === "others"}
                        onClick={handleGenderRadioButton}
                      />
                      <label htmlFor="others">Others</label>
                      </div>
                      {error.gender ?  <div className="error-validationmsg"> {error.gender}</div> : null}  
                    </div>    
                    <div className='row primary-enquiry'>                          
                      <span>Email</span>
                      <input className='enquiryinput' type="text"name="email" value={userDatas.email} onChange={handleChange}  />
                      {error.email ? <div className="error-validationmsg"> {error.email}</div> : null}
                    </div>
                  </div>
                  <div className='col-4' style={{paddingBottom:"20px"}}>
                    <span>*photo</span>
                    <div className='enquiryimage'> {previewOn === false ? (
                      <div className="pic-container" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", borderRadius: ".25rem"}}>
                        <input
                          type="file"
                          className="custom-file-input"
                          id="validatedCustomFile"
                          onChange={uploadImage}
                        />
                        {invalidPic && <p className="error">{invalidPic}</p>}
                        <label className="" htmlFor="validatedCustomFile">
                          <p>Upload Profile Picture</p>
                          <div className="camera-icon">
                            <i className="fa fa-camera camera-icon"></i>
                          </div>
                        </label>                     
                      </div>
                      ) : (
                      <div>
                        <IoIosCloseCircleOutline onClick={() => {setPreviewOn(false)}} className='closebutton-admission'/>                                              
                        <img
                          src={userDatas.img_url}
                          alt="Hospital Picture"
                          className="avatar avatar-admission"
                        />
                      </div>
                      )} 
                    </div>
                {error.img_url ? <div className="error-validationmsg" style={{float:"right",width:"100%"}}> {error.img_url}</div>  : null}  

                  </div>
                </div>
                <div className='row primary-enquiry' style={{ marginTop: error.gender ? '0px' : '-10px'}}>                
                  <span>Address Line1</span>
                  <input className='enquiryinput'name="city" placeholder='City' value={userDatas.city} onChange={handleChange} type="text" />
                </div>
                <div className='row primary-enquiry'>
                <span>*AddressLine 3</span>
                <input className='enquiryinput' placeholder='Location' type="text" name="location" value={userDatas.location} onChange={handleChange} />
                { error.location ? <div className="error-validationmsg">{error.location}</div> : null}
                </div>
                <div className='row primary-enquiry'>
                  <div className='col-6' style={{ padding: 0, paddingRight:"15px" }}>                    
                    <span>Region</span>
                    <RegionDropdown
                      className='enquiryinput'
                      country={"India"}
                      value={userDatas.state}
                      onChange={(val) => selectRegion(val)} 
                    />
                  </div>
                  <div className='col-6' style={{ padding: 0 }}>
                    <span>Pincode</span>
                    <input className='enquiryinput' type="text" name="pincode" value={userDatas.pincode} onChange={handleChange}/>
                  </div>
                </div>
                  <h6 style={{marginLeft:"35%",marginTop:"2%",color:"#245693",fontSize:"15px"}}> <b> Referrer 2 </b></h6>
                  <div className='row'>
                    <div className='col-6' style={{ padding: "2%" }}>
                      <span>Name</span>
                      <input className='enquiryinput' type="text" name="name" value={referrer2.name} onChange={handleReferrer2Change} />
                    </div>
                    <div className='col-6' style={{ padding: "2%" }}>
                      <span>Contact no</span>
                      <PhoneInput
                        disabled={referrer2.name===""} 
                        country={'in'}
                        onlyCountries={['in']}
                        countryCodeEditable={false}
                        value={referrer2.contact}                
                        name="contact"
                        onChange={(value) => {
                          const numericValue = value.replace(/\D/g, "");
                          if (numericValue.length < 12 && numericValue.length!==2) {
                            setReferrer2({
                              ...referrer2,
                              conatct:"91",
                              error: "Contact number must have at least 10 digits",
                            });
                          } else if(numericValue.length===2){
                            setReferrer2({
                              ...referrer2,
                              contact:"91",
                              error: "",
                            });
                          } else {
                            setReferrer2({
                              ...referrer2,
                              contact: value, // Update the input value as entered
                              error: null,
                            });
                          }
                        }}
                        className='enquiryinput'
                        style={{ width: "100%" }}
                      />
                      {referrer2.error ? <div className="error-validationmsg">{referrer2.error}</div> : null}
                    </div> 
                  </div>
                  <span>Comments</span>
                  <textarea className='enquiryinputtext' style={{cursor:referrer2.name===""&&"not-allowed"}}  disabled={referrer2.name===""}  name="comments"  value={referrer2.comments}  maxLength={250} onChange={handleReferrer2Change}  type="textarea" />
                  <button onClick={handleMalayalamInputToggle1}>Switch to Malayalam Input</button>
                  {useMalayalamInput1 && (       
                    <iframe
                     title="Google Malayalam Input"
                      src={process.env.PUBLIC_URL + '/malayalam.html'}
                      width="100%"
                      height="400px"
                    ></iframe>   
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-6' style={{ padding: "2%" }}>
                  <label style={{ backgroundColor: "white", border: "1px dashed #104173", height: "40px", width: "98%", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center", gap: "8px", padding: "0 12px", cursor: "pointer" }}>
                    <input type="file" style={{ display: "none" }} onChange={uploadImage1} />
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i> 
                    {fileName} 
                  </label> 
                </div>
                <div className='col-6' style={{ padding: "2%" }}>
                  <button style={{ backgroundColor: "#9069A5", color: "white", height: "40px", border: "none", width: "98%", borderRadius: "10px" }} onClick={(e)=>handleContinue(e)}>Submit</button>
                </div>         
              </div>
            </div>
            {pvShow ? 
              <ViewPopup
                show={pvShow}
                onHide={() => {
                  setPVShow(false);
                  window.location.reload(false);
                }}
              /> 
            : null}
            {alertShow ? (
              <AlertPopUp
                show={alertShow}
                onHide={() => {
                  setAlertShow(false);
                }}
              /> ) 
            : null}
        </div>
      )

}
export default PrimaryEnquiry