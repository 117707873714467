import React,{useState,useEffect} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import { useAlert } from "react-alert";
function AddScan(props) {

    const[data, setData]= useState({


  
      scanname:'',
      scantype:'',
      scanmethod:'',
      scansarea:'',
      group:{ value:'', label:'' },
      cptcode:'', 
     // agegroup:{value:'',label:''},  
      description:'',
    
      country : { value:'India', label:'India' }
    })

    const [error,setError]=useState({
      scanname:'',
      scantype:'',
     // agegroup:'',
     group:'',
   
    country : ''
        
       });
       const group3 = [{ value:'male', label:'MALE' },
       { value:'female', label:'FEMALE' },
   { value:'others', label:'OTHERS' },
      
   ]


   const agegroupvalue = [{ value:'1', label:'Infants' },
   { value:'2', label:'kids' },{value:'3', label:'teen'},{value:'4', label:'young'},{value:'5', label:'adult'},{value:'6', label:'elderly'}
  
]
const alert = useAlert();
let formData =new FormData();
const [image, setImage] = useState(null);
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)
    const[ageGroupData,setAgeGroupData] = useState("")


//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]
useEffect(() => {
  const tokenString = sessionStorage.getItem("usertoken");
  let str = tokenString.replace(/["]+/g, "");
  axios
    .get(`${APIURL}/api/v1/staff/age-groups/`, {
      headers: {
        Authorization: "Token " + str,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        setAgeGroupData(res.data);
      }
    })
}, []);

const handleImageUpload = (event) => {
  const selectedImage = event.target.files[0];
  setImage(selectedImage);
}
    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Click Proceed to add scan.</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Not Now</Button> 
             <Button className='btn btn-primary btn-col' onClick={saveChanges}>Proceed</Button> 
             
            </Modal.Footer>
          </Modal>
        );
      }

      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleSave=() => {
        if(validate()){ 
        setSuccessShow(true);
        } else {
          setErrorValidate(true)
        }
      }

      const handleServiceChange= (data) => {

        setData( current => ({
            ...current , group: data
        }))
   }

   const handleAgeGroup= (data) => {

    setData( current => ({
        ...current , agegroup: data
    }))
  }

  // const handleRequest = (e)=> {
  //  const val = e.target.value
  //  const key = e.target.name

  //  setData((current) => ({
  //      ...current , [key] : val==='true'? true : val==='false'? false : null
  //  }))

  // }

  // const handleYear = (date)=> {
  //   setYear(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setYearData(DateExtracted);
      
  //     }
  // }

  // const handleExpiry = (date)=> {
  //   setExpiry(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setExpiryData(DateExtracted);
      
  //     }
  // }

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});
  // scanname:'',
  //     scantype:'',
  //     scanmethod:'',
  //     scansarea:'',
  //    group:{ value:'', label:'' },
  //    cptcode:'',   
  //   description:'',
  //   country : { value:'', label:'' }
        
    if (!input["scanname"].trim() || input["scanname"].trim() ==="" ) {
      isValid = false;
      errors["scanname"] = "Enter Scan Name";
    }
    if (!input["scansarea"].trim() || input["scansarea"].trim() ==="" ) {
      isValid = false;
      errors["scansarea"] = "Enter Scan Area";
    }

    // if (typeof input["scanname"] !== "undefined") {
    //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if (pattern3.test(input["scanname"])) {
    //     isValid = false;
    //     errors["scanname"] = "Special Characters are not allowed";
    //   }
    // }
    // if (!input["scanmethod"].trim() || input["scanmethod"].trim() ==="" ) {
    //   isValid = false;
    //   errors["scanmethod"] = "Enter Scan Method";
    // }

    // if (typeof input["scanmethod"] !== "undefined") {
    //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if (pattern3.test(input["scanmethod"])) {
    //     isValid = false;
    //     errors["scanmethod"] = "Special Characters are not allowed";
    //   }
    // }

    if (!input["scantype"] || input["scantype"]==="" ) {
      isValid = false;
      errors["scantype"] = "Enter Scan Type";
    }
  
    // if (typeof input["scantype"] !== "undefined") {
    //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if (pattern3.test(input["scantype"])) {
    //     isValid = false;
    //     errors["scantype"] = "Special Characters are not allowed";
    //   }
    // }
    if (!input.group || (input.group && !input.group.value) ) {
      isValid = false;
      errors["group"] = "Select group";
    }
    // if (!input.agegroup || (input.agegroup && !input.agegroup.value) ) {
    //   isValid = false;
    //   errors["agegroup"] = "Select Agegroup";
    // }
    if (!input["country"] || input["country"]==="" ) {
      isValid = false;
      errors["country"] = "Please Select country";
    }
 
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
    //  scanname:'',
    //   scantype:'',
    //   scanmethod:'',
    //   scansarea:'',
    //  group:{ value:'', label:'' },
    //  cptcode:'',   
    // description:'',
    // country : { value:'', label:'' }
  const dataToSend = {
    scan_name : data.scanname.slice(0,1).toUpperCase() +data.scanname.slice(1, data.scanname.length), 
    scan_type : data.scantype,
    scan_method : data.scanmethod,
    scan_area :  data.scansarea,                                
   cpt_code: data.cptcode,
    country :       data.country.value,           
    group : data.group.value,
   // age_group :data.agegroup.value,
    description:data.description

  }

  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
Object.keys(dataToSend).forEach(key => formData.append(key, dataToSend[key]));
 
     axios
       .post(`${APIURL}/api/v1/staff/master-scans/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status==="success") {
          alert.success('Successfully saved!')
          props.modalClose();
          setData({
            scanname:'',
      scantype:'',
      scanmethod:'',
      scansarea:'',
     group:{ value:'', label:'' },
     cptcode:'',   
    description:'',
    country : { value:'', label:'' }
         });}
          else {
         alert.error(res.data.message)
         }
       })
       .catch((err) => {
         //setErrorShow(true)
       });
       setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
   }

}

    return (
      <>
        <h2 style={{marginTop:'0'}} className="title-of-page">Add Scan</h2>
        <div style={{display:"flex", marginLeft: "4rem", marginRight: "4rem", overlap:"hidden", padding: "0 10px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {image && (
         <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "5px" }}>
          <img src={URL.createObjectURL(image)} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
          <div style={{maxHeight:'1000px', width:"90%"}} className='config-box fit-content'> 
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Scan Name: </span>
                  <input type='text' className='form-control title-flex' name='scanname' value={data.scanname} onChange={handleTitle} />
                  {error.scanname ? <div className="error-validation-msg error-feature-inventory">{error.scanname}</div> : null}
                </div>

                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Scan Type : </span>
                  <input type='text' className='form-control title-flex' name='scantype' value={data.scantype} onChange={handleTitle} />
                  {error.scantype ? <div className="error-validation-msg error-feature-inventory">{error.scantype}</div> : null}
                </div>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'>Scan Method : </span>
                  <input type='text' className='form-control title-flex' name='scanmethod' value={data.scanmethod} onChange={handleTitle} />
                  {error.scanmethod ? <div className="error-validation-msg error-feature-inventory">{error.scanmethod}</div> : null}
                
                </div>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'> *Scan Area: </span>
                  <input type='text' className='form-control title-flex' name='scansarea' value={data.scansarea} onChange={handleTitle} />
                  {error.scansarea ? <div className="error-validation-msg error-feature-inventory">{error.scansarea}</div> : null}
                </div>
                

                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Group: </span>
                  <Select
                    value={data.group}
                    onChange={(data,e)=>{handleServiceChange(data,e)
                    }}
                    options={group3}
                    name='group'
                    className="select-currency select-flex title-flex"
                  />
                  {error.group ? <div className="error-validation-msg error-feature-inventory">{error.group}</div> : null}
                </div>
                {/* <div className='flex-col justify-feature'>
                  <span className='align-rental'>*Age Group: </span>
                  <Select
                    value={data.agegroup}
                    onChange={(data,e)=>{handleAgeGroup(data,e)
                    }}
                    options={agegroupvalue}
                    name='agegroup'
                    className="select-currency select-flex title-flex"
                  />
                  {error.agegroup ? <div className="error-validation-msg error-feature-inventory">{error.agegroup}</div> : null}
                </div> */}
            </div>
            <div className='text-center'>
              <button onClick={handleSave} className='btn btn-primary btn-col mb-2'>Save</button>  
            </div>
            {successShow ? 
              <SuccessPopUp
                show={successShow}
                onProceed={() =>  {setSuccessShow(false); props.modalClose();}}
                onHide={() =>  {
                  setSuccessShow(false);
                  props.modalClose() 
                }}
              /> 
              : null}

            {errorShow ? 
              <ErrorPopUp
                show={errorShow}
                onHide={() =>  {setErrorShow(false)}}
              /> 
            : null}            
          </div>
      </>
  )
}

export default AddScan;
