import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';

function AddSymptoms(props) {

    const[data, setData]= useState({
      symptomname:'',
      symptomtype:'',
      symptomcode :''
     
        

    })
    const alert = useAlert()
    const [error,setError]=useState({
      symptomname:''
        
       });
    const[errorValidate,setErrorValidate]=useState(false)


      
      const handleError = () =>
  {
    setErrorValidate(false);
  }
    
      
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["symptomname"] || input["symptomname"]==="" ) {
      isValid = false;
      errors["symptomname"] = "Enter symptom Name";
    }
    if (!input["symptomtype"] || input["symptomtype"]==="" ) {
      isValid = false;
      errors["symptomtype"] = "Enter symptom Type";
    }
        
        
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();

 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    symptom_name: data.symptomname,                               
    symptom_type : data.symptomtype,
    symptom_code : data.symptomcode
   

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-symptoms/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
           
         alert.success('Saved successfully!')
         props.modalClose()
            
         setData({
          symptomname : ''
       });
        
         } else {
           alert.error('Check if duplicate')
         }
       })
       .catch((err) => {
       });
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add Symptoms</h4>
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Symptom Name: </label>
                    <textarea type='text' className='form-control title-flex' name='symptomname' value={data.symptomname} onChange={handleTitle} /></div>
                    {error.symptomname ? <div className="error-validation-msg error-feature-inventory">{error.symptomname}</div> : null}
                </div><br/>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>*Symptom Type : </label>
                    <input type='text' className='form-control title-flex' name='symptomtype' value={data.symptomtype} onChange={handleTitle} />
                    {error.symptomtype ? <div className="error-validation-msg error-feature-inventory">{error.symptomtype}</div> : null}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Symptom Code : </label>
                    <input type='text' className='form-control title-flex' name='symptomcode' value={data.symptomcode} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>                                              
            </div>       
        </div>
        <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>   
        </div>
        </>
    )
}

export default AddSymptoms
