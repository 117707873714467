import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';

function EditComo(props) {

    const[data, setData]= useState(props.data)
    const [errormsg,setErrorMsg]=useState("")
    const [error,setError]=useState({
      comorbidity_name : ''
        
       });
    const alert = useAlert();
    const[errorValidate,setErrorValidate]=useState(false)
     
      const handleTitle = (e)=> {
        
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
          
            ...current , [key] : val
        }))
      }

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["comorbidity_name"] || input["comorbidity_name"]==="" ) {
      isValid = false;
      errors["comorbidity_name"] = "Enter Comorbidity Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 let dataToSend={}
 if(props.data.comorbidity_name===data.comorbidity_name&&props.data.comorbidity_type===data.comorbidity_type){
  dataToSend = {
   
    comorbidity_index:data.comorbidity_index,
    comorbidity_stage:data.comorbidity_stage
  }
 }else if(props.data.comorbidity_name===data.comorbidity_name){
  dataToSend = {
    comorbidity_type:data.comorbidity_type,
    comorbidity_index:data.comorbidity_index,
    comorbidity_stage:data.comorbidity_stage,
  }
 }
 
 else{
   dataToSend = {
    comorbidity_name: data.comorbidity_name,
    comorbidity_type:data.comorbidity_type,
    comorbidity_index:data.comorbidity_index,
    comorbidity_stage:data.comorbidity_stage,
   }   
  }
 
     axios
       .put(`${APIURL}/api/v1/staff/master-comorbidity-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
           alert.success('Updated successfully');
           props.modalClose();
         setData({
          comorbidity_name : '',
          comorbidity_type : '',
          comorbidity_index : '',
          comorbidity_stage : ''

      });
        
         } else {
          alert.error('Check if duplicated')
         }
       })
       .catch((err) => {
         alert.error('Check if duplicated')
       });
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Edit comorbidities</h4>
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Name: </label>
                    <input type='text' className='form-control title-flex' name='comorbidity_name' value={data.comorbidity_name} onChange={handleTitle} /></div>
                    {error.comorbidity_name ? <div className="error-validation-msg error-feature-inventory">{error.comorbidity_name}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Comorbidity Type : </label>
                    <input type='text' className='form-control title-flex' name='comorbidity_type' value={data.comorbidity_type} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Comorbidity Index  : </label>
                    <input type='text' className='form-control title-flex' name='comorbidity_index' value={data.comorbidity_index} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Comorbidity Stage : </label>
                    <input type='text' className='form-control title-flex' name='comorbidity_stage' value={data.comorbidity_stage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
              </div>
        </div>
        <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>   
        </div>
        </>
    )
}

export default EditComo;
